import React from 'react'
import TableComponent from '../../../../Component/TableComponent';
import Api from '../../../../Api';
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import SweetAlert from "sweetalert-react";
import SelectAutocompleteComponent from "../../../../Component/SelectAutocompleteComponent";
import SelectComponent from "../../../../Component/SelectComponent";
import ButtonComponent from "../../../../Component/ButtonComponent";
import { Assignment, Delete, Edit } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { formHandlerInit } from "../../../../Tool/FormHandlerCommon";
import { inArray } from "../../../../Tool/ArrayCommon";
import moment from "moment";
import {hasRight} from "../../../../Tool/UserTool";
import { TableReload } from "../../../../Action/TableAction";
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from "../../../../Action/LinearProgressMainLayoutAction";
import { SnackbarOpen } from "../../../../Action/SnackbarAction";

export default function VirtualClassroomSession(props = {}) {

    const dispatch = useDispatch();
    const authenticationReducer = useSelector(state => state.AuthenticationReducer);
    const selectOrganismReducer = useSelector(state => state.SelectOrganismReducer)

    const [dialog, setDialog] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [isCrmAdmin, setIsCrmAdmin] = React.useState(true);
    const [fullSessions, setFullSessions] = React.useState(false);
    const [showConfirm, setShowConfirm] = React.useState(false);

    React.useEffect(() => {
        if (hasRight(authenticationReducer, 'ROLE_SUPER_ADMIN')) {
            setIsCrmAdmin(false);
        }
    }, []);

    // USE STATE OPTIONS
    const [optionsUnits, setOptionsUnits] = React.useState([]);
    const [optionAddYears, setOptionAddYears] = React.useState([]);
    const [optionFormation, setOptionFormation] = React.useState([]);
    const [optionsSessionFormation, setOptionsSessionFormation] = React.useState([]);

    const [sessionFormationToRemove, setSessionFormationToRemove] = React.useState({});

    const [form, setForm] = React.useState({
        year: {
            name: 'year',
            label: 'Année',
            textHelper: 'Choisissez une année.',
            type: 'integer',
            defaultValue: parseInt(moment().format('YYYY')),
            options: {validation: ['required'],}
        },
        formation: {
            name: 'formation',
            label: 'Formation',
            textHelper: 'Choisissez une formation.',
            type: 'integer',
            defaultValue: '',
            options: {validation: ['required'],}
        },
        sessionFormation: {
            name: 'sessionFormation',
            label: 'Session',
            textHelper: 'Choisissez une session.',
            type: 'integer',
            defaultValue: '',
            options: {validation: ['required'],}
        },
        unit: {
            name: 'unit',
            label: 'Unité',
            textHelper: 'Choisissez une unité.',
            type: 'integer',
            defaultValue: '',
            options: {validation: ['required'],}
        }
    });

    const handler = formHandlerInit(form, setForm);

    React.useEffect(handler.start, []);
    React.useEffect(() => {
        // Year
        const currentYear = new Date().getFullYear();
        let yearsTMP = [];
        yearsTMP.push({label: (currentYear + 1), value: (currentYear + 1)});
        for (let i = currentYear; i > currentYear - 5; i--) {
            yearsTMP.push({ label: i, value: i });
        }
        let yearsAddTMP = [
            {label: currentYear + 1, value: currentYear + 1},
            {label: currentYear, value: currentYear},
            {label: currentYear - 1, value: currentYear - 1}
        ];

        setOptionAddYears(yearsAddTMP);

        Api.get({
                route: 'select_formations',
                data: {organism: selectOrganismReducer.organism.value}
            },
            (response) => {
                if (response && response.data) {
                    setOptionFormation(response.data);
                }
            });
    }, []);


    const changeYear = (value, mustReload, formationId = '', formationSessionId = '') => {
        let newForm = {...handler.form};
        newForm['year'].value = value;
        newForm['year'].error = handler.getErrorByField(newForm['year']);
        handler.set(newForm);
        if (mustReload) changeFormation(formationId, formationSessionId);
    };

    const changeFormation = (value, formationSessionId = '') => {
        let newForm = {...handler.form};
        newForm['formation'].value = value;
        newForm['formation'].error = value !== '' ? handler.getErrorByField(newForm['formation']) : '';
        handler.set(newForm);

        loadSession(fullSessions,formationSessionId);
    };

    const loadSession = (full = false, formationSessionId = '') => {
        setFullSessions(full);

        let formationIdTMP = form['formation'].value;
        let yearTMP = form['year'].value;

        if (formationIdTMP) {
            handler.setFormLoading(true);
            Api.get({
                    route: 'institution_health_content_manager_virtual_classroom_sessions_formation_list',
                    data: {full: full ? 1 : 0, formation: formationIdTMP, year: yearTMP, organism: selectOrganismReducer.organism.value, vc: props.match.params.id}
                },
                (response) => {
                    if (response && response.data) {
                        setOptionsSessionFormation(response.data);
                    }
                    loadUnits(formationIdTMP)
                });
        }
        else {
            setOptionsUnits([]);
            setOptionsSessionFormation([]);
        }

        let newForm = {...handler.form};
        newForm['sessionFormation'].value = formationSessionId;
        newForm['sessionFormation'].error = formationSessionId !== '' ? handler.getErrorByField(newForm['sessionFormation']) : '';
        handler.set(newForm);

    };

    const loadUnits = (formationId, unitId = '') => {
        Api.get(
            {
                route: 'institution_health_content_manager_virtual_classroom_unit_list',
                data: {formation: formationId}
            },
            (response) => {
                handler.setFormLoading(false);
                if (response && response.data) {
                    setOptionsUnits(response.data);
                }
            }
        );
        let newForm = {...handler.form};
        newForm['unit'].value = unitId;
        newForm['unit'].error = unitId !== '' ? handler.getErrorByField(newForm['unit']) : '';
        handler.set(newForm);
    };

    const save = () => {
        if (handler.checkError()) {
            console.log('Error');
        } else {
            handler.setFormLoading(true);
            setLoading(true);
            dispatch(LinearProgressMainLayoutActivate());
            Api.post({
                route: 'institution_health_content_manager_virtual_classroom_edit',
                data: {organism: selectOrganismReducer.organism.value, ...handler.getData()},
                params: { id: props.match.params.id }
            }, (response) => {
                handler.setFormLoading(false);
                setLoading(false);
                dispatch(LinearProgressMainLayoutDeactivate());

                if (response.status === 200) {
                    dispatch(
                        SnackbarOpen({
                            text: 'Classe virtuelle modifiée.',
                            variant: 'success',
                        })
                    );
                    dispatch(TableReload('institution_health_content_manager_virtual_classroom_session'));
                    setDialog(false);
                } else if (response.status === 400) {
                    handler.setErrorApi(response.error);
                } else {
                    dispatch(
                        SnackbarOpen({
                            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
            });
        }
    };

    // LIST
    const columns = [
        {
            name: 'id',
            label: 'Référence',
            options: { filter: true, sort: true }
        },
        {
            name: 'organism',
            label: 'Organism',
            options: { filter: true, sort: true }
        },
        {
            name: 'formation',
            label: 'Formation',
            options: { filter: true, sort: true }
        },
        {
            name: 'session',
            label: 'Session',
            options: { filter: true, sort: true }
        },
        {
            name: "action",
            label: "Action",
            options: { filter: false, sort: false }
        }
    ];

    const getAction = (row) => {
        return (
            <>
                <Tooltip title={'Supprimer'} placement="left">
                    <IconButton onClick={() => {
                        setShowConfirm(true);
                        setSessionFormationToRemove({sessionFormation: row.id})
                    }}>
                        <Delete />
                    </IconButton>
                </Tooltip>
            </>
        );
    };

    return (
        <>
            <TableComponent
                noCache={true}
                sortOrder={{ name: 'order', direction: 'desc' }}
                id={'institution_health_content_manager_virtual_classroom_session'}
                title={'Sessions formation liée a cette classe virtuelle : '}
                columns={columns}
                actionFirst={{
                    label: 'Ajouter une session',
                    onClick: () => {
                        setDialog(true);
                        handler.reset();
                    }
                }}
                promiseData={(resolve) => {
                    if (props.match.params.id) {
                        Api.get({
                            route: 'institution_health_content_manager_virtual_classroom_all_sessions_formation',
                            params: { virtualclassroom: props.match.params.id }
                        }, (response) => {
                            if (response.data) {
                                let data = response.data;
                                for (let index in data) {
                                    data[index].action = getAction(data[index]);
                                }
                                resolve(data);
                            } else {
                                resolve([]);
                            }
                        })
                    } else {
                        resolve([]);
                    }
                }}
            />

            <Dialog open={dialog} disabled={isCrmAdmin} maxWidth={'xl'} onClose={() => setDialog(false)}>
                <DialogTitle style={{fontSize: 15}}>Ajouter une session</DialogTitle>
                <DialogContent style={{minWidth: '50vw'}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={2} md={12} lg={6} xl={6}>
                            <SelectComponent name={'year'} handler={handler} options={optionAddYears} onChange={(value) => changeYear(value, true)}/>
                        </Grid>
                        <Grid item xs={12} sm={5} md={12} lg={6} xl={6}>
                            <SelectAutocompleteComponent name={'formation'} handler={handler} options={optionFormation} onChange={changeFormation}/>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                            <SelectComponent name={'sessionFormation'} handler={handler} options={optionsSessionFormation}/>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                            <SelectComponent name={'unit'} handler={handler} options={optionsUnits}/>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    {fullSessions ?
                        <ButtonComponent color={'#db770a'} label={'Cacher les sessions passées'} onClick={() => loadSession(false)} disabled={loading}/> :
                        <ButtonComponent color={'#28a745'} label={'Afficher les sessions passées'} onClick={() => loadSession(true)} disabled={loading}/>
                    }
                    <ButtonComponent color={'#5E6E82'} label={'Annuler'} onClick={() => setDialog(false)} disabled={loading}/>
                    <ButtonComponent label={'Enregistrer'} disabled={loading} onClick ={save}/>
                </DialogActions>
            </Dialog>

            <SweetAlert
                show={showConfirm}
                title={'Supprimer'}
                text={'Êtes-vous sur de vouloir supprimer cette session formation ?'}
                type={'warning'}
                cancelButtonText={'Non'}
                confirmButtonText={'Oui'}
                showCancelButton={true}
                onConfirm={() => {
                    dispatch(LinearProgressMainLayoutActivate());
                    setShowConfirm(false);
                    Api.post({
                        route: 'institution_health_content_manager_virtual_classroom_delete_session_formation',
                        params: { virtualClassroom: props.match.params.id },
                        data: {...sessionFormationToRemove},
                    },
                        (response) => {
                            dispatch(LinearProgressMainLayoutDeactivate());

                            if (response.status === 200) {
                                dispatch(
                                    SnackbarOpen({
                                        text: 'session formation supprimée.',
                                        variant: 'success',
                                    })
                                );
                                dispatch(TableReload('institution_health_content_manager_virtual_classroom_session'));
                            } else {
                                dispatch(
                                    SnackbarOpen({
                                        text: response.error && response.error.message ? response.error.message : 'Une erreur inattendue s\'est produite.',
                                        variant: 'error',
                                    })
                                );
                            }
                        });
                }}
                onCancel={() => setShowConfirm(false)}
            />
        </>
    )
}
