import React from 'react';
import ContentViewComponent from "../../../../Component/ContentViewComponent";
import TableComponent from "../../../../Component/TableComponent";
import Api from "../../../../Api";
import moment from "moment";
import { inArray } from "../../../../Tool/ArrayCommon";
import { Box, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import { Delete, Edit, Tune } from "@material-ui/icons";
import { formHandlerInit } from "../../../../Tool/FormHandlerCommon";
import { makeStyles } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import FilterListIcon from "@material-ui/icons/FilterList";
import SelectComponent from "../../../../Component/SelectComponent";
import MultipleSelectComponent from "../../../../Component/MultipleSelectComponent";
import Grid from "@material-ui/core/Grid";
import { getRoutePathname } from "../../../../Config/Route";
import TextFieldComponent from "../../../../Component/TextFieldComponent";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import SweetAlert from "sweetalert-react";
import { TableReload } from "../../../../Action/TableAction";
import { Drawer as DrawerMUI } from "@material-ui/core";
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from "../../../../Action/LinearProgressMainLayoutAction";
import { useDispatch, useSelector } from "react-redux";
import DatePickerComponent from "../../../../Component/DatePickerComponent";
import { cacheTablePage, cacheTableServer } from "../../../../Cache";
import { SnackbarOpen } from "../../../../Action/SnackbarAction";
import { hasRight } from "../../../../Tool/UserTool";
import SelectAutocompleteComponent from "../../../../Component/SelectAutocompleteComponent";
import ButtonComponent from "../../../../Component/ButtonComponent";
import { theme } from '../../../../App';

let VirtualClassChecked = [];
function VirtualClassroomList(props) {

    const dispatch = useDispatch();
    const classes = useStyles(theme)();
    const screenReducer = useSelector(state => state.ScreenReducer);
    const selectOrganismReducer = useSelector(state => state.SelectOrganismReducer)
    const authenticationReducer = useSelector(state => state.AuthenticationReducer);

    const [actionRow, setActionRow] = React.useState({});
    const [breadcrumbs, setBreadcrumbs] = React.useState({});

    const [optionYears, setOptionYears] = React.useState([]);
    const [optionFormer, setOptionFormer] = React.useState([]);
    const [optionPeriod, setOptionPeriod] = React.useState([]);
    const [optionsUnits, setOptionsUnits] = React.useState([]);
    const [optionAddYears, setOptionAddYears] = React.useState([]);
    const [optionAddFormer, setOptionAddFormer] = React.useState([]);
    const [optionFormation, setOptionFormation] = React.useState([]);
    const [optionsFormation, setOptionsFormation] = React.useState([]);
    const [optionsSessionFormation, setOptionsSessionFormation] = React.useState([]);

    const [isOpen, setIsOpen] = React.useState(false);
    const [dialog, setDialog] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [isScreen, setIsScreen] = React.useState(true);
    const [isCrmAdmin, setIsCrmAdmin] = React.useState(true);
    const [showConfirm, setShowConfirm] = React.useState(false);
    const [fullSessions, setFullSessions] = React.useState(false);
    const [isReadyContent, setReadyContent] = React.useState(false);

    const [form, setForm] = React.useState({
        year: {
            name: 'year',
            label: 'Année',
            textHelper: 'Choisissez une année.',
            type: 'integer',
            defaultValue: parseInt(moment().format('YYYY')),
            options: { validation: ['required'], }
        },
        formation: {
            name: 'formation',
            label: 'Formation',
            textHelper: 'Choisissez une formation.',
            type: 'integer',
            defaultValue: '',
            options: { validation: ['required'], }
        },
        sessionFormation: {
            name: 'sessionFormation',
            label: 'Session',
            textHelper: 'Choisissez une session.',
            type: 'integer',
            defaultValue: '',
            options: { validation: ['required'], }
        },
        unit: {
            name: 'unit',
            label: 'Unité',
            textHelper: 'Choisissez une unité.',
            type: 'integer',
            defaultValue: '',
            options: {validation: ['required'],}
        },
        period: {
            name: 'period',
            label: 'Créneaux',
            textHelper: 'Choisissez les crénaux de cette classe.',
            type: 'array',
            defaultValue: '',
            options: { validation: ['array', 'required'] }
        },
        former: {
            name: 'former',
            label: 'Formateur',
            textHelper: 'Choisissez un formateur',
            type: 'integer',
            defaultValue: '',
            options: { validation: ['required'], }
        },
        duration: {
            name: 'duration',
            label: 'Durée',
            textHelper: 'Indiquez la durée de cette classe virtuelle',
            type: 'integer',
            options: { validation: ['required'], }
        },
        meetingAt: {
            name: 'meetingAt',
            label: 'Date de la réunion ZOOM',
            textHelper: "Saisissez la date de la réunion ZOOM",
            type: 'date',
            defaultValue: '',
            options: { validation: ['date', 'required'] }
        },
    });
    const handler = formHandlerInit(form, setForm);

    const columns = [
        {
            name: "id",
            label: "Référence",
            options: { filter: true, sort: true }
        },
        {
            name: "formerName",
            label: "Formateur",
            options: { filter: true, sort: true }
        },
        {
            name: "formationName",
            label: "Formation",
            options: { filter: true, sort: true }
        },
        {
            name: "sessionStartAtText",
            label: "Debut de session",
            options: { filter: true, sort: true }
        },
        {
            name: "sessionEndAtText",
            label: "Fin de session",
            options: { filter: true, sort: true }
        },
        {
            name: "meetingAtText",
            label: "Date classe virtuelle",
            options: { filter: true, sort: true }
        },
        {
            name: "financeType",
            label: "Type de financement",
            options: { filter: true, sort: true }
        },
        {
            name: "registrations",
            label: "Participants",
            options: { filter: false, sort: false }
        },
        {
            name: "active",
            label: "Activer",
            options: { filter: true, sort: true }
        },
        {
            name: "action",
            label: "Action",
            options: { filter: false, sort: false, }
        }
    ];

    const getAction = (row) => {
        return (
            <>
                <Tooltip title={'Modifier'} placement="left">
                    <IconButton onClick={() => {
                        props.history.push(getRoutePathname('institution_health_content_manager_virtual_classroom', { id: row.id }));
                    }}>
                        <Edit />
                    </IconButton>
                </Tooltip>
                <Tooltip title={'Supprimer'} placement="left">
                    <IconButton onClick={() => {
                        setActionRow(row);
                        setShowConfirm(true);
                    }}>
                        <Delete />
                    </IconButton>
                </Tooltip>
            </>
        );
    };


    // Search
    const currentYear = new Date().getFullYear();
    const [formSearch, setFormSearch] = React.useState({
        formationName: {
            name: 'formationName',
            label: 'Formation',
            textHelper: 'Trier par formation.',
            type: 'text',
            defaultValue: cacheTableServer['institution_health_content_manager_virtual_classroom_list'] && cacheTableServer['institution_health_content_manager_virtual_classroom_list'].formationName ? cacheTableServer['institution_health_content_manager_virtual_classroom_list'].formationName : 'all',
            options: {}
        },
        formerName: {
            name: 'formerName',
            label: 'Formateurs',
            textHelper: 'Trier par formation.',
            type: 'text',
            defaultValue: cacheTableServer['institution_health_content_manager_virtual_classroom_list'] && cacheTableServer['institution_health_content_manager_virtual_classroom_list'].formerName ? cacheTableServer['institution_health_content_manager_virtual_classroom_list'].formerName : 'all',
            options: {}
        },
        sessionStartAtText: {
            name: 'sessionStartAtText',
            label: 'Date de début de session',
            textHelper: 'Saisissez une date.',
            type: 'date',
            defaultValue: cacheTableServer['institution_health_content_manager_virtual_classroom_list'] && cacheTableServer['institution_health_content_manager_virtual_classroom_list'].sessionStartAtText ? cacheTableServer['institution_health_content_manager_virtual_classroom_list'].sessionStartAtText : '',
            options: {}
        },
        sessionEndAtText: {
            name: 'sessionEndAtText',
            label: 'Date de fin de session.',
            textHelper: 'Saisissez une date.',
            type: 'date',
            defaultValue: cacheTableServer['institution_health_content_manager_virtual_classroom_list'] && cacheTableServer['institution_health_content_manager_virtual_classroom_list'].sessionEndAtText ? cacheTableServer['institution_health_content_manager_virtual_classroom_list'].sessionEndAtText : '',
            options: {}
        },
        meetingAtText: {
            name: 'meetingAtText',
            label: 'Date de session.',
            textHelper: 'Saisissez une date.',
            type: 'date',
            defaultValue: cacheTableServer['institution_health_content_manager_virtual_classroom_list'] && cacheTableServer['institution_health_content_manager_virtual_classroom_list'].meetingAtText ? cacheTableServer['institution_health_content_manager_virtual_classroom_list'].meetingAtText : '',
            options: {}
        },
        year: {
            name: 'year',
            label: 'Année',
            textHelper: 'Trier par année.',
            type: 'text',
            defaultValue: cacheTableServer['institution_health_content_manager_virtual_classroom_list'] && cacheTableServer['institution_health_content_manager_virtual_classroom_list'].year ? cacheTableServer['institution_health_content_manager_virtual_classroom_list'].year : currentYear,
            options: {}
        },
        financeType: {
            name: 'financeType',
            label: 'Type de financement',
            textHelper: 'Trier par type de financement.',
            type: 'text',
            defaultValue: cacheTableServer['institution_health_content_manager_virtual_classroom_list'] && cacheTableServer['institution_health_content_manager_virtual_classroom_list'].financeType ? cacheTableServer['institution_health_content_manager_virtual_classroom_list'].financeType : 'all',
            options: {}
        },
    });

    const handlerSearch = formHandlerInit(formSearch, setFormSearch);
    const inputSearchChange = (index, value) => {
        handlerSearch.setValue(index, value);
        cacheTablePage['institution_health_content_manager_virtual_classroom_list'] = 0;
        cacheTableServer['institution_health_content_manager_virtual_classroom_list'].page = 0;
        cacheTableServer['institution_health_content_manager_virtual_classroom_list'][index] = value;
        dispatch(TableReload('institution_health_content_manager_virtual_classroom_list'))
    };

    // UseEffect
    React.useEffect(handlerSearch.start, []);
    React.useEffect(handler.start, []);
    React.useEffect(() => {
        if (hasRight(authenticationReducer, 'ROLE_SUPER_ADMIN')) {
            setIsCrmAdmin(false);
        }
    }, []);

    React.useEffect(() => {
        setBreadcrumbs({
            title: 'Liste des classes virtuelles',
            context: 'Gestion de contenu',
            description: '',
            html: <GetActionDowlaod />
        });
    }, [isOpen]);

    React.useEffect(() => {
        // Year
        let yearsTMP = [];
        yearsTMP.push({ label: (currentYear + 1), value: (currentYear + 1) });
        for (let i = currentYear; i > currentYear - 5; i--) {
            yearsTMP.push({ label: i, value: i });
        }
        let yearsAddTMP = [
            { label: currentYear + 1, value: currentYear + 1 },
            { label: currentYear, value: currentYear },
            { label: currentYear - 1, value: currentYear - 1 }
        ];
        setOptionYears(yearsTMP);
        setOptionAddYears(yearsAddTMP);

        Api.get({
            route: 'select_formers',
            data: {isInstitution: true}
            },
            (response) => {
                if (response && response.data) {
                    let data = [{ value: 'all', label: 'Tous' }, ...response.data];
                    let dataAdd = [...response.data];
                    setOptionFormer(data);
                    setOptionAddFormer(dataAdd);
                }
            });

        Api.get({
            route: 'select_formations',
            data: {organism: selectOrganismReducer.organism.value}
        },
            (response) => {
                if (response && response.data) {
                    setOptionsFormation(response.data)
                    let data = [{ value: 'all', label: 'Tous' }, ...response.data];
                    setOptionFormation(data);
                }
            });

        Api.get({
            route: 'select_virtual_classroom_period'
        },
            (response) => {
                if (response && response.data) {
                    let data = [...response.data];
                    setOptionPeriod(data);
                }
            });

        setReadyContent(true);
    }, []);

    const save = (id, checked) => {
        Api.post({
            route: 'institution_health_content_manager_virtual_classroom_active',
            params: { id: id },
            data: { active: checked }
        },
            (response) => {
                console.log(response.status);
                if (response.status === 200) {
                    dispatch(
                        SnackbarOpen({
                            text: 'Classe virtuelle modifiée.',
                            variant: 'success',
                        })
                    );
                }
                else {
                    dispatch(
                        SnackbarOpen({
                            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
            });
    };
    const saveAdd = () => {
        if (handler.checkError()) {
            console.log('Error');
        }
        else {
            setLoading(true);
            handler.setFormLoading(true);
            dispatch(LinearProgressMainLayoutActivate());

            Api.post({
                route: 'institution_health_content_manager_virtual_classroom_add',
                data: { organism: selectOrganismReducer.organism.value, ...handler.getData()}
            },
                (response) => {
                    setLoading(false);
                    handler.setFormLoading(false);
                    dispatch(LinearProgressMainLayoutDeactivate());
                    if (response.status === 200) {
                        handler.setFormLoading(false)
                        dispatch(
                            TableReload('institution_health_content_manager_virtual_classroom_list'),
                            SnackbarOpen({
                                text: 'Classe virtuelle créée.',
                                variant: 'success',
                            }),
                        );
                        setDialog(false);
                    }
                    else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
        }

    };


    const getActionCheck = (data, checked) => {

        return (
            <Checkbox
                key={data + '-' + new Date().getTime()}
                checked={checked}
                onChange={() => {
                    if (inArray(data, VirtualClassChecked)) {
                        let index = VirtualClassChecked.indexOf(data);
                        if (index > -1) {
                            VirtualClassChecked.splice(index, 1);
                            save(data, false)
                            dispatch(TableReload('institution_health_content_manager_virtual_classroom_list'));
                        }
                    } else {
                        VirtualClassChecked.push(data);
                        save(data, true);
                        dispatch(TableReload('institution_health_content_manager_virtual_classroom_list'));
                    }
                }}
            />
        );
    };
    const getDatas = (resolve, filters) => {
        Api.get({
            route: 'institution_health_content_manager_virtual_classroom_list', data: filters
        },
            (response) => {
                let data = response.data.data;
                for (let index in data) {

                    data[index].formerName = data[index].formerLastname + "  " + data[index].formerFirstname;
                    data[index].sessionStartAtText = moment(data[index].sessionStartAt).format('DD/MM/YYYY');
                    data[index].sessionEndAtText = moment(data[index].sessionEndAt).format('DD/MM/YYYY');
                    data[index].meetingAtText = moment(data[index].meetingAt).format('DD/MM/YYYY');
                    data[index].action = getAction(data[index]);

                    if (data[index].TrueFalse === true) VirtualClassChecked.push(data[index].id);
                    data[index].active = getActionCheck(data[index].id, data[index].TrueFalse);

                }
                resolve(data, response.data.count);
            });
    }

    // Save
    const changeYear = (value, mustReload, formationId = '', formationSessionId = '') => {
        let newForm = { ...handler.form };
        newForm['year'].value = value;
        newForm['year'].error = handler.getErrorByField(newForm['year']);
        handler.set(newForm);
        if (mustReload) changeFormation(formationId, formationSessionId);
    };

    const filter = () => {
        return (
            <Box style={{ width: '90%', height: '100%', margin: 'auto', }}>
                <Box style={{
                    margin: 0,
                    display: 'flex',
                    alignItems: 'center',
                    padding: '19px 19px 10px 19px',
                    color: '#5E6E82', opacity: 0.7, fontSize: 14
                }}>FILTRE<hr style={{ width: '70%', border: '#5E6E82 1px solid', borderRadius: 10, opacity: 0.4 }} />
                    {!isScreen &&
                        <IconButton style={{ padding: 5 }} onClick={() => setIsOpen(false)}>
                            <HighlightOffIcon />
                        </IconButton>
                    }
                </Box>
                <Grid container spacing={2} style={{ width: '100%', margin: 0 }}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <SelectComponent name={'year'} handler={handlerSearch} options={optionYears} onChange={(val) => inputSearchChange('year', val)} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <DatePickerComponent name={'sessionStartAtText'} handler={handlerSearch} onChange={(val) => inputSearchChange('sessionStartAtText', val)} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <DatePickerComponent name={'sessionEndAtText'} handler={handlerSearch} onChange={(val) => inputSearchChange('sessionEndAtText', val)} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <DatePickerComponent name={'meetingAtText'} handler={handlerSearch} onChange={(val) => inputSearchChange('meetingAtText', val)} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <SelectComponent name={'formerName'} handler={handlerSearch} options={optionFormer} onChange={(val) => inputSearchChange('formerName', val)} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <SelectAutocompleteComponent name={'formationName'} handler={handlerSearch} options={optionFormation} onChange={(val) => inputSearchChange('formationName', val)} />
                    </Grid>
                </Grid>
            </Box>
        )
    }

    const GetActionDowlaod = () => {
        return (
            <Box style={{ display: 'flex', gap: 12 }}>
                <ButtonComponent color={"#5E6E82"} label={<><FilterListIcon style={{ color: '#FFFFFF' }} /> Filtre</>} onClick={() => setIsOpen(!isOpen)} />
            </Box>
        )
    }

    const changeFormation = (value, formationSessionId = '') => {
        let newForm = { ...handler.form };
        newForm['formation'].value = value;
        newForm['formation'].error = value !== '' ? handler.getErrorByField(newForm['formation']) : '';
        handler.set(newForm);

        loadSession(fullSessions, formationSessionId);
    };

    const loadSession = (full = false, formationSessionId = '') => {
        setFullSessions(full);

        let formationIdTMP = form['formation'].value;
        let yearTMP = form['year'].value;

        let newForm = { ...handler.form };
        newForm['sessionFormation'].value = formationSessionId;
        newForm['sessionFormation'].error = formationSessionId !== '' ? handler.getErrorByField(newForm['sessionFormation']) : '';
        handler.set(newForm);

        if (formationIdTMP) {
            handler.setFormLoading(true);
            Api.get({
                route: 'institution_health_content_manager_virtual_classroom_sessions_formation_list',
                data: { full: full ? 1 : 0, formation: formationIdTMP, year: yearTMP, organism: selectOrganismReducer.organism.value }
            },
                (response) => {
                    if (response && response.data) {
                        setOptionsSessionFormation(response.data);
                    }
                    loadUnits(formationIdTMP)
                });
        }
        else {
            setOptionsUnits([]);
            setOptionsSessionFormation([]);
        }
    };

    const loadUnits = (formationId, unitId = '') => {
        Api.get(
            {
                route: 'institution_health_content_manager_virtual_classroom_unit_list',
                data: {formation: formationId}
            },
            (response) => {
                handler.setFormLoading(false);
                if (response && response.data) {
                    setOptionsUnits(response.data);
                }
            }
        );
        let newForm = {...handler.form};
        newForm['unit'].value = unitId;
        newForm['unit'].error = unitId !== '' ? handler.getErrorByField(newForm['unit']) : '';
        handler.set(newForm);
    };


    React.useEffect(() => {
        if (screenReducer.screen) {
            setIsScreen(screenReducer.screen === 'LG' || screenReducer.screen === 'XL');
        }
    }, [screenReducer.screen]);

    return (
        <Box className={classes.styleContentView}>
            <DrawerMUI
                variant="permanent"
                className={`${classes.drawer} ${isOpen ? classes.drawerOpen : classes.drawerClose}`}
                style={!isScreen ? { position: 'absolute', zIndex: 10 } : {}}
                open={isOpen}>
                {filter()}
            </DrawerMUI>
            <ContentViewComponent loading={!isReadyContent} breadcrumbs={breadcrumbs} {...props}>
                <Box className={classes.TableComponent} style={{ width: '95%' }}>
                    <TableComponent
                        id={'institution_health_content_manager_virtual_classroom_list'}
                        title={'Classes virtuelles'}
                        columns={columns}
                        actionFirst={{
                            label: 'Ajouter une classe virtuelle',
                            onClick: () => {
                                setDialog(true);
                                handler.reset();
                            }
                        }}
                        promiseServerData={(resolve, options) => {
                            let datas = handlerSearch.getData();
                            let filters = {
                                organism: selectOrganismReducer.organism.value,
                                limit: options.rowsPerPage,
                                offset: options.page * options.rowsPerPage,
                                sortName: options.sortOrder.name,
                                sortDirection: options.sortOrder.direction,
                                year: datas.year ?? currentYear,
                                formerName: (datas.formerName === 'all') ? '' : datas.formerName,
                                sessionStartAt: datas.sessionStartAtText ?? '',
                                sessionEndAt: datas.sessionEndAtText ?? '',
                                meetingAt: datas.meetingAtText ?? '',
                                formationName: (datas.formationName === 'all') ? '' : datas.formationName,
                                financeType: 7,
                            };
                            getDatas(resolve, filters)
                        }}
                        search={false}
                    />
                    <br />
                    <SweetAlert
                        show={showConfirm}
                        title={'Supprimer'}
                        text={actionRow.firstname + ' ' + actionRow.lastname + '\nÊtes-vous sur de vouloir supprimer cet classe virtuelle ?'}
                        type={'warning'}
                        cancelButtonText={'Non'}
                        confirmButtonText={'Oui'}
                        showCancelButton={true}
                        onConfirm={() => {
                            dispatch(LinearProgressMainLayoutActivate());
                            setShowConfirm(false);
                            Api.delete({
                                route: 'institution_health_content_manager_virtual_classroom_delete',
                                params: { id: actionRow.id }
                            },
                                (response) => {
                                    dispatch(LinearProgressMainLayoutDeactivate());

                                    if (response.status === 200) {
                                        dispatch(
                                            SnackbarOpen({
                                                text: 'Classe virtuelle supprimée.',
                                                variant: 'success',
                                            })
                                        );
                                        dispatch(TableReload('institution_health_content_manager_virtual_classroom_list'));
                                    } else {
                                        dispatch(
                                            SnackbarOpen({
                                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                                variant: 'error',
                                            })
                                        );
                                    }
                                });
                        }}
                        onCancel={() => setShowConfirm(false)}
                    />
                    <Dialog open={dialog} disabled={isCrmAdmin} maxWidth={'xl'} onClose={() => setDialog(false)}>
                        <DialogTitle style={{ fontSize: 15 }}>Ajouter une class virtuelle</DialogTitle>
                        <DialogContent style={{ minWidth: '30vw' }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={2} md={12} lg={3} xl={3}>
                                    <SelectComponent name={'year'} handler={handler} options={optionAddYears} onChange={(value) => changeYear(value, true)} />
                                </Grid>
                                <Grid item xs={12} sm={5} md={12} lg={3} xl={3}>
                                    <SelectAutocompleteComponent name={'formation'} handler={handler} options={optionsFormation} onChange={changeFormation} />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                                    <SelectComponent name={'sessionFormation'} handler={handler} options={optionsSessionFormation}/>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                                    <SelectComponent name={'unit'} handler={handler} options={optionsUnits}/>
                                </Grid>

                                <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                                    <SelectComponent name={'former'} handler={handler} options={optionAddFormer} />
                                </Grid>
                                <Grid item xs={12} sm={5} md={12} lg={3} xl={3}>
                                    <MultipleSelectComponent name={'period'} handler={handler} options={optionPeriod} />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                                    <TextFieldComponent name={'duration'} handler={handler} />
                                </Grid>
                                <Grid item xs={12} sm={12} md={3} lg={3}>
                                    <DatePickerComponent name={'meetingAt'} handler={handler} />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            {fullSessions ?
                                <ButtonComponent color={'#db770a'} label={'Cacher les sessions passées'} onClick={() => loadSession(false)} disabled={loading} /> :
                                <ButtonComponent color={'#28a745'} label={'Afficher les sessions passées'} onClick={() => loadSession(true)} disabled={loading} />
                            }
                            <ButtonComponent color={'#5E6E82'} label={'Annuler'} onClick={() => setDialog(false)} disabled={loading} />
                            <ButtonComponent label={'Enregistrer'} disabled={loading} onClick={saveAdd} />
                        </DialogActions>
                    </Dialog>
                </Box>
            </ContentViewComponent>
        </Box>
    );
}
const drawerOpenWidth = 340;
const drawerCloseWidth = 0;

const useStyles = (theme) => makeStyles(style => ({
    TableComponent: {
        height: '90%',
        width: '95%',
        margin: 'auto',
        '& >div': {
            height: '100%',
            '& .MuiPaper-root': {
                height: '100%',
                display: 'grid',
                gridTemplateRows: ' min-content min-content auto min-content min-content',
                overflow: 'hidden',
                '& div .MuiTable-root': {
                    position: 'absolute'
                }
            }
        },
        '& .MuiTableCell-head:last-child': {
            width: 145,
            textAlign: 'center'
        }
    },
    drawer: {
        width: drawerOpenWidth,
        height: '100%',
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxShadow: '-1px 0px 2px rgba(0,0,0,0.2)',
        background: '#FFFFFF',
        overflowX: 'hidden',
        zIndex: 1,
        boxSizing: 'border-box',
        '& .MuiDrawer-paperAnchorDockedLeft': {
            border: 0
        },
        '& .MuiDrawer-paper': {
            height: '100%',
            boxSizing: 'border-box',
            width: '100%',
            overflowX: 'hidden',
            position: 'relative'
        }
    },
    drawerOpen: {
        transition: style.transitions.create('width', {
            easing: style.transitions.easing.sharp,
            duration: style.transitions.duration.enteringScreen,
        }),
        width: drawerOpenWidth
    },
    drawerClose: {
        transition: style.transitions.create('width', {
            easing: style.transitions.easing.sharp,
            duration: style.transitions.duration.leavingScreen,
        }),
        width: drawerCloseWidth
    },
    styleContentView: {
        height: '100%',
        display: 'flex',
        flexDirection: 'row-reverse',
        '& .ContentView-root': {
            height: '100%',
            display: 'grid',
            gridTemplateRows: 'min-content auto',
            width: '100%',
            '& .Breadcrumbs-root:nth-of-type(1)': {
                margin: 0,
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
                height: 'auto',
                '& div:nth-of-type(2)': {
                    margin: '0 0 0 auto',
                }
            }
        }
    },
    h3: {
        color: '#5E6E82',
        fontSize: 18,
        fontWeight: 300,
        margin: '0px 0px 10px 0px',
        padding: '0px 5px',
    }, iconH3: {
        fontSize: 25,
        padding: '0px 5px',
        verticalAlign: 'sub',
        float: 'right'
    }
}));
export default VirtualClassroomList;
