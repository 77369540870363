import React from 'react';
import { Box, IconButton, Tooltip, makeStyles, Drawer as DrawerMUI, Button, Popover, Typography } from '@material-ui/core';
import SweetAlert from "sweetalert-react";
import ContentViewComponent from '../../../../Component/ContentViewComponent';
import TableComponent from '../../../../Component/TableComponent';
import { useDispatch, useSelector } from 'react-redux';
import { getRoutePathname } from '../../../../Config/Route';
import ApartmentIcon from '@material-ui/icons/Apartment';
import { Delete, Edit } from '@material-ui/icons';
import DashboardIcon from '@material-ui/icons/Dashboard';
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from '../../../../Action/LinearProgressMainLayoutAction';
import ButtonComponent from '../../../../Component/ButtonComponent';
import { cacheTablePage, cacheTableServer } from "../../../../Cache";
import Api from '../../../../Api';
import { SnackbarOpen } from '../../../../Action/SnackbarAction';
import { TableReload } from '../../../../Action/TableAction';
import TextFieldComponent from '../../../../Component/TextFieldComponent';
import { formHandlerInit } from '../../../../Tool/FormHandlerCommon';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Grid from "@material-ui/core/Grid";
import SelectComponent from "../../../../Component/SelectComponent";
import DatePickerComponent from "../../../../Component/DatePickerComponent";
import FilterListIcon from "@material-ui/icons/FilterList";
import CheckboxComponent from "../../../../Component/CheckboxComponent";
import SelectAutocompleteComponent from "../../../../Component/SelectAutocompleteComponent";
import MultipleSelectComponent from "../../../../Component/MultipleSelectComponent";
import ShadowBoxComponent from '../../../../Component/ShadowBoxComponent';

export default function InstitutionList(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [isReadyContent, setReadyContent] = React.useState(false);
    const selectOrganismReducer = useSelector(state => state.SelectOrganismReducer);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [showConfirm, setShowConfirm] = React.useState(false);
    const [actionRow, setActionRow] = React.useState([]);
    const [isOpen, setIsOpen] = React.useState(false);
    const [isScreen, setIsScreen] = React.useState(true);
    const [optionCommercial, setOptionCommercial] = React.useState([]);
    const [optionState, setOptionState] = React.useState([]);
    const [optionGroup, setOptionGroup] = React.useState([]);
    const [optionType, setOptionType] = React.useState([]);
    const [optionFinanceTypes, setOptionFinanceTypes] = React.useState([]);
    const [optionStatus, setOptionStatus] = React.useState([]);
    const [loadDownload, setLoadDownload] = React.useState(false);
    const screenReducer = useSelector(state => state.ScreenReducer);

    const columns = [
        {
            name: "id",
            label: "Référence",
            options: { filter: true, sort: true }
        },
        {
            name: "name",
            label: "Nom",
            options: { filter: true, sort: true }
        },
        {
            name: "holder",
            label: "Titulaire",
            options: { filter: true, sort: true }
        },
        {
            name: "commercial",
            label: "Commercial",
            options: { filter: true, sort: true }
        },
        {
            name: "employeeNumber",
            label: "Salariés",
            options: { filter: true, sort: true }
        },
        {
            name: "email",
            label: "Email",
            options: { filter: true, sort: true }
        },
        {
            name: "phone",
            label: "Téléphone",
            options: { filter: true, sort: true }
        },
        {
            name: "mobile",
            label: "Mobile",
            options: { filter: true, sort: true }
        },
        {
            name: "address",
            label: "Adresse",
            options: { filter: true, sort: true }
        },
        {
            name: "groupName",
            label: "Groupement",
            options: { filter: true, sort: true }
        },
        {
            name: "institutionType",
            label: "Type d'établissement",
            options: { filter: true, sort: true }
        },
        {
            name: "action",
            label: "Action",
            options: { filter: false, sort: false }
        }
    ];

    const [formSearch, setFormSearch] = React.useState({
        commercial: {
            name: 'commercial',
            label: 'Commercial',
            textHelper: 'Trier par commercial.',
            type: 'text',
            defaultValue: cacheTableServer['institution_health_crm_institution_list'] && cacheTableServer['institution_health_crm_institution_list'].commercial ? cacheTableServer['institution_health_crm_institution_list'].commercial : 'all',
            options: {}
        },
        state: {
            name: 'state',
            label: 'État',
            textHelper: 'Trier par état.',
            type: 'text',
            defaultValue: cacheTableServer['institution_health_crm_institution_list'] && cacheTableServer['institution_health_crm_institution_list'].state ? cacheTableServer['institution_health_crm_institution_list'].state : 'all',
            options: {}
        },
        status: {
            name: 'status',
            label: 'Status',
            textHelper: 'Trier par status.',
            type: 'text',
            defaultValue: cacheTableServer['institution_health_crm_institution_list'] && cacheTableServer['institution_health_crm_institution_list'].status ? cacheTableServer['institution_health_crm_institution_list'].status : 'all',
            options: {}
        },
        city: {
            name: 'city',
            label: 'Ville',
            textHelper: 'Saisissez une ville.',
            type: 'text',
            defaultValue: cacheTableServer['institution_health_crm_institution_list'] && cacheTableServer['institution_health_crm_institution_list'].city ? cacheTableServer['institution_health_crm_institution_list'].city : '',
            options: {}
        },
        phone: {
            name: 'phone',
            label: 'Téléphone',
            textHelper: 'Saisissez un téléphone.',
            type: 'text',
            defaultValue: cacheTableServer['institution_health_crm_institution_list'] && cacheTableServer['institution_health_crm_institution_list'].phone ? cacheTableServer['institution_health_crm_institution_list'].phone : '',
            options: {}
        },
        zipCode: {
            name: 'zipCode',
            label: 'Code postal',
            textHelper: 'Saisissez le code postal de l\’établissement',
            type: 'text',
            defaultValue: cacheTableServer['institution_health_crm_institution_list'] && cacheTableServer['institution_health_crm_institution_list'].zipCode ? cacheTableServer['institution_health_crm_institution_list'].zipCode : '',
            options: {}
        },
        group: {
            name: 'group',
            label: 'Groupement d’établissement',
            textHelper: 'Trier par groupement d’établissement.',
            type: 'text',
            defaultValue: cacheTableServer['institution_health_crm_institution_list'] && cacheTableServer['institution_health_crm_institution_list'].group ? cacheTableServer['institution_health_crm_institution_list'].group : 'all',
            options: {}
        },
        type: {
            name: 'type',
            label: 'Type d’établissement',
            textHelper: 'Trier par Type d’établissement.',
            type: 'text',
            defaultValue: cacheTableServer['institution_health_crm_institution_list'] && cacheTableServer['institution_health_crm_institution_list'].type ? cacheTableServer['institution_health_crm_institution_list'].type : 'all',
            options: {}
        },
        registrationAt: {
            name: 'registrationAt',
            label: 'Aucune inscription depuis le...',
            textHelper: 'Saisissez une date.',
            type: 'date',
            defaultValue: cacheTableServer['institution_health_crm_institution_list'] && cacheTableServer['institution_health_crm_institution_list'].registrationAt ? cacheTableServer['institution_health_crm_institution_list'].registrationAt : null,
            options: {}
        },
        hasPhone: {
            name: 'hasPhone',
            label: 'Téléphone obligatoire',
            textHelper: '',
            type: 'boolean',
            defaultValue: cacheTableServer['institution_health_crm_institution_list'] && cacheTableServer['institution_health_crm_institution_list'].hasPhone ? cacheTableServer['institution_health_crm_institution_list'].hasPhone : false,
            options: {}
        },
        financeTypes: {
            name: 'financeTypes',
            label: 'En ne tenant compte que...',
            textHelper: 'Saisissez les types de financement.',
            type: 'array',
            defaultValue: cacheTableServer['institution_health_crm_institution_list'] && cacheTableServer['institution_health_crm_institution_list'].financeTypes ? cacheTableServer['institution_health_crm_institution_list'].financeTypes : '',
            options: {}
        }
    });

    React.useEffect(() => {
        Api.get({
            route: 'institution_health_crm_commercial_list',
            data: { organismId: selectOrganismReducer.organism.value }
        },
            (response) => {
                if (response && response.data) {
                    setOptionCommercial([...[{ value: 'all', label: 'Tous' }, { value: '0', label: 'Aucun' }], ...response.data]);;
                }
            });
        Api.get({
            route: 'institution_groups',
        },
            (response) => {
                if (response && response.data) {
                    setOptionGroup([...[{ value: 'all', label: 'Tous' }, { value: '0', label: 'Aucun' }], ...response.data]);
                }
            });
        Api.get({
            route: 'institution_states',
        },
            (response) => {
                if (response && response.data) {
                    setOptionState([...[{ value: 'all', label: 'Tous' }, ...response.data]]);
                }
            });
        Api.get({
            route: 'institution_types',
        },
            (response) => {
                if (response && response.data) {
                    setOptionType([...[{ value: 'all', label: 'Tous' }, ...response.data]]);
                }
            });
        Api.get({
            route: 'select_finance_types',
            data: { 'isFull': true, 'isInstitution': true }
        },
            (response) => {
                if (response && response.data) {
                    setOptionFinanceTypes(response.data);
                }
            });
        setOptionStatus([{ value: 'all', label: 'Tous' }, { value: 1, label: 'Prospect' }, { value: 2, label: 'Nouveau client' }, { value: 3, label: 'Client' }, { value: 4, label: 'Client inactif' }, { value: 5, label: 'Dépot de bilan' }, { value: 6, label: 'Archiver' }])
    }, [])

    const handlerSearch = formHandlerInit(formSearch, setFormSearch);
    React.useEffect(handlerSearch.start, []);

    const inputSearchChange = (index, value) => {
        handlerSearch.setValue(index, value);
        if (index === 'registrationAt' && value === '') {
            inputSearchChange('financeTypes', value)
        }
        cacheTablePage['institution_health_crm_institution_list'] = 0;
        cacheTableServer['institution_health_crm_institution_list'].page = 0;
        cacheTableServer['institution_health_crm_institution_list'][index] = value;
        dispatch(TableReload('institution_health_crm_institution_list'))
    };
    const getFilterValues = (options) => {
        let data = handlerSearch.getData();
        for (const key in data) {
            if (key === 'registrationAt' && data[key] === '') {
                data[key] = null;
            } else if (key === 'financeTypes' && data[key].length === 0) {
                data[key] = null;
            } else if (key === 'group') {
                if (data[key] === '0') {
                    data.isGroupLess = true;
                    data[key] = null
                } else {
                    data.isGroupLess = null;
                    data[key] = data[key] === 'all' ? null : data[key]
                }
            } else if (
                key !== 'phone'
                && key !== 'zipCode'
                && key !== 'city'
                && data[key] === 'all'
            ) {
                data[key] = null;
            }
        }
        let optionsFilter = {}

        if (options) {
            optionsFilter = {
                text: options.searchText,
                limit: options.rowsPerPage,
                offset: options.page * options.rowsPerPage,
                sortName: options.sortOrder.name,
                sortDirection: options.sortOrder.direction
            }
        } else {
            optionsFilter = {
                limit: 10000,
            }
        }

        const filters = {
            ...data,
            ...optionsFilter
        };
        return filters
    }
    const getDownloadExcel = () => {
        setLoadDownload(true);
        let filters = getFilterValues();
        Api.getDownload({
            route: 'institution_health_crm_institution_list',
            filename: 'export_institution.xls',
            data: { ...filters, organismId: selectOrganismReducer.organism.value }
        }, (response) => {
            if (response.status === 200) {
                setLoadDownload(false)
            }
        })
    }

    const filter = () => {
        return (
            <Box style={{ width: '90%', height: '100%', margin: 'auto', }}>
                <Box style={{
                    margin: 0,
                    display: 'flex',
                    alignItems: 'center',
                    padding: '19px 19px 10px 19px',
                    color: '#5E6E82', opacity: 0.7, fontSize: 14
                }}>FILTRE<hr style={{ width: '70%', border: '#5E6E82 1px solid', borderRadius: 10, opacity: 0.4 }} />
                    {!isScreen &&
                        <IconButton style={{ padding: 5 }} onClick={() => setIsOpen(false)}>
                            <HighlightOffIcon />
                        </IconButton>
                    }
                </Box>
                <Grid container spacing={2} style={{ width: '100%', margin: 0 }}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <SelectComponent name={'commercial'} handler={handlerSearch} options={optionCommercial} onChange={(val) => inputSearchChange('commercial', val)} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <TextFieldComponent name={'city'} handler={handlerSearch} onChange={(val) => inputSearchChange('city', val)} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <TextFieldComponent name={'zipCode'} handler={handlerSearch} onChange={(val) => inputSearchChange('zipCode', val)} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <TextFieldComponent name={'phone'} handler={handlerSearch} onChange={(val) => inputSearchChange('phone', val)} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <SelectComponent name={'state'} handler={handlerSearch} options={optionState} onChange={(val) => inputSearchChange('state', val)} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <SelectComponent name={'status'} handler={handlerSearch} options={optionStatus} onChange={(val) => inputSearchChange('status', val)} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <SelectAutocompleteComponent name={'group'} handler={handlerSearch} options={optionGroup} onChange={(val) => inputSearchChange('group', val)} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <SelectComponent name={'type'} handler={handlerSearch} options={optionType} onChange={(val) => inputSearchChange('type', val)} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <DatePickerComponent name={'registrationAt'} handler={handlerSearch} onChange={(val) => inputSearchChange('registrationAt', val)} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <MultipleSelectComponent name={'financeTypes'} disabled={!formSearch['registrationAt'].value} handler={handlerSearch} options={optionFinanceTypes} onChange={(val) => inputSearchChange('financeTypes', val)} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <CheckboxComponent name={'hasPhone'} handler={handlerSearch} onChange={(val) => inputSearchChange('hasPhone', val)} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Button className={classes.downloadBtn} onClick={() => getDownloadExcel()} disabled={loadDownload}>
                            Télécharger au format Excel
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        )
    }

    const getAction = (row) => {
        return (
            <Box>
                <Tooltip title={'Modifier'} placement="left">
                    <IconButton onClick={() => { props.history.push(getRoutePathname('institution_health_crm_institution', { institution: row.id })) }}>
                        <Edit style={{ color: '#5E6E82' }} />
                    </IconButton>
                </Tooltip>
                <Tooltip title={'Tableau de bord'} placement="left">
                    <IconButton onClick={() => { props.history.push(getRoutePathname('institution_health_crm_institution_manager', { institution: row.id })) }}>
                        <DashboardIcon style={{ color: '#5E6E82' }} />
                    </IconButton>
                </Tooltip>
                <Tooltip title={'Supprimer'} placement="left">
                    <IconButton onClick={() => { setShowConfirm(true); setActionRow(row) }}>
                        <Delete style={{ color: '#5E6E82' }} />
                    </IconButton>
                </Tooltip>
            </Box>
        )
    }
    const GetActionMore = () => {
        return (
            <Box style={{ display: 'flex', gap: 12 }}>
                <ButtonComponent
                    color={"#5E6E82"}
                    label={'Groupement d\'établissements'}
                    onClick={() => { props.history.push(getRoutePathname('institution_health_crm_institution_group_list')) }}
                />
                <ButtonComponent
                    color={"#5E6E82"}
                    onClick={() => setIsOpen(!isOpen)}
                    label={<><FilterListIcon style={{ color: '#FFFFFF' }} /> Filtre</>}
                />
            </Box>
        )
    }

    React.useEffect(() => {
        dispatch(LinearProgressMainLayoutDeactivate());
        setBreadcrumbs({
            title: 'Établissement de santé',
            context: 'Portail',
            path: getRoutePathname('institution_health_home'),
            description: 'Liste des établissement',
            html: <GetActionMore />
        });

        setReadyContent(true);
    }, [isOpen]);

    React.useEffect(() => {
        if (screenReducer.screen) {
            setIsScreen(screenReducer.screen === 'LG' || screenReducer.screen === 'XL');
        }
    }, [screenReducer.screen]);

    return (
        <Box className={classes.styleContentView}>
            <DrawerMUI
                variant="permanent"
                className={`${classes.drawer} ${isOpen ? classes.drawerOpen : classes.drawerClose}`}
                style={!isScreen ? { position: 'absolute', zIndex: 10 } : {}}
                open={isOpen}>
                {filter()}
            </DrawerMUI>
            <ContentViewComponent loading={!isReadyContent} breadcrumbs={breadcrumbs} {...props}>
                <Box className={classes.TableComponent} style={{ width: '95%' }}>
                    <TableComponent
                        id={'institution_health_crm_institution_list'}
                        title={<h1 style={{ margin: 0, fontSize: 15, display: 'flex', alignItems: 'center', gap: 5, }}><ApartmentIcon style={{ width: 20, height: 20 }} />Vos établissements</h1>}
                        columns={columns}
                        actionFirst={{
                            label: 'Ajouter un établissement',
                            onClick: () => props.history.push(getRoutePathname('institution_health_crm_institution', { institution: null })),
                        }}
                        search={true}
                        promiseServerData={(resolve, options) => {
                            let filters = getFilterValues(options);
                            Api.get({
                                route: 'institution_health_crm_institution_list',
                                data: { ...filters, organismId: selectOrganismReducer.organism.value }
                            },
                                (response) => {
                                    if (response.status === 200) {
                                        let data = response.data.data;
                                        for (let index in data) {
                                            data[index].action = getAction(data[index]);
                                            data[index].holder = data[index].holder.length > 0 ? <CardHolderComponent holder={data[index].holder} /> : '-';
                                            data[index].email = data[index].email ? data[index].email : '-';
                                            data[index].name = data[index].name ? data[index].name : '-';
                                            data[index].groupName = data[index].groupName ? data[index].groupName : '-';
                                            data[index].commercial = data[index].commercial ? data[index].commercial : '-';

                                            if (data[index].phone) {
                                                continue;
                                            } else if (data[index].mobile) {
                                                data[index].phone = data[index].mobile;
                                            } else {
                                                data[index].mobile = '-';
                                                data[index].phone = '-'
                                            }
                                        }
                                        resolve(data, response.data.count);
                                    } else resolve([]);
                                });
                        }}
                    />
                    <SweetAlert
                        show={showConfirm}
                        title={'Supprimer'}
                        text={'\nÊtes-vous sur de vouloir supprimer cet établissement ?'}
                        type={'warning'}
                        cancelButtonText={'Non'}
                        confirmButtonText={'Oui'}
                        showCancelButton={true}
                        onConfirm={() => {
                            dispatch(LinearProgressMainLayoutActivate());
                            setShowConfirm(false);
                            Api.delete({
                                route: 'institution_health_crm_institution_delete',
                                params: { id: actionRow.id }
                            },
                                (response) => {
                                    dispatch(LinearProgressMainLayoutDeactivate());

                                    if (response.status === 200) {
                                        dispatch(
                                            SnackbarOpen({
                                                text: 'Établissement supprimé.',
                                                variant: 'success',
                                            })
                                        );
                                        dispatch(TableReload('institution_health_crm_institution_list'));
                                    } else {
                                        dispatch(
                                            SnackbarOpen({
                                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                                variant: 'error',
                                            })
                                        );
                                    }
                                });
                        }}
                        onCancel={() => setShowConfirm(false)}
                    />
                </Box>
            </ContentViewComponent>
        </Box>
    );
}

const CardHolderComponent = (props) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);
    const open = Boolean(anchorEl);
    const idPopover = open ? 'simple-popover' : undefined;
    const viewAction = (row) => {
        return (
            <div style={{ margin: 'auto', width: '100%', textAlign: 'center' }}>
                <IconButton style={{ padding: '5px 0px', borderRadius: 0, height: ' 100%', width: '100%' }} onClick={handleClick}>
                    {`${row[0].lastName && row[0].lastName} ${row[0].firstName && row[0].firstName} `}
                    <span style={{ margin: 0, height: '100%' }}>
                        {props.holder.length > 1 && '[...]'}
                    </span>
                </IconButton>
                <Popover
                    id={idPopover}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    className={classes.popover}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    <Typography style={{ padding: 8 }}>
                        {getAction(row)}
                    </Typography>
                </Popover>
            </div>
        )
    }

    const getAction = (row) => {
        return (row.map((data, index) => (
            <p key={index} style={{ padding: '5px 10px' }}>{data.lastName + ' ' + data.firstName}</p>
        )))
    }

    return (
        <>
            <ShadowBoxComponent className={classes.iconButton} style={{ background: props.holder.length > 1 ? '#FFFFFF' : 'transparent' }}>
                <Box style={{ height: '100%' }}>
                    {props.holder.length > 1 && <p style={{ margin: 0, padding: '5px 10px', borderRadius: 5, background: 'rgb(94, 110, 130)', color: '#FFF' }}>{props.holder.length}</p>}
                </Box>
                {props.holder.length > 1 ? viewAction(props.holder) : <span style={{ margin: 'auto', textAlign: 'center' }} >{(`${props.holder[0].lastName && props.holder[0].lastName} ${props.holder[0].firstName && props.holder[0].firstName} `)}</span>}
            </ShadowBoxComponent >
        </>
    )
}

const drawerOpenWidth = 340;
const drawerCloseWidth = 0;
const useStyles = makeStyles(style => ({
    TableComponent: {
        height: '90%',
        width: '95%',
        margin: 'auto',
        '& >div': {
            height: '100%',
            '& .MuiPaper-root': {
                height: '100%',
                display: 'grid',
                gridTemplateRows: ' min-content min-content auto min-content min-content',
                overflow: 'hidden',
                '& div .MuiTable-root': {
                    position: 'absolute'
                }
            }
        },
        '& .MuiTableCell-head:last-child': {
            width: 145,
            textAlign: 'center'
        }
    },
    styleContentView: {
        height: '100%',
        display: 'flex',
        flexDirection: 'row-reverse',
        '& .ContentView-root': {
            height: '100%',
            display: 'grid',
            gridTemplateRows: 'min-content auto',
            width: '100%',
            '& .Breadcrumbs-root:nth-of-type(1)': {
                margin: 0,
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
                height: 'auto',
                '& div:nth-of-type(2)': {
                    margin: '0 0 0 auto',
                }
            }
        }
    },
    drawer: {
        width: drawerOpenWidth,
        height: '100%',
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxShadow: '-1px 0px 2px rgba(0,0,0,0.2)',
        background: '#FFFFFF',
        overflowX: 'hidden',
        zIndex: 1,
        boxSizing: 'border-box',
        '& .MuiDrawer-paperAnchorDockedLeft': {
            border: 0
        },
        '& .MuiDrawer-paper': {
            height: '100%',
            boxSizing: 'border-box',
            width: '100%',
            overflowX: 'hidden',
            position: 'relative'
        }
    },
    drawerOpen: {
        transition: style.transitions.create('width', {
            easing: style.transitions.easing.sharp,
            duration: style.transitions.duration.enteringScreen,
        }),
        width: drawerOpenWidth
    },
    drawerClose: {
        transition: style.transitions.create('width', {
            easing: style.transitions.easing.sharp,
            duration: style.transitions.duration.leavingScreen,
        }),
        width: drawerCloseWidth
    },
    downloadBtn: {
        background: '#3f51b5',
        color: 'white',
        '&:hover': {
            background: '#303f9f',
        }
    },
    iconButton: {
        height: 'auto',
        display: 'flex',
        color: '#000',
        alignItems: 'center',
        boxShadow: 'none',
        margin: '0',
        gap: 5,
        padding: 4,
        borderRadius: 5,
        width: 'fit-content',
        "& .MuiIconButton-label": {
            color: '#000',
        },
        "& span": {
            width: 'max-content',
            fontSize: 12,
        }
    },
    popover: {
        '& .MuiPaper-root': {
            margin: 12,
            border: '1px solid rgba(94, 110, 130, 0.38)',
            boxShadow: 'none'
        },
        '& p': {
            margin: 2,
            fontSize: 12,
            padding: 3,
            color: 'rgb(94, 110, 130)',
            fontWeight: 'bold',
            background: 'rgba(94, 110, 130, 0.06)',
            borderRadius: 2
        }
    }
}))