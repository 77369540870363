import React from "react";
import { dispatch } from "../../../../App";
import { getRoutePathname } from "../../../../Config/Route";
import { Box, Dialog, DialogActions, DialogContent, Grid, makeStyles } from "@material-ui/core";
import SweetAlert from "sweetalert-react";
import CardDiscountComponent from "./Component/CardDiscountComponent";
import { formHandlerInit } from "../../../../Tool/FormHandlerCommon";
import TextFieldComponent from "../../../../Component/TextFieldComponent";
import DatePickerComponent from "../../../../Component/DatePickerComponent";
import ButtonComponent from "../../../../Component/ButtonComponent";
import Api from "../../../../Api";
import { SnackbarOpen } from "../../../../Action/SnackbarAction";
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from "../../../../Action/LinearProgressMainLayoutAction";

export default function InstitutionGroupDiscount(props) {
    const classes = useStyles();
    const [showConfirmDiscount, setShowConfirmDiscount] = React.useState(false);
    const [discount, setDiscount] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [isEdition, setIsEdition] = React.useState(false);
    const [id, setId] = React.useState();

    const [form, setForm] = React.useState({
        comment: {
            name: 'comment',
            label: 'Réduction',
            textHelper: 'Saisissez une réduction',
            type: 'text',
            defaultValue: '',
            options: { validation: ['required'] }
        },
        startAt: {
            name: 'startAt',
            label: 'Date de début',
            textHelper: 'Saisissez la date de début.',
            type: 'date',
            defaultValue: '',
            options: { validation: ['required', 'date'] }
        },
        endAt: {
            name: 'endAt',
            label: 'Date de fin',
            textHelper: 'Saisissez la date de fin.',
            type: 'date',
            defaultValue: '',
            options: { validation: ['date'] }
        },
        value: {
            name: 'value',
            label: 'Valeur du rabais (€)',
            textHelper: 'Saisissez le montant du rabais.',
            type: 'float',
            defaultValue: '',
            options: {}
        },
        percentage: {
            name: 'percentage',
            label: 'Pourcentage du rabais (%)',
            textHelper: 'Saisissez le pourcentage du rabais.',
            type: 'float',
            defaultValue: '',
            options: {}
        }
    });
    const handler = formHandlerInit(form, setForm);
    React.useEffect(handler.start, []);

    const save = () => {
        if (handler.checkError() || ((handler.getData().startAt >= handler.getData().endAt && handler.getData().endAt))) {
            if (handler.getData().startAt >= handler.getData().endAt) handler.setError('endAt', 'La date de fin doit être supérieure à la date de début')
            console.log('Error')
        } else {
            handler.setFormLoading(true);
            setLoading(true);
            dispatch(LinearProgressMainLayoutActivate());
            if (isEdition) {
                if (props.match.params.id) {
                    Api.post({
                        route: 'institution_health_crm_group_discount_edit',
                        data: handler.getData(),
                        params: { institutionGroup: props.match.params.id, id: id }
                    }, (response) => {
                        handler.setFormLoading(false);
                        setLoading(false);
                        dispatch(LinearProgressMainLayoutDeactivate());
                        if (response.status === 200) {
                            getData();
                            handler.reset();
                            props.setDialogAddDiscount(false);
                            setId(null);
                            dispatch(
                                SnackbarOpen({
                                    text: 'Réduction modifiée.',
                                    variant: 'success',
                                })
                            );
                        } else if (response.status === 400) {
                            handler.setErrorApi(response.error);
                        } else {
                            dispatch(
                                SnackbarOpen({
                                    text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                    variant: 'error',
                                })
                            );
                        }
                    });
                }
            } else {
                let datas = handler.getData();
                Api.post({
                    route: 'institution_health_crm_group_discount_add',
                    data: datas,
                    params: { institutionGroup: props.match.params.id }
                }, (response) => {
                    handler.setFormLoading(false);
                    setLoading(false);
                    dispatch(LinearProgressMainLayoutDeactivate());

                    if (response.status === 201) {
                        getData();
                        handler.reset();
                        props.setDialogAddDiscount(false);
                        setId(null)
                        dispatch(
                            SnackbarOpen({
                                text: 'Réduction ajoutée.',
                                variant: 'success',
                            })
                        );
                    } else if (response.status === 400) {
                        handler.setErrorApi(response.error);
                    } else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
            }
        }
    }
    const getData = () => {
        if (props.match.params.id) {
            Api.get({
                route: 'institution_health_crm_group_discount_list',
                params: { institutionGroup: props.match.params.id }
            },
                (response) => {
                    props.setLoading(false);
                    if (response.status === 200) {
                        let data = response.data;
                        setDiscount(data)
                    } else if (response.status === 404) {
                        props.history.push(getRoutePathname('institution_health_crm_institution_group_list'));
                        dispatch(
                            SnackbarOpen({
                                text: response.error.message,
                                variant: 'warning',
                            })
                        );
                    }
                    else {
                        dispatch(
                            SnackbarOpen({
                                text: 'Une erreur inattendue s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
        }
    };

    React.useEffect(() => {
        if (isEdition && id) {
            handler.setFormLoading(true);
            setLoading(true);
            Api.get({
                route: 'institution_health_crm_group_discount_get',
                params: { institutionGroup: props.match.params.id, id: id }
            },
                (response) => {
                    if (response.status === 200) {
                        let data = response.data;
                        handler.setDataApi(data);
                        handler.setFormLoading(false);
                        setLoading(false);
                    } else if (response.status === 404) {
                        dispatch(
                            SnackbarOpen({
                                text: response.error.message,
                                variant: 'warning',
                            })
                        );
                    }
                    else {
                        dispatch(
                            SnackbarOpen({
                                text: 'Une erreur inattendue s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
        }
    }, [id]);

    React.useEffect(() => getData(), [props.match.params.id]);

    return (
        <>
            <Box style={{ height: '100%', overflow: 'auto', background: '#EFF2F4', borderRadius: '10px 10px 0 0', boxShadow: 'inset 0px 0px 4px #0000001a', position: 'absolute', width: '100%', }}>
                {discount.length != 0 ? discount.map((data, index) => (
                    <CardDiscountComponent
                        data={data}
                        setIsEdition={setIsEdition}
                        setIsAction={setShowConfirmDiscount}
                        setId={setId}
                        key={index}
                        {...props}
                    />
                )) : <p className={classes.alertStyle}>Aucune réduction</p>}
            </Box>
            <Dialog open={props.dialogAddDiscount} maxWidth={'lg'} onClose={() => { props.setDialogAddDiscount(false); handler.reset(); setId(null); setIsEdition(false) }}>
                <Box>
                    <h1 style={{ color: '#5E6E82', fontSize: 23, margin: 0, padding: 20 }} >{id ? 'Modifier la réduction' : 'Ajouter une réduction'}</h1>
                    <hr style={{ margin: 0, opacity: 0.2 }} />
                </Box>
                <DialogContent>
                    <Grid container spacing={1} style={{ maxWidth: 600, width: '100%', margin: 'auto' }}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TextFieldComponent name={'comment'} handler={handler} multiline={true} disabled={loading} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                            <TextFieldComponent name={'value'} handler={handler} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                            <TextFieldComponent name={'percentage'} handler={handler} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                            <DatePickerComponent name={'startAt'} handler={handler} disabled={loading} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                            <DatePickerComponent name={'endAt'} handler={handler} disabled={loading} />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <ButtonComponent color={'#5E6E82'} label={'Annuler'} onClick={() => { props.setDialogAddDiscount(false); handler.reset(); setId(null); setIsEdition(false) }} />
                    <ButtonComponent label={id ? 'Enregistrer' : 'Ajouter à la liste'} onClick={save} disabled={loading} />
                </DialogActions>
            </Dialog>
            <SweetAlert
                show={showConfirmDiscount}
                title={'Supprimer'}
                text={'\nÊtes-vous sur de vouloir supprimer cet réduction ?'}
                type={'warning'}
                cancelButtonText={'Non'}
                confirmButtonText={'Oui'}
                showCancelButton={true}
                onConfirm={() => {
                    dispatch(LinearProgressMainLayoutActivate());
                    setShowConfirmDiscount(false)
                    Api.delete({
                        route: 'institution_health_crm_group_discount_delete',
                        params: { institutionGroup: props.match.params.id, id: id }
                    },
                        (response) => {
                            dispatch(LinearProgressMainLayoutDeactivate());
                            if (response.status === 200) {
                                getData()
                                dispatch(
                                    SnackbarOpen({
                                        text: 'Réduction supprimé.',
                                        variant: 'success',
                                    })
                                );
                            } else {
                                dispatch(
                                    SnackbarOpen({
                                        text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                        variant: 'error',
                                    })
                                );
                            }
                        });
                }}
                onCancel={() => setShowConfirmDiscount(false)}
            />
        </>
    )
}

const useStyles = makeStyles({
    alertStyle: {
        display: 'flex',
        width: 'fit-content',
        height: '100%',
        alignItems: 'center',
        margin: 'auto',
        fontSize: 20,
        fontWeight: 'bold',
        opacity: 0.4
    },
})