import React from 'react';
import { Box, IconButton, Tooltip, makeStyles } from '@material-ui/core';
import ContentViewComponent from '../../../../Component/ContentViewComponent';
import TableComponent from '../../../../Component/TableComponent';
import { useDispatch, useSelector } from 'react-redux';
import { getRoutePathname } from '../../../../Config/Route';
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from '../../../../Action/LinearProgressMainLayoutAction';
import ButtonComponent from '../../../../Component/ButtonComponent';
import Api from '../../../../Api';
import CancelIcon from '@material-ui/icons/Cancel';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import SweetAlert from 'sweetalert-react/lib/SweetAlert';
import { TableReload } from '../../../../Action/TableAction';
import { Delete } from '@material-ui/icons';
import { SnackbarOpen } from '../../../../Action/SnackbarAction';
import FormatIndentIncreaseIcon from '@material-ui/icons/FormatIndentIncrease';
import ViewInvoiceDialogComponent from './Component/ViewInvoiceDialogComponent';

export default function InvoicePersonalList(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [isReadyContent, setReadyContent] = React.useState(false);
    const selectOrganismReducer = useSelector(state => state.SelectOrganismReducer);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [institutionName, setInstitutionName] = React.useState('-');
    const [loading, setLoading] = React.useState(false);
    const [showConfirm, setShowConfirm] = React.useState(false);
    const [typeShow, setTypeShow] = React.useState({});
    const [actionRow, setActionRow] = React.useState({});
    const [dialog, setDialog] = React.useState(false);

    const columns = [
        {
            name: "id",
            label: "Référence",
            options: { filter: true, sort: true }
        },
        {
            name: "label",
            label: "Référence de la facture",
            options: { filter: true, sort: true }
        },
        {
            name: "prospectName",
            label: "Nom/Prénom",
            options: { filter: true, sort: true }
        },
        {
            name: "formation",
            label: "Formation",
            options: { filter: true, sort: true }
        },
        {
            name: "destination",
            label: "À destination",
            options: { filter: true, sort: true }
        },
        {
            name: "state",
            label: "État",
            options: { filter: true, sort: true }
        },
        {
            name: "amountTtc",
            label: "Net à payer",
            options: { filter: true, sort: true }
        },
        {
            name: "action",
            label: "Action",
            options: { filter: false, sort: false }
        }
    ];

    /*const save = (data, name, checked, handler, setIsLoading) => {
        dispatch(LinearProgressMainLayoutActivate());
        setIsLoading(true);
        let apiData = null;
        if (name === 'isPaid') {
            apiData = {
                route: 'institution_health_financial_management_billing_institution_invoice_paid',
                params: { institution: props.match.params.institution, invoiceId: data.invoices[0].id },
                data: { [name]: checked, isPersonal: true }
            }
        } else if (name === 'isConvention') {
            apiData = {
                route: 'institution_health_financial_management_billing_institution_contract_convention',
                params: { institution: props.match.params.institution, contractId: data.id },
                data: { [name]: checked, isPersonal: true }
            }
        }

        Api.post({
            route: apiData.route,
            data: apiData.data,
            params: apiData.params
        }, (response) => {
            if (name != 'isEstimateAccepted') {
                setIsLoading(false);
            }
            dispatch(LinearProgressMainLayoutDeactivate());
            if (response.status === 201) {
                dispatch(TableReload('institution_health_financial_management_billing_institution_invoice_personal_list'));
                dispatch(
                    SnackbarOpen({
                        text: response.data.message,
                        variant: 'success',
                    })
                );
            } else if (response.status === 400) {
                setIsLoading(false);
                dispatch(
                    SnackbarOpen({
                        text: response.error.message,
                        variant: 'warning',
                    })
                );
            } else {
                setIsLoading(false);
                dispatch(
                    SnackbarOpen({
                        text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                        variant: 'error',
                    })
                );
            }
        });
    }*/

    /*const download = (row) => {
        const url = Api.route({
            route: 'institution_health_financial_management_billing_institution_invoice_personal_download',
            params: { institution: props.match.params.institution, id: row.id },
        });
        window.open(url, '_blank');
    };*/

    const getAction = (row) => {
        const isInvoice = (row.invoices && row.invoices.length != 0) ? true : false;
        const isCanceledInvoices = (row.canceledInvoices && row.canceledInvoices.length != 0) ? true : false;
        return (
            <Box style={{ width: 'max-content' }}>
                <Tooltip title={(row.cancelAt) ? '' : 'Modifier'} placement="left">
                    <span>
                        <IconButton disabled={row.cancelAt} onClick={() => { props.history.push(getRoutePathname('institution_health_financial_management_billing_institution_invoice_personal', { institution: props.match.params.institution, id: row.id })) }}>
                            <LibraryBooksIcon style={{ color: (row.cancelAt) ? '' : '#5E6E82' }} />
                        </IconButton>
                    </span>
                </Tooltip>
                <Tooltip title={!(isInvoice || (isInvoice && isCanceledInvoices) || (!isInvoice && isCanceledInvoices)) ? '' : 'Voir les factures'} placement="left">
                    <span>
                        <IconButton disabled={!(isInvoice || (isInvoice && isCanceledInvoices) || (!isInvoice && isCanceledInvoices))} onClick={() => {
                            setActionRow(row);
                            setDialog(true);
                        }}>
                            <FormatIndentIncreaseIcon style={{ color: !(isInvoice || (isInvoice && isCanceledInvoices) || (!isInvoice && isCanceledInvoices)) ? '' : '#5E6E82' }} />
                        </IconButton>
                    </span>
                </Tooltip>
                {(row.canceledInvoices.length != 0) ?
                    <Tooltip title={(isInvoice || row.cancelAt) ? '' : 'Annuler'} placement="left">
                        <span>
                            <IconButton disabled={(isInvoice || row.cancelAt)} onClick={() => {
                                setTypeShow({ title: 'Annuler', text: '\nÊtes-vous sûr de vouloir annuler ce devis ?', type: 'cancel' });
                                setActionRow(row);
                                setShowConfirm(true);
                            }}>
                                <CancelIcon style={{ color: (isInvoice || row.cancelAt) ? '' : '#5E6E82' }} />
                            </IconButton>
                        </span>
                    </Tooltip> :
                    <Tooltip title={(isInvoice || row.cancelAt) ? '' : 'Supprimer'} placement="left">
                        <span>
                            <IconButton disabled={(isInvoice || row.cancelAt)} onClick={() => {
                                setTypeShow({ title: 'Supprimer', text: '\nÊtes-vous sûr de vouloir supprimer ce devis ?', type: 'delete' });
                                setActionRow(row);
                                setShowConfirm(true);
                            }}>
                                <Delete style={{ color: (isInvoice || row.cancelAt) ? '' : '#5E6E82' }} />
                            </IconButton>
                        </span>
                    </Tooltip>}
            </Box>
        )
    }

    const GetActionMore = () => {
        return (
            <Box style={{ display: 'flex', gap: 12 }}>
                <ButtonComponent
                    onClick={() => props.history.push(getRoutePathname('institution_health_financial_management_billing_institution_invoice_personal', { institution: props.match.params.institution, id: null }))}
                    label={<>Créer un devis</>}
                />
                <ButtonComponent
                    color={'#5E6E82'}
                    onClick={() => props.history.push(getRoutePathname('institution_health_financial_management_billing_institution_invoice_list', { institution: props.match.params.institution }))}
                    label={<>Liste des contrats</>}
                />
            </Box>
        )
    }

    React.useEffect(() => {
        dispatch(LinearProgressMainLayoutDeactivate());
        setBreadcrumbs({
            title: institutionName,
            context: 'Portail',
            path: getRoutePathname('institution_health_home'),
            description: 'Liste des devis',
            html: <GetActionMore />,
            links: [
                { label: 'Gestion financière' },
                { path: getRoutePathname('institution_health_financial_management_billing_institution_list'), label: 'Facturation établissement' }
            ],
        });

        setReadyContent(true);
    }, [institutionName]);

    React.useEffect(() => {
        if (!props.match.params.institution) {
            props.history.push(getRoutePathname('institution_health_financial_management_billing_institution_list'));
            dispatch(
                SnackbarOpen({
                    text: 'Aucun établissement n\'a été trouvé.',
                    variant: 'warning',
                })
            );
        }
    }, [props.match.params.institution]);

    return (
        <Box className={classes.styleContentView}>
            <ContentViewComponent loading={!isReadyContent} breadcrumbs={breadcrumbs} {...props}>
                <Box className={classes.TableComponent} style={{ width: '95%' }}>
                    <TableComponent
                        id='institution_health_financial_management_billing_institution_invoice_personal_list'
                        title={
                            <h1 style={{ margin: 0, fontSize: 15, display: 'flex', alignItems: 'center', gap: 5 }}>
                                Vos devis
                            </h1>
                        }
                        columns={columns}
                        search={true}
                        promiseData={(resolve) => {
                            Api.get({
                                route: 'institution_health_financial_management_billing_institution_invoice_list',
                                data: { organismId: selectOrganismReducer.organism.value, isPersonal: true },
                                params: { institution: props.match.params.institution }
                            },
                                (response) => {
                                    if (response.status === 200) {
                                        const data = response.data.data.map(item => {
                                            const descriptions = {
                                                1: { text: 'Devis | Annuler', color: '#DD4E4E' },
                                                2: { text: 'Devis | Facturer', color: '#28a745' },
                                                3: { text: 'Devis | Facturer non payé', color: '#E6A731' },
                                                4: { text: 'Devis | Modfier', color: '#007bff' },
                                                default: { text: 'Devis', color: '#007bff' }
                                            };

                                            const isInvoice = (item.invoices && item.invoices.length != 0) ? true : false;
                                            const conditions = [
                                                { condition: item.cancelAt, type: 1 },
                                                { condition: isInvoice && item.invoices[0].isPaid, type: 2 },
                                                { condition: isInvoice && !item.invoices[0].isPaid, type: 3 },
                                                { condition: item.canceledInvoices.length != 0, type: 4 },
                                            ];

                                            const descriptionType = conditions.find(cond => cond.condition)?.type || 'default';
                                            const { text: stateLabel, color } = descriptions[descriptionType];


                                            let destination = '-';
                                            switch (item.invoices[0]?.destinationType) {
                                                case 1:
                                                    destination = 'l\'établissement';
                                                    break;
                                                case 2:
                                                    destination = item.prospectName ?? '-';
                                                    break;
                                            }

                                            return {
                                                ...item,
                                                action: getAction(item),
                                                label: item.estimationLabel ?? '-',
                                                prospectName: item.prospectName ?? '-',
                                                formation: item.formationName ?? '-',
                                                destination: destination,
                                                amountTtc: <span style={{ color: '#007bff', fontWeight: 'bold', width: 'max-content', display: 'block' }}>{item.amountTtc ?? 0} €</span>,
                                                state: <span style={{ color: color, width: 'max-content', display: 'block', fontWeight: 'bold', }}>{stateLabel}</span>
                                            };
                                        });
                                        setActionRow((prev) => {
                                            const newPrev = response.data.data.find(item => item.id === prev.id);
                                            return newPrev ?? prev;
                                        });
                                        setInstitutionName(response.data.institutionName);
                                        resolve(data);
                                    } else {
                                        resolve([]);
                                    }
                                });
                        }}
                    />
                </Box>
                <ViewInvoiceDialogComponent row={actionRow} isPersonal={true} dialog={dialog} setDialog={setDialog} {...props} />
            </ContentViewComponent>
            <SweetAlert
                show={showConfirm}
                title={typeShow.title}
                text={((actionRow.invoices && actionRow.invoices.length != 0) ? (actionRow.invoices[0].label ?? '-') : (actionRow.estimationLabel ?? '-')) + typeShow.text}
                type={'warning'}
                cancelButtonText={'Non'}
                confirmButtonText={'Oui'}
                showCancelButton={true}
                onConfirm={() => {
                    setShowConfirm(false);
                    dispatch(LinearProgressMainLayoutActivate());
                    if (typeShow.type === 'delete') {
                        Api.delete({
                            route: 'institution_health_financial_management_billing_institution_contract_delete',
                            params: { institution: props.match.params.institution, contractId: actionRow.id }
                        },
                            (response) => {
                                dispatch(LinearProgressMainLayoutDeactivate());
                                if (response.status === 201) {
                                    dispatch(TableReload('institution_health_financial_management_billing_institution_invoice_personal_list'))
                                    dispatch(
                                        SnackbarOpen({
                                            text: 'Devis supprimer.',
                                            variant: 'success',
                                        })
                                    );
                                } else if (response.status === 400) {
                                    dispatch(
                                        SnackbarOpen({
                                            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                            variant: 'error',
                                        })
                                    );
                                } else if (response.status === 409) {
                                    dispatch(
                                        SnackbarOpen({
                                            text: response.error.message,
                                            variant: 'warning',
                                        })
                                    );
                                } else {
                                    dispatch(
                                        SnackbarOpen({
                                            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                            variant: 'error',
                                        })
                                    );
                                }
                            });
                    } else if (typeShow.type === 'cancel') {
                        Api.delete({
                            route: 'institution_health_financial_management_billing_institution_contract_cancel',
                            params: { institution: props.match.params.institution, contractId: actionRow.id }
                        },
                            (response) => {
                                dispatch(LinearProgressMainLayoutDeactivate());
                                if (response.status === 201) {
                                    dispatch(TableReload('institution_health_financial_management_billing_institution_invoice_personal_list'))
                                    dispatch(
                                        SnackbarOpen({
                                            text: 'Devis annuler.',
                                            variant: 'success',
                                        })
                                    );
                                } else if (response.status === 400) {
                                    dispatch(
                                        SnackbarOpen({
                                            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                            variant: 'error',
                                        })
                                    );
                                } else if (response.status === 409) {
                                    dispatch(
                                        SnackbarOpen({
                                            text: response.error.message,
                                            variant: 'warning',
                                        })
                                    );
                                } else {
                                    dispatch(
                                        SnackbarOpen({
                                            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                            variant: 'error',
                                        })
                                    );
                                }
                            });
                    }
                }}
                onCancel={() => setShowConfirm(false)}
            />
        </Box>
    );

}

const useStyles = makeStyles(style => ({
    TableComponent: {
        height: '90%',
        width: '95%',
        margin: 'auto',
        '& >div': {
            height: '100%',
            '& .MuiPaper-root': {
                height: '100%',
                display: 'grid',
                gridTemplateRows: ' min-content min-content auto min-content min-content',
                overflow: 'hidden',
                '& div .MuiTable-root': {
                    position: 'absolute'
                }
            }
        },
        '& .MuiTableCell-head:last-child': {
            width: 145,
            textAlign: 'center'
        }
    },
    styleContentView: {
        height: '100%',
        display: 'flex',
        flexDirection: 'row-reverse',
        '& .ContentView-root': {
            height: '100%',
            display: 'grid',
            gridTemplateRows: 'min-content auto',
            width: '100%',
            '& .Breadcrumbs-root:nth-of-type(1)': {
                margin: 0,
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
                height: 'auto',
                '& div:nth-of-type(2)': {
                    margin: '0 0 0 auto',
                }
            }
        }
    },
    iconButton: {
        height: 'auto',
        display: 'flex',
        background: 'rgb(255, 255, 255)',
        color: 'rgb(94, 110, 130)',
        alignItems: 'center',
        width: '100%',
        margin: '0',
        gap: 0,
        padding: 4,
        borderRadius: 5,
        "& span": {
            width: 'max-content',
            fontSize: 12
        }
    },
    dialogStyle: {
        '& .MuiPaper-root': {
            width: 478,
            padding: 17
        },
        '& .MuiDialogContent-root': {
            overflowY: 'initial'
        },
        '& #depositAmount': {
            textAlign: 'center'
        }
    }
}))