import React from 'react';
import Api from "../../../../Api";
import { formHandlerInit } from "../../../../Tool/FormHandlerCommon";
import { DialogActions, DialogContent, DialogTitle, Grid } from "@material-ui/core";
import ButtonComponent from "../../../../Component/ButtonComponent";
import SelectComponent from '../../../../Component/SelectComponent';
import SelectAutocompleteComponent from "../../../../Component/SelectAutocompleteComponent";
import moment from 'moment';
import DatePickerComponent from '../../../../Component/DatePickerComponent';
import CheckboxComponent from '../../../../Component/CheckboxComponent';
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from '../../../../Action/LinearProgressMainLayoutAction';
import { dispatch } from '../../../../App';
import { useSelector } from 'react-redux';
import { SnackbarOpen } from '../../../../Action/SnackbarAction';
import { TableReload } from '../../../../Action/TableAction';
import {hasRight} from "../../../../Tool/UserTool";

function RegistrationEdit(props = {
    onClose: () => {
    }
}) {
    const authenticationReducer = useSelector(state => state.AuthenticationReducer);
    const isAdmin = hasRight(authenticationReducer, 'ROLE_CRM_SUPER_ADMIN') || hasRight(authenticationReducer, 'ROLE_INSTITUTION_CRM_ADMIN') || hasRight(authenticationReducer, 'ROLE_SUPER_ADMIN');
    const selectOrganismReducer = useSelector(state => state.SelectOrganismReducer);
    const [fullSessions, setFullSessions] = React.useState(false);
    const [loadingFormFormation, setLoadingFormFormation] = React.useState(true);
    const [loading, setLoading] = React.useState(false);
    const [isCheck, setIsCheck] = React.useState(false);
    const [optionFormation, setOptionFormation] = React.useState([]);
    const [optionsSessionFormation, setOptionsSessionFormation] = React.useState([]);
    const [optionFinanceType, setOptionFinanceType] = React.useState([]);
    const [optionCommercial, setOptionCommercial] = React.useState([]);
    const [optionYears, setOptionYears] = React.useState([
        { value: (parseInt(moment().format('YYYY')) - 1), label: (parseInt(moment().format('YYYY')) - 1) },
        { value: parseInt(moment().format('YYYY')), label: parseInt(moment().format('YYYY')) },
        { value: (parseInt(moment().format('YYYY')) + 1), label: (parseInt(moment().format('YYYY')) + 1) }
    ]);
    const [form, setForm] = React.useState({
        formation: {
            name: 'formation',
            label: 'Formation',
            textHelper: 'Choisissez une formation.',
            type: 'integer',
            defaultValue: '',
            options: { validation: ['required'] }
        },
        sessionFormation: {
            name: 'sessionFormation',
            label: 'Session',
            textHelper: 'Choisissez une session.',
            type: 'integer',
            defaultValue: '',
            options: {}
        },
        year: {
            name: 'year',
            label: 'Année',
            textHelper: 'Choisissez une année.',
            type: 'integer',
            defaultValue: parseInt(moment().format('YYYY')),
            options: { validation: ['required'] }
        },
        financeType: {
            name: 'financeType',
            label: 'Type de financement',
            textHelper: 'Saisissez un type de financement.',
            type: 'integer',
            defaultValue: '',
            options: { validation: ['required'] },
        },
        commercial: {
            name: 'commercial',
            label: 'Commercial',
            textHelper: 'Choisissez le commercial.',
            type: 'integer',
            defaultValue: '',
            options: {},
        },
        isAvailable: {
            name: 'isAvailable',
            label: 'Disponibilité',
            textHelper: 'Validez la disponibilité de la formation.',
            type: 'boolean',
            defaultValue: '',
            options: {}
        },
        availableAt: {
            name: 'availableAt',
            label: 'Date de disponibilité',
            textHelper: 'Saisissez une date de disponibilité.',
            type: 'date',
            defaultValue: '',
            options: {}
        }
    });

    const handler = formHandlerInit(form, setForm);
    React.useEffect(handler.start, []);

    const changeForm = (index, value, formationSessionId = '') => {
        setLoadingFormFormation(true);
        let newForm = { ...handler.form };
        if (index === 'year') {
            newForm['year'].value = value;
            newForm['year'].error = value === '' ? handler.getErrorByField(newForm['formation']) : '';
        }
        if (index === 'formation'){
            newForm['formation'].value = value;
            newForm['formation'].error = value !== '' ? handler.getErrorByField(newForm['formation']) : '';
        }
        handler.set(newForm);
        setLoadingFormFormation(false);
        loadSession(fullSessions, formationSessionId);
    };
    const loadSession = (full = false, formationSessionId = '') => {
        setFullSessions(full);
        const formationIdTMP = form['formation'].value;
        const year = form['year'].value;
        let newForm = { ...handler.form };
        newForm['sessionFormation'].value = formationSessionId;
        newForm['sessionFormation'].error = formationSessionId !== '' ? handler.getErrorByField(newForm['sessionFormation']) : '';
        handler.set(newForm);
        if (formationIdTMP) {
            handler.setFormLoading(true);
            Api.get({
                    route: 'institution_health_crm_institution_registration_formation_session_formation_list',
                    params: { institution: props.match.params.institution, formation: formationIdTMP },
                    data: { full: full ? 1 : 0, organism: selectOrganismReducer.organism.value, year: year }
                },
                (response) => {
                    handler.setFormLoading(false);
                    if (response && response.data) {
                        setOptionsSessionFormation(response.data);
                    }
                });
        }
        else {
            setOptionsSessionFormation([]);
        }
    };

    const save = () => {
        if (handler.checkError()) {
            console.error('Error');
        }
        else {
            let datas = {};
            handler.setFormLoading(true);
            dispatch(LinearProgressMainLayoutActivate());
            setLoading(true);
            let data = handler.getData();

            datas = {
                year: data.year,
                sessionFormation: data.sessionFormation,
                organism: selectOrganismReducer.organism.value,
                formation: data.formation,
                financeType: data.financeType,
                isAvailable: data.isAvailable ? true : false,
                availableAt: data.availableAt ? data.availableAt : null,
                commercial: data.commercial ? data.commercial : null
            };

            Api.post({
                route: 'institution_health_crm_institution_registration_edit',
                data: datas,
                params: {
                    institution: props.match.params.institution,
                    registration: props.row.id,
                }
            },
                (response) => {
                    handler.setFormLoading(false)
                    dispatch(LinearProgressMainLayoutDeactivate());
                    setLoading(false);
                    if (response.status === 200) {
                        props.onClose();
                        dispatch(
                            SnackbarOpen({
                                text: 'Inscription modifiée.',
                                variant: 'success',
                            })
                        );
                    }
                    else if (response.status === 400) {
                        handler.setErrorApi(response.error);
                        if (response.error.message) {
                            dispatch(SnackbarOpen({ text: response.error.message, variant: 'error' }));
                        }
                    }
                    else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                    dispatch(TableReload('institution_health_crm_institution_registration_list'));
                });
        }
    };

    React.useEffect(() => {
        handler.setFormLoading(true);

        Api.get({
                route: 'institution_health_crm_commercial_list',
                data: { organismId: selectOrganismReducer.organism.value }
            },
            (response) => {
                if (response && response.data) {
                    setOptionCommercial(response.data);
                }
            });

        Api.get({
            route: 'select_finance_types',
            data: { 'isInstitution': true }
        },
            (response) => {
                if (response && response.data) {
                    let data = [];
                    for (let index in response.data) {
                        data.push(response.data[index]);
                    }
                    setOptionFinanceType(data);
                }
            });

        Api.get({
            route: 'institution_health_crm_institution_registration_formation_list',
            params: { institution: props.match.params.institution },
            data: { organism: selectOrganismReducer.organism.value }
        },
            (response) => {
                if (response && response.data) {
                    setOptionFormation(response.data);
                }
            });

        handler.setFormLoading(false);

        if (props.row) {
            handler.setDataApi(props.row);
            handler.setValue('commercial', props.row.commercialId)
            if (props.row.isAvailable && props.row.isAvailable.props.defaultChecked) {
                setIsCheck(true);
                handler.setValue('isAvailable', props.row.isAvailable.props.defaultChecked);
                handler.setValue('availableAt', moment(props.row.availableAt, "DD MMM. YYYY HH:mm"));
            }
            changeForm('formation', props.row.formation);
            handler.setValue('sessionFormation', props.row.sessionFormation);
            handler.setValue('commercial', props.row.commercialId);
        }
    }, []);

    return (
        <>
            <DialogTitle style={{ fontSize: 15 }}>{'Éditer une inscription'}</DialogTitle>
            <DialogContent>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={2} md={2} lg={2}>
                        <SelectComponent name={'year'} handler={handler} options={optionYears} onChange={(val) => {changeForm('year', val)}}/>
                    </Grid>
                    <Grid item xs={12} sm={5} md={5} lg={5}>
                        <SelectAutocompleteComponent name={'formation'} handler={handler} options={optionFormation} onChange={(val) => {changeForm('formation', val)}}/>
                    </Grid>
                    <Grid item xs={12} sm={5} md={5} lg={5}>
                        <SelectComponent name={'sessionFormation'} handler={handler} options={optionsSessionFormation} nullable={true} disabled={loadingFormFormation} />
                    </Grid>

                    <Grid item xs={12} sm={6} md={6} lg={6}>
                        <SelectComponent name={'commercial'} handler={handler} options={optionCommercial} nullable={true} disabled={!isAdmin}/>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                        <SelectComponent name={'financeType'} handler={handler} options={optionFinanceType} />
                    </Grid>

                    <Grid item xs={4} sm={3} md={6} lg={6}>
                        <CheckboxComponent
                            name={'isAvailable'}
                            handler={handler}
                            disabled={!(handler.form['sessionFormation'].value)}
                            onChange={(val) => {
                                if (val === true) {
                                    setIsCheck(true);
                                    handler.setValue('availableAt', moment());
                                }
                                else if (val === false) {
                                    setIsCheck(false);
                                    handler.setValue('availableAt', '');
                                }
                            }}
                        />
                    </Grid>
                    {isCheck &&
                        <Grid item xs={8} sm={6} md={6} lg={6}>
                            <DatePickerComponent name={'availableAt'} handler={handler} />
                        </Grid>
                    }
                </Grid>

            </DialogContent>
            <DialogActions>
                <ButtonComponent color={'#5E6E82'} label={'Annuler'} onClick={props.onClose} disabled={loading} />
                <ButtonComponent label={'Enregistrer'} onClick={save} disabled={loading} />
            </DialogActions>
        </>
    );
}

export default RegistrationEdit;
