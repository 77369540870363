import React from "react";
import { Box, Checkbox, Fade, IconButton, makeStyles, Tooltip } from "@material-ui/core";
import UseAnimations from "react-useanimations";
import activity from "react-useanimations/lib/activity";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { SnackbarOpen } from "../../../../../Action/SnackbarAction";
import { dispatch } from "../../../../../App";
import { getRoutePathname } from "../../../../../Config/Route";
import Api from "../../../../../Api";
import SelectComponent from "../../../../../Component/SelectComponent";
import { formHandlerInit } from "../../../../../Tool/FormHandlerCommon";
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from "../../../../../Action/LinearProgressMainLayoutAction";
import CancelIcon from '@material-ui/icons/Cancel';
import SweetAlert from 'sweetalert-react/lib/SweetAlert';


const ViewRegistrationInvoicesComponent = (props) => {
    const classes = useStyles();
    const [dataList, setDataList] = React.useState([]);
    const [dataHistoriqueList, setDataHistoriqueList] = React.useState([]);
    const [dataInvoices, setDataInvoices] = React.useState([]);
    const [dataHistoriqueInvoices, setDataHistoriqueInvoices] = React.useState([]);
    const [dataCredits, setDataCredits] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [selectType, setSelectType] = React.useState(1);
    const [options, setOptions] = React.useState([
        { value: 1, label: 'Tous' },
        { value: 2, label: 'Facture' },
        { value: 3, label: 'Avoir' }
    ]);
    const [form, setForm] = React.useState({
        type: {
            name: 'type',
            label: 'Filtre',
            textHelper: '',
            type: 'integer',
            defaultValue: 1,
            options: {}
        }
    });
    const handler = formHandlerInit(form, setForm);
    React.useEffect(handler.start, []);
    const getInvoicesByRegistration = (registrationId) => {
        setLoading(true);
        if (props.match.params.institution) {
            Api.get({
                route: 'institution_health_financial_management_billing_institution_registration_invoices_info',
                params: { institution: props.match.params.institution, registrationId: registrationId }
            }, (response) => {
                setLoading(false);
                if (response.status === 200) {
                    let data = response.data;
                    setDataInvoices(data.invoices?.filter(data => (data.contractId == props.match.params.id) && !data.cancelAt));
                    setDataHistoriqueInvoices(data.invoices?.filter(data => data.contractId != props.match.params.id || data.cancelAt));
                    setDataCredits(data.credits);
                } else {
                    props.history.push(getRoutePathname('institution_health_financial_management_billing_institution_invoice_list', { institution: props.match.params.institution }));
                    dispatch(
                        SnackbarOpen({
                            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                            variant: 'warning',
                            duration: 10000
                        })
                    );
                }
            })
        }
    }

    const mergeInvoicesWithCredits = (invoices, credits) => {
        return invoices.map(invoice => {
            const relatedCredits = credits.filter(credit => credit.invoiceId === invoice.id);
            return {
                ...invoice,
                credits: relatedCredits
            };
        });
    };

    React.useEffect(() => {
        props.registration.id && getInvoicesByRegistration(props.registration.id);
    }, [props.registration]);

    React.useEffect(() => {
        setDataList(selectType == 1 ?
            mergeInvoicesWithCredits(dataInvoices, dataCredits)
            : selectType == 2 ? dataInvoices : []);

        setDataHistoriqueList(selectType == 1 ?
            mergeInvoicesWithCredits(dataHistoriqueInvoices, dataCredits)
            : selectType == 2 ? dataHistoriqueInvoices : dataCredits);

    }, [dataHistoriqueInvoices, dataInvoices, dataCredits, selectType]);

    return (
        <>
            <Box style={{ display: 'grid', gridTemplateRows: 'max-content auto', color: '#5E6E82', height: '100%' }}>
                <Box className={classes.filter}>
                    <Box>
                        <Box style={{ display: 'grid', gridTemplateColumns: 'max-content auto', alignItems: 'center', gap: 10 }}>
                            <h1 style={{ fontSize: 20, margin: 0 }}>Factures / Avoir</h1>
                            <hr style={{ margin: 0, opacity: 0.5 }} />
                        </Box>
                        <p style={{ fontSize: 13, margin: 0 }}>liées à {props.registration.prospectName ?? '???'}</p>
                    </Box>
                    <SelectComponent name={'type'} handler={handler} options={options} onChange={(value) => setSelectType(value)} />
                </Box>
                <Box style={{ background: '#EFF3F4', borderRadius: '10px', border: '1px solid rgba(163, 173, 183, 0.36)', position: 'relative', overflow: 'hidden auto' }}>
                    {loading ? (
                        <Fade in={true} timeout={1000}>
                            <Box className={classes.loader} style={{ width: '100%', height: '100%', display: 'flex' }}>
                                <Box className={classes.loaderContent}>
                                    <Box className={classes.loaderActivity}>
                                        <UseAnimations animation={activity} size={70} />
                                    </Box>
                                </Box>
                            </Box>
                        </Fade>
                    ) : (dataHistoriqueList.length !== 0 || dataList.length !== 0) ? (
                        <>
                            <div style={{ padding: '20px 0', display: 'flex', flexWrap: 'wrap', gap: 20, width: '90%', margin: 'auto', position: 'absolute', top: 0, left: '50%', transform: 'translate(-50%,0%)' }}>
                                {dataList.map((data, index) => (
                                    <Fade key={index} in={true} {...{ timeout: 1000 }}>
                                        <Box style={{ width: '100%' }}>
                                            <Card data={data} personal={props?.isPersonal} disabled={dataList.some(prevItem => prevItem.invoiceType === 9)} {...props} />
                                            {data.credits && data.credits.map((credit, index) => (
                                                <Box key={index} className={classes.creditStyle}>
                                                    <Card data={credit} personal={false} {...props} />
                                                </Box>
                                            ))}
                                        </Box>
                                    </Fade>
                                ))}
                                {(dataHistoriqueList.length !== 0 && selectType != 3) &&
                                    <p style={{ margin: 0, fontSize: 20, fontWeight: 'bold', textAlign: 'center', width: '100%', display: 'grid', gridTemplateColumns: 'auto max-content auto', alignItems: 'center', gap: 10 }}>
                                        <hr style={{ margin: 0, opacity: 0.5 }} /><span>Historique</span><hr style={{ margin: 0, opacity: 0.5 }} />
                                    </p>
                                }
                                {dataHistoriqueList.map((data, index) => (
                                    <Fade key={index} in={true} {...{ timeout: 1000 }}>
                                        <Box style={{ width: '100%' }}>
                                            <Card data={data} personal={props?.isPersonal} {...props} />
                                            {data.credits && data.credits.map((credit, index) => (
                                                <Box key={index} className={classes.creditStyle}>
                                                    <Card data={credit} personal={false} {...props} />
                                                </Box>
                                            ))}
                                        </Box>
                                    </Fade>
                                ))}
                            </div>
                        </>
                    ) : (
                        <p className={classes.error} style={{ transform: 'translate(-50%,-50%)' }}>Aucun résultat.</p>
                    )}
                </Box>
            </Box>
        </>
    )
}
const Card = (props) => {
    const data = props.data;
    const [showConfirm, setShowConfirm] = React.useState(false);
    const [typeShow, setTypeShow] = React.useState({});
    const isDisabled = (data.cancelAt ? true : false) || props?.loading

    const descriptions = {
        1: { text: 'Facture | Annuler', color: '#DD4E4E' },
        2: { text: 'Facture | payé', color: '#28a745' },
        3: { text: 'Avoir', color: '#9c27b0' },
        default: { text: 'Facture | Non payé', color: '#E6A731' },
    };

    const conditions = [
        { condition: data.cancelAt, type: 1 },
        { condition: data.isPaid, type: 2 },
        { condition: !data.isPaid && data.invoiceId, type: 3 }
    ];

    const descriptionType = conditions.find(cond => cond.condition)?.type || 'default';
    const { text: stateLabel, color } = descriptions[descriptionType];

    const save = (id, name, checked, setIsLoading) => {
        dispatch(LinearProgressMainLayoutActivate());
        setIsLoading(true);
        Api.post({
            route: 'institution_health_financial_management_billing_institution_invoice_paid',
            data: { [name]: checked },
            params: { institution: props.match.params.institution, invoiceId: id }
        }, (response) => {
            setIsLoading(false);
            dispatch(LinearProgressMainLayoutDeactivate());
            if (response.status === 201) {
                props.getInstitution(props.match.params.id)
                dispatch(
                    SnackbarOpen({
                        text: response.data.message,
                        variant: 'success',
                        duration: 10000
                    })
                );
            } else if (response.status === 400) {
                dispatch(
                    SnackbarOpen({
                        text: response.error.message,
                        variant: 'warning',
                        duration: 10000
                    })
                );
            } else {
                dispatch(
                    SnackbarOpen({
                        text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                        variant: 'error',
                    })
                );
            }
        });
    }

    const download = () => {
        const url = Api.route({
            route: 'institution_health_financial_management_billing_institution_download',
            data: { type: !data.isPaid && data.invoiceId ? 3 : 2 },
            params: { institution: props.match.params.institution, id: data.id },
        });
        window.open(url, '_blank');
    };

    return (
        <Box style={{ background: '#FFF', width: '100%', borderRadius: 10, border: '1px solid #A3ADB7', display: 'grid', gridTemplateRows: 'max-content auto max-content', overflow: 'hidden' }}>
            <Box style={{ borderBottom: '1px solid #A3ADB7', display: 'grid', gridTemplateColumns: 'auto max-content max-content', background: '#E5E7EA' }}>
                <div style={{ padding: '10px 30px' }}>
                    <p style={{ margin: 0, fontSize: 12 }}>Référence</p>
                    <p style={{ margin: 0, fontWeight: 'bold', fontSize: 14 }}>{`${data.lable}`}</p>
                </div>
                <div style={{ background: '#5E6E82', height: '100%', justifyContent: 'center', display: 'flex' }}>
                    <Tooltip title={'Télécharger'} placement="left">
                        <IconButton onClick={() => { download() }} style={{ borderRadius: 0 }}>
                            <CloudDownloadIcon style={{ color: '#FFF' }} />
                        </IconButton>
                    </Tooltip>
                </div>
                {(props.personal && !data.invoiceId) &&
                    <div style={{ background: (isDisabled || (props?.disabled && data.invoiceType === 8)) ? '#5E6E82B2' : '#DD4E4E', height: '100%', justifyContent: 'center', display: 'flex' }}>
                        <Tooltip title={(isDisabled || (props?.disabled && data.invoiceType === 8)) ? '' : 'Annuler'} placement="left">
                            <IconButton disabled={isDisabled || (props?.disabled && data.invoiceType === 8)} onClick={() => {
                                setTypeShow({ title: 'Annuler', text: '\nÊtes-vous sûr de vouloir annuler cette facture ?' });
                                setShowConfirm(true);
                            }} style={{ borderRadius: 0 }}>
                                <CancelIcon style={{ color: '#FFF', opacity: (isDisabled || (props?.disabled && data.invoiceType === 8)) ? .5 : 1 }} />
                            </IconButton>
                        </Tooltip>
                    </div>
                }
            </Box>
            <Box style={{ padding: '10px 30px' }}>
                <Box style={{ display: 'flex', alignItems: 'initial', flexWrap: 'wrap' }}>
                    <Box>
                        <p style={{ margin: 0, fontSize: 12 }}>Net à payé : <b>{data.amountTtc ?? 0} €</b></p>
                    </Box>
                    <p style={{ margin: '0 0 0 auto', fontSize: 12 }}>État - <span style={{ color: color, width: 'max-content', fontWeight: 'bold' }}>{stateLabel}</span></p>
                </Box>
                {(props.personal && !data.invoiceId) &&
                    <Box style={{ fontSize: 12, display: 'flex', alignItems: 'center', gap: 2 }}>
                        <span>Paiement</span>
                        <GetActionCheck data={data} label={'Payement'} name={'isPaid'} save={save} loading={isDisabled} />
                    </Box>
                }
            </Box>
            <SweetAlert
                show={showConfirm}
                title={typeShow.title}
                text={data.lable + typeShow.text}
                type={'warning'}
                cancelButtonText={'Non'}
                confirmButtonText={'Oui'}
                showCancelButton={true}
                onConfirm={() => {
                    setShowConfirm(false);
                    dispatch(LinearProgressMainLayoutActivate());
                    Api.delete({
                        route: 'institution_health_financial_management_billing_institution_invoice_cancel',
                        params: { institution: props.match.params.institution, invoiceId: data.id }
                    },
                        (response) => {
                            dispatch(LinearProgressMainLayoutDeactivate());
                            if (response.status === 201) {
                                props.getInstitution(props.match.params.id)
                                dispatch(
                                    SnackbarOpen({
                                        text: 'Facture annuler.',
                                        variant: 'success',
                                    })
                                );
                            } else if (response.status === 400) {
                                dispatch(
                                    SnackbarOpen({
                                        text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                        variant: 'error',
                                    })
                                );
                            } else if (response.status === 409) {
                                dispatch(
                                    SnackbarOpen({
                                        text: response.error.message,
                                        variant: 'warning',
                                    })
                                );
                            } else {
                                dispatch(
                                    SnackbarOpen({
                                        text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                        variant: 'error',
                                    })
                                );
                            }
                        });
                }}
                onCancel={() => setShowConfirm(false)}
            />
        </Box>
    )
}

const GetActionCheck = (props = { data: [], label: '', name: '', save: () => { }, loading: false }) => {
    const [isChecked, setIsChecked] = React.useState(props.data[props.name]);
    const [isEvent, setIsEvent] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [isShow, setIsShow] = React.useState(false);
    const [text, setText] = React.useState('');

    const handleCheckboxChange = (event) => {
        if (!props.data[props.name]) {
            setText(`Êtes-vous sûr de vouloir cocher cette case ?`);
            setIsEvent(event.target.checked);
            setIsShow(true);
        } else if (props.data[props.name]) {
            setText(`Êtes-vous sûr de vouloir décocher cette case ?`);
            setIsEvent(event.target.checked);
            setIsShow(true);
        }
    };

    React.useEffect(() => {
        setIsChecked(props.data[props.name])
    }, [props.data[props.name]]);

    return (
        <>
            <Checkbox
                key={`${props.data.id}-${props.name}`}
                checked={isChecked}
                disabled={props?.loading || isLoading}
                style={{ padding: 0 }}
                onChange={(value) => handleCheckboxChange(value)}
            />
            <SweetAlert
                show={isShow}
                title={props.label}
                text={text}
                type={'warning'}
                showCancelButton={true}
                cancelButtonText={'Non'}
                confirmButtonText={'Oui'}
                onConfirm={() => {
                    setIsShow(false);
                    setIsChecked(isEvent);
                    props.save(props.data.id, props.name, isEvent, setIsLoading);
                }}
                onCancel={() => {
                    setIsShow(false);
                }}
            />
        </>
    );
}

const useStyles = makeStyles(style => ({
    loaderActivity: {
        '& > div': {
            margin: 'auto',
            padding: 10,
            width: '50px !important',
            height: '50px !important'
        }
    },
    loader: {
        textAlign: 'center'
    },
    loaderContent: {
        margin: 'auto',
        background: '#FFF',
        border: '5px double rgb(206, 200, 200)',
        borderRadius: 100
    },
    filter: {
        padding: '0 0 10px 0',
        display: 'grid',
        gridTemplateColumns: 'auto max-content',
        alignItems: 'center',
        gap: 10,
        '& .MuiFormHelperText-root': {
            display: 'none'
        }
    },
    creditStyle: {
        width: '100%',
        display: 'grid',
        gridTemplateColumns: 'max-content auto',
        '&>div': {
            margin: '10px 0 0 0'
        },
        '&::before': {
            content: '" "',
            width: 60,
            borderLeft: '2px solid',
            borderBottom: '2px solid',
            borderRadius: '0 10px',
            transform: 'translate(50%,-39%)',
            zIndex: -1,
            position: 'relative',
            opacity: 0.3
        }
    },
    error: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
        fontSize: 30,
        fontWeight: 'bold',
        opacity: 0.3,
        margin: 0,
        width: '100%',
        textAlign: 'center',
    },
}));

export default ViewRegistrationInvoicesComponent;