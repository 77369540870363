import { Box, Fade, IconButton, LinearProgress, Tooltip, Typography, makeStyles } from '@material-ui/core';
import { Assignment, PersonAddDisabled } from '@material-ui/icons';
import moment from 'moment';
import React from 'react';
import UseAnimations from 'react-useanimations';
import activity from "react-useanimations/lib/activity";
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from '../../../../../../Action/LinearProgressMainLayoutAction';
import { SnackbarOpen } from '../../../../../../Action/SnackbarAction';
import Api from '../../../../../../Api';
import { dispatch } from '../../../../../../App';
import TableComponent from '../../../../../../Component/TableComponent';
import { getRoutePathname } from '../../../../../../Config/Route';
import { hourBySecondClassic } from "../../../../../../Tool/convert";
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import SweetAlert from 'sweetalert-react/lib/SweetAlert';
import Action from './Action';
import { useSelector } from 'react-redux';

function LinearProgressWithLabel(props) {
    const getColor = (value) => {
        if (value >= 0 && value < 33) {
            return '#dc3545';
        } else if (value >= 33 && value < 66) {
            return '#db770a';
        } else if (value >= 66) {
            return '#28a745';
        }
    };
    const useStyles = makeStyles({
        root: {
            backgroundColor: '#e5e3e3',
            '& .MuiLinearProgress-barColorPrimary': { backgroundColor: getColor(props.value) }
        }
    });
    const classes = useStyles();

    return (
        <Box display="flex" alignItems="center">
            <Box width="100%" mr={1}>
                <LinearProgress className={classes.root} variant="determinate" {...props} />
            </Box>
            <Box minWidth={35}>
                <Typography variant="body2" color="textSecondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}


function RegistrationsFIFPL(props = {
    reloadList: () => { }
}) {
    const classes = useStyles();
    const [propsReady, setPropsReady] = React.useState(false);
    const [isLock, setIsLock] = React.useState(true);
    const [dialog, setDialog] = React.useState(false);
    const [isBillable, setIsBillable] = React.useState(true);
    const [registration, setRegistration] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const selectOrganismReducer = useSelector(state => state.SelectOrganismReducer);

    let columns = [
        {
            name: "id",
            label: "Référence",
            options: { filter: true, sort: true }
        },
        {
            name: "prospectName",
            label: "Nom / Prénom",
            options: { filter: true, sort: true }
        },
        {
            name: "finishAtFormat",
            label: "Formation terminée le",
            options: { filter: true, sort: true }
        },
        {
            name: "availableAtFormat",
            label: "Date d\'inscription",
            options: { filter: true, sort: true }
        },
        /*{
            name: "durationConnectedText",
            label: "Temps de connexion",
            options: { filter: true, sort: true }
        },
        {
            name: "durationVirtualClassText",
            label: "Temps classe virtuelle",
            options: { filter: true, sort: true }
        },*/
        {
            name: "durationTotalSessionText",
            label: "Temps Total",
            options: { filter: true, sort: true }
        },
        {
            name: "stateText",
            label: "État",
            options: { filter: true, sort: true }
        },
        {
            name: "completionProgress",
            label: "Complétion",
            options: { filter: true, sort: true }
        },
        {
            name: "action",
            label: "Action",
            options: { filter: false, sort: false }
        }
    ];

    const cancel = (registration) => {
        dispatch(LinearProgressMainLayoutActivate());
        Api.get({
            route: 'financial_management_relaunch_registration_cancel',
            params: { registration: registration }
        },
            (response) => {
                if (response.status === 200) {
                    dispatch(
                        SnackbarOpen({
                            text: 'Le contact a bien été désinscrit de la formation.',
                            variant: 'success',
                        })
                    );
                    props.reloadList();
                }
                else {
                    dispatch(
                        SnackbarOpen({
                            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
                dispatch(LinearProgressMainLayoutDeactivate());
            });
    };

    const getAction = (row) => {
        return (
            <div style={{ width: 'max-content' }}>
                <Tooltip title={'Visualiser la fiche contact'} placement="left">
                    <span>
                        <IconButton onClick={() => {
                            window.open(getRoutePathname('institution_health_crm_institution_employee', { institution: row.institutionId, id: row.employee }), '_blank').focus();
                        }} disabled={row.state.value === 3}>
                            <Assignment />
                        </IconButton>
                    </span>
                </Tooltip>
                <Tooltip title={'Facture (P1)'} placement="left">
                    <span>
                        <IconButton onClick={() => {
                            window.open(getRoutePathname('institution_health_financial_management_billing_institution_invoice', { institution: row.institutionId, id: row.contractId }))
                        }} disabled={[2, 3].includes(row.state.value)} >
                            <LibraryBooksIcon />
                        </IconButton>
                    </span>
                </Tooltip>
                <Tooltip title={'Désinscription'} placement="left">
                    <span>
                        <IconButton
                            onClick={() => { setRegistration(row) }}
                            disabled={![10].includes(row.state.value)}>
                            <PersonAddDisabled />
                        </IconButton>
                    </span>
                </Tooltip>
            </div>
        );
    };

    React.useEffect(() => {
        setPropsReady(true);
        setIsBillable(props.billing.isBillable);
    }, [props.billing]);

    const getDataTable = (registrations, billing) => {
        let r = registrations;
        for (let index in r) {
            r[index]['finishAtFormat'] = r[index]['finishAt'] !== '-' ? moment(r[index]['finishAt']).format('lll') : '-';
            r[index]['availableAtFormat'] = r[index]['availableAt'] !== '-' ? moment(r[index]['availableAt']).format('lll') : '-';
            r[index]['durationConnectedText'] = <span style={{ color: (r[index]['durationConnected'] < r[index]['durationConnectedExpected']) ? '#AD732C' : '#28A745' }}>{hourBySecondClassic(r[index]['durationConnected'])}</span>;
            r[index]['durationVirtualClassText'] = <span style={{ color: (r[index]['durationVirtualClass'] < r[index]['durationVirtualClassExpected']) ? '#AD732C' : '#28A745' }}>{hourBySecondClassic(r[index]['durationVirtualClass'])}</span>;
            r[index]['durationTotalSessionText'] = <span>{hourBySecondClassic(r[index]['durationTotalSession'])}</span>;
            r[index]['stateText'] = <span style={{ color: r[index]['state']['color'] }}>{r[index]['state']['text']}</span>;
            r[index].completionProgress = <div style={{ width: 150 }}><LinearProgressWithLabel value={r[index].completion} /></div>;
            r[index]['action'] = getAction(r[index], billing);
        }
        return r;
    };

    const getIsBillable = () => {
        return (
            <span style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                {(props.billing && props.billing.state) && (
                    <>
                        {
                            (() => {
                                let chipColor = '#28a745';
                                switch (props.billing.state) {
                                    case 1:
                                        chipColor = '#007bff';
                                        break;
                                    case 2:
                                        chipColor = '#28a745';
                                        break;
                                    case 3:
                                        chipColor = '#db770a';
                                        break;
                                    case 4:
                                        chipColor = '#9c27b0';
                                        break;
                                }
                                return (
                                    <span style={{ background: chipColor }} className={classes.chip}>{props.billing.stateText}</span>
                                );
                            })()
                        }
                    </>
                )}
            </span>
        )
    }
    const billable = (isBillable) => {
        dispatch(LinearProgressMainLayoutActivate());
        setLoading(true);
        Api.post({
            route: 'institution_health_financial_management_billing_session_formation_billable',
            params: { sessionFormation: props.match.params.id },
            data: { isBillable: isBillable, financeTypeId: 3, organismId: selectOrganismReducer.organism.value }
        },
            (response) => {
                dispatch(LinearProgressMainLayoutDeactivate());
                setLoading(false);

                if (!isBillable) setIsLock(true);
                setIsBillable(isBillable);
                props.reloadList();
                if (response.status === 200) {
                    dispatch(
                        SnackbarOpen({
                            text: isBillable ? 'Session formation facturable.' : 'Session formation non facturable.',
                            variant: 'success',
                        })
                    );
                }
                else {
                    dispatch(
                        SnackbarOpen({
                            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
            });
    }

    return (
        <>
            {
                propsReady ?
                    <div className={classes.tabStyle}>
                        {(props && props.billing && props.billing.registrations) &&
                            <>
                                <TableComponent
                                    id={'institution_health_financial_management_billing_registration_fifpl_list'}
                                    minHeight={'calc(100vh - 515px)'}
                                    title={<span style={{ display: 'flex', alignItems: 'center', gap: 5 }}>Liste des inscriptions FIFPL {getIsBillable()}</span>}
                                    onBeforeRefresh={props.reloadList}
                                    columns={columns}
                                    actionThird={{
                                        label: isBillable ? 'Passer en non facturable' : 'Passer en facturable',
                                        onClick: () => { billable(isBillable ? false : true) },
                                        disabled: isBillable ? (loading || props.billing.state === 2 || props.billing.state === 4 || props.billing.registrations.length === 0) : (loading || props.billing.registrations.length === 0),
                                        color: isBillable ? '#DC3545' : '#35A2EB'
                                    }}
                                    actionSecond={{
                                        label: 'Action',
                                        onClick: () => setDialog(true),
                                        disabled: (loading || props.billing.registrations.length === 0),
                                        color: '#728092'
                                    }}
                                    search={false}
                                    promiseData={(resolve) => {
                                        if (props.billing && props.billing.registrations) {
                                            setTimeout(() => {
                                                resolve(getDataTable(props.billing.registrations, props.billing));
                                            }, 50);
                                        }
                                        else {
                                            resolve([])
                                        }
                                    }}
                                />
                                <Action open={dialog} onClick={(value) => setDialog(value)} {...props} />
                            </>
                        }
                    </div>

                    :

                    <Fade in={true} {...{ timeout: 1000 }}>
                        <Box className={classes.loader}>
                            <Box className={classes.loaderContent}>
                                <Box className={classes.loaderActivity}>
                                    <UseAnimations animation={activity} size={70} />
                                </Box>
                            </Box>
                        </Box>
                    </Fade>
            }
            <SweetAlert
                show={registration.length != 0}
                title={'Désinscription'}
                text={
                    'Êtes-vous sûr de vouloir désinscrit ?'
                }
                type={'warning'}
                cancelButtonText={'Non'}
                confirmButtonText={'Oui'}
                showCancelButton={true}
                onConfirm={() => {
                    cancel(registration.id);
                    setRegistration([])
                }}
                onCancel={() => setRegistration([])}
            />
        </>
    );
};

const useStyles = makeStyles({
    loaderActivity: {
        '& > div':
        {
            margin: 'auto',
            padding: 10,
            width: '50px !important',
            height: '50px !important'
        }
    },
    loader: {
        textAlign: 'center',
        width: '100%',
        height: '100%',
        display: 'flex',
        marginTop: 50,
        marginBottom: 50
    },
    loaderContent: {
        margin: 'auto',
        background: '#FFF', border: '5px double rgb(206, 200, 200)', borderRadius: 100
    },
    chip: {
        color: '#fff',
        fontSize: 13,
        padding: '3px 10px',
        display: 'block',
        width: 'fit-content'
    },
    tabStyle: {
        position: 'relative',
        height: '100%',
        '& span': {
            margin: 0,
        },
        '& >div': {
            height: '100%',
            '& .MuiPaper-root': {
                boxShadow: 'none',
                borderRadius: 0,
                height: '100%',
            }
        }
    }
});

export default RegistrationsFIFPL;
