import React from 'react';
import { Box, Fade, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, makeStyles } from "@material-ui/core";
import { Dialog, DialogActions, DialogContent } from "@material-ui/core";
import ButtonComponent from "../../../../../../Component/ButtonComponent";
import Api from "../../../../../../Api";
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from "../../../../../../Action/LinearProgressMainLayoutAction";
import { formHandlerInit } from '../../../../../../Tool/FormHandlerCommon';
import DatePickerComponent from '../../../../../../Component/DatePickerComponent';
import TextFieldComponent from '../../../../../../Component/TextFieldComponent';
import { dispatch } from '../../../../../../App';
import { SnackbarOpen } from '../../../../../../Action/SnackbarAction';
import SweetAlert from 'sweetalert-react/lib/SweetAlert';
import SelectComponent from '../../../../../../Component/SelectComponent';

const DestinationAction = (props) => {
    const classes = useStyles();
    const handler = props.handler;
    const [destination, setDestination] = React.useState(1);

    const option = [
        { value: 1, label: 'Établissement' },
        { value: 2, label: 'L\'apprenant' },
        { value: 3, label: 'Autre' }
    ];

    const onChange = (value) => {
        setDestination(value);
        handler.form[`destinationFor_${props.registration.id}`].options = value == 3 ? { validation: ['required'] } : {}
    }

    React.useEffect(() => {
        if (props.disabled) {
            setDestination(1);
            handler.form[`destinationType_${props.registration.id}`].value = 1;
        }
    }, [props.disabled]);

    return (
        <Box className={classes.destinationRoot}>
            {destination != 3 ? <p>{option[destination - 1].label}</p> : <TextFieldComponent name={`destinationFor_${props.registration.id}`} handler={handler} />}
            <div className={!props.disabled ? 'select-root' : 'selectOff-root'}>
                <SelectComponent name={`destinationType_${props.registration.id}`} handler={handler} options={option} onChange={(value) => onChange(value)} disabled={props.disabled} />
            </div>
        </Box>
    )
}

function Invoice(props) {
    const classes = useStyles();
    const [loading, setLoading] = React.useState(true);
    const [addBillingList, setAddBillingList] = React.useState([]);
    const [pdfUrls, setPdfUrls] = React.useState([]);
    const [registrationList, setRegistrationList] = React.useState([]);
    const [total, setTotal] = React.useState(0);
    const [countRegistration, setCountRegistration] = React.useState(0);
    const [formInvoiceAt, setFormInvoiceAt] = React.useState({
        invoiceAt: {
            name: 'invoiceAt',
            label: 'Date de la facture',
            textHelper: 'Saisissez la date de la facture',
            type: 'date',
            defaultValue: props.sessionFormation.invoiceAt,
            options: { validation: ['required', 'date'] }
        },
        /*isGroup: {
            name: 'isGroup',
            label: 'Facturation',
            textHelper: 'Type de facturation',
            type: 'integer',
            defaultValue: 1,
            options: {}
        }*/
    });
    const handlerInvoiceAt = formHandlerInit(formInvoiceAt, setFormInvoiceAt);
    const [form, setForm] = React.useState({});
    const handler = formHandlerInit(form, setForm);
    const [isGroup, setIsGroup] = React.useState(true);
    /*const optionGroup = [
        { value: 1, label: 'Groupée' },
        { value: 2, label: 'Personnelle' }
    ]
    const inputGroupChange = (value) => {
        setIsGroup(value == 1);
    }*/

    const addBilling = (data) => {
        setAddBillingList((prev) => {
            return [...prev, data];
        });
    };

    const removeBilling = (data) => {
        setAddBillingList((prev) => {
            return prev.filter(item => item.id !== data.id);
        });

        setRegistrationList((prev) => {
            return [...prev, data];
        });
    };

    const calcTotal = () => {
        let data = handler.getData();
        let total = 0;
        let countRegistration = 0;

        for (let index in data) {
            let split = index.split("_");
            if (split && split[0] === 'price') {
                total += parseFloat(data[index] ?? 0);
                countRegistration++;
            }
        }

        setTotal((Number(total) === total) ? total.toFixed(2) : 0);
        setCountRegistration(countRegistration);
    };

    React.useEffect(handlerInvoiceAt.start, []);
    React.useEffect(handler.start, []);
    React.useEffect(() => {
        setLoading(false);
        if (addBillingList.length !== 0) {
            setForm((prevData) => {
                const newData = addBillingList.reduce((acc, registration) => {
                    acc['price_' + registration.id] = {
                        name: 'price_' + registration.id,
                        label: 'Prix',
                        textHelper: '',
                        type: 'float',
                        defaultValue: '',
                        value: registration.initialAmount ?? '0',
                        options: {},
                        registration: registration.id
                    };

                    acc['contract_' + registration.id] = {
                        name: 'contract_' + registration.id,
                        value: registration.contractId,
                        registration: registration.id
                    };

                    acc['comment_' + registration.id] = {
                        name: 'comment_' + registration.id,
                        label: 'Commentaire',
                        textHelper: '',
                        type: 'text',
                        defaultValue: '',
                        options: {},
                        registration: registration.id
                    };

                    acc['destinationType_' + registration.id] = {
                        name: 'destinationType_' + registration.id,
                        label: 'Destination',
                        textHelper: '',
                        type: 'integer',
                        defaultValue: 1,
                        value: 1,
                        options: {},
                        registration: registration.id
                    };

                    acc['destinationFor_' + registration.id] = {
                        name: 'destinationFor_' + registration.id,
                        label: 'Autre',
                        textHelper: '',
                        type: 'text',
                        defaultValue: '',
                        options: {},
                        registration: registration.id
                    };
                    return acc;
                }, {});

                const mergedData = { ...prevData };
                Object.keys(newData).forEach(key => {
                    mergedData[key] = {
                        ...newData[key],
                        ...prevData[key]
                    };
                });

                return mergedData;
            });

            setRegistrationList((prev) => {
                return prev.filter(item => !addBillingList.some(reg => reg.id === item.id));
            });
        }

        setForm((prev) => {
            const result = {};
            addBillingList.forEach(item => {
                const registrationId = item.id.toString();
                for (const key in prev) {
                    if (prev[key].registration.toString() === registrationId) {
                        result[key] = prev[key];
                    }
                }
            });
            return result;
        });

    }, [addBillingList]);

    React.useEffect(() => {
        calcTotal()
    }, [handler.getData()]);

    const save = () => {
        if (handler.checkError() || handlerInvoiceAt.checkError()) {
            console.log('Error');
        }
        else {
            dispatch(LinearProgressMainLayoutActivate());
            handler.setFormLoading(true);
            setLoading(true);
            let data = handler.getData();
            let lines = [];

            Object.keys(data).forEach((key) => {
                if (key.startsWith('price_')) {
                    const id = key.split('_')[1];
                    const price = data[key];

                    const destinationTypeKey = 'destinationType_' + id;
                    const destinationForKey = 'destinationFor_' + id;
                    const contractKey = 'contract_' + id;
                    const commentKey = 'comment_' + id;

                    let lineData = {
                        contractId: data[contractKey],
                        comment: data[commentKey],
                        price: price,
                        id: id
                    }

                    if (data[destinationTypeKey] || data[destinationForKey]) {
                        lineData.destinationType = data[destinationTypeKey];
                        lineData.destinationFor = data[destinationForKey];
                    } else {
                        lineData.destinationType = 2;
                        lineData.destinationFor = null;
                    }
                    lines.push(lineData);
                }
            });

            Api.post({
                route: 'institution_health_financial_management_billing_session_formation_invoice',
                params: { sessionFormation: props.sessionFormation.id },
                data: {
                    lines: lines,
                    invoiceAt: handlerInvoiceAt.getData().invoiceAt,
                    //isGroup: handlerInvoiceAt.getData().isGroup == 1 ? true : false,
                    financeTypeId: 3
                }
            },
                (response) => {
                    dispatch(LinearProgressMainLayoutDeactivate());
                    handler.setFormLoading(false);
                    setLoading(false);

                    if (response.status === 200) {
                        props.close();
                        props.reloadList();
                        dispatch(
                            SnackbarOpen({
                                text: 'Session formation facturée.',
                                variant: 'success',
                            })
                        );
                        let urls = [];
                        response.data.succesInvoices.forEach(invoiceData => {
                            const url = Api.route({
                                route: 'institution_health_financial_management_billing_session_formation_download',
                                params: { sessionFormation: props.sessionFormation.id, id: invoiceData.id },
                                data: { isInvoice: true }
                            });
                            urls.push(url)
                        });
                        setPdfUrls(urls);
                    }
                    else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
        }
    };

    const getAddRegistrations = () => {
        let registrations = registrationList;
        return (
            (registrations.length != 0) ?
                <Box style={{ position: 'absolute', padding: '10px 0', width: '95%', left: '50%', top: 0, transform: 'translate(-50%)' }}>
                    <Box style={{ display: 'flex', flexDirection: 'column', gap: 10, }}>
                        {registrations.map((registration, regIndex) => (
                            <Box key={regIndex} style={{ background: '#FFF', width: 'auto', fontSize: 13, textAlign: 'center', alignItems: 'center', padding: 10, borderRadius: 10, border: '1px solid #A3ADB7', display: 'grid', gridTemplateColumns: 'max-content auto max-content max-content' }}>
                                <p style={{ margin: 0, padding: '0 10px' }}>{registration.id}</p>
                                <p style={{ margin: 0, padding: '0 10px', borderLeft: '1px solid #A3ADB7', borderRight: '1px solid #A3ADB7' }}><b>{registration.firstname} {registration.lastname}</b></p>
                                <p style={{ margin: 0, padding: '0 10px', borderRight: '1px solid #A3ADB7' }}>Prix: <b>{registration.initialAmount}</b></p>
                                <Box style={{ padding: '0 10px' }}>
                                    <ButtonComponent disabled={loading} onClick={() => addBilling(registration)} color={'#5E6E82'} label={'Ajouter'} />
                                </Box>
                            </Box>
                        ))}
                    </Box>
                </Box> :
                <Box style={{ position: 'absolute', padding: '10px 0', width: '95%', left: '50%', top: '50%', transform: 'translate(-50%,-50%)' }}>
                    <p style={{ color: '#5E6E82', margin: 'auto', fontSize: 12, textAlign: 'center' }}>Aucun résultat.</p>
                </Box>
        );
    }

    const getRenderRegistrations = () => {
        if (addBillingList) {
            let render = [];
            let registrations = addBillingList;
            if (registrations.length != 0) {
                for (let index in registrations) {
                    render.push(
                        (handler.form[`price_${registrations[index].id}`]) &&
                        <Fade key={registrations[index].id} in={true} {...{ timeout: 1000 }}>
                            <TableRow>
                                <TableCell className={classes.td}>{registrations[index].id}</TableCell>
                                <TableCell className={classes.td}><b>{registrations[index].firstname} {registrations[index].lastname}</b></TableCell>
                                {/*<TableCell className={classes.td}>
                                    <DestinationAction registration={registrations[index]} disabled={isGroup} handler={handler} {...props} />
                                </TableCell>*/}
                                <TableCell className={classes.td}>
                                    <TextFieldComponent name={`price_${registrations[index].id}`} handler={handler} />
                                </TableCell>
                                <TableCell className={classes.td}><ButtonComponent disabled={loading} color={'#DD4E4E'} onClick={() => removeBilling(registrations[index])} label={'Retirer'} /></TableCell>
                            </TableRow>
                        </Fade>
                    );
                }
            } else {
                render.push(
                    <TableRow>
                        <TableCell colSpan={5} className={classes.td} >Aucun résultat.</TableCell>
                    </TableRow>
                )
            }
            return render;
        } else return <></>;
    };

    React.useEffect(() => {
        setRegistrationList(props.registrations.length != 0 ? props.registrations : [])
    }, [props.registrations]);
    
    return (
        <>
            <Dialog open={props.open} className={classes.dialogStyle} maxWidth={'xl'} onClose={props.close}>
                <Box style={{ display: 'grid', gap: 10, gridTemplateColumns: 'auto 450px', padding: 10, height: 600 }}>
                    <Box style={{ display: 'flex', flexDirection: 'column' }}>
                        <Box style={{ fontSize: 15, color: '#5E6E82', }}>
                            <Box style={{ display: 'grid', gridTemplateColumns: 'max-content auto', alignItems: 'center', gap: 10 }}>
                                <h1 style={{ fontSize: 20, margin: 0 }}>Facture - FIFPL</h1>
                                <hr style={{ margin: 0, opacity: 0.5 }} />
                            </Box>
                            <Box style={{ padding: '10px 20px' }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <DatePickerComponent name={'invoiceAt'} handler={handlerInvoiceAt} />
                                    </Grid>
                                    {/*<Grid item xs={12} sm={12} md={3} lg={3}>
                                        <SelectComponent name={'isGroup'} handler={handlerInvoiceAt} options={optionGroup} onChange={(val) => inputGroupChange(val)} />
                                    </Grid>*/}
                                </Grid>
                            </Box>
                            <hr style={{ margin: 0 }} />
                        </Box>
                        <DialogContent style={{ padding: '0px 24px' }}>
                            <TableContainer style={{ position: 'relative', height: '100%' }}>
                                <Table style={{ position: 'absolute' }}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className={classes.th}>Référence</TableCell>
                                            <TableCell className={classes.th}>Nom</TableCell>
                                            {/*<TableCell className={classes.th}>À destination</TableCell>*/}
                                            <TableCell className={classes.th}>Prix</TableCell>
                                            <TableCell className={classes.th}></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {getRenderRegistrations()}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </DialogContent>
                        <Box>
                            <hr style={{ margin: 0 }} />
                            <div style={{ textAlign: 'end', padding: '10px 20px', color: '#5E6E82', }}>
                                Nombre total participant : <span style={{ fontWeight: 600 }}>{countRegistration}</span> -- Total : <span style={{ fontWeight: 600 }}>{total} €</span>
                            </div>
                        </Box>
                        <DialogActions>
                            <ButtonComponent disabled={loading} onClick={props.close} color={'#5E6E82'} label={'Annuler'} />
                            <ButtonComponent disabled={loading || countRegistration === 0} onClick={save} label={'Générer la facture'} />
                        </DialogActions>
                    </Box>
                    <Box style={{ display: 'grid', gridTemplateRows: 'max-content auto', gap: 10, color: '#5E6E82', height: '100%' }}>
                        <Box style={{ display: 'grid', gridTemplateColumns: 'max-content auto', alignItems: 'center', gap: 10 }}>
                            <h1 style={{ fontSize: 20, margin: 0 }}>Liste des inscription</h1>
                            <hr style={{ margin: 0, opacity: 0.5 }} />
                        </Box>
                        <Box style={{ display: 'flex', flexDirection: 'column', background: '#EFF3F4', borderRadius: 10, gap: 10, border: '1px solid rgba(163, 173, 183, 0.36)', position: 'relative', overflow: 'hidden auto' }}>
                            {getAddRegistrations()}
                        </Box>
                    </Box>
                </Box>
            </Dialog>
            <SweetAlert
                show={pdfUrls.length > 0}
                title={'Téléchargement des factures'}
                text={
                    `Voulez vous télécharger les PDF des ${pdfUrls.length} facture(s) générée(s) ?`
                }
                type={'warning'}
                cancelButtonText={'Non'}
                confirmButtonText={'Oui'}
                showCancelButton={true}
                onConfirm={() => {
                    pdfUrls.forEach(pdfUrl => {
                        window.open(pdfUrl, '_blank');
                    });
                    setPdfUrls([]);
                }}
                onCancel={() => setPdfUrls([])}
            />
        </>
    );
}

const useStyles = makeStyles({
    th: {
        fontWeight: 900,
        fontSize: 15,
        padding: 12,
        textAlign: 'center',
        color: '#5E6E82',
    },
    td: {
        fontSize: 13,
        padding: 5,
        textAlign: 'center',
        color: '#5E6E82',
        '& .MuiFormHelperText-root': {
            display: 'none'
        }
    },
    destinationRoot: {
        display: 'grid',
        gridTemplateColumns: 'max-content auto',
        justifyContent: 'center',
        '& p': {
            padding: '7.1px 14px',
            fontSize: 13,
            margin: 0,
            width: 140,
            border: '1px solid rgba(0, 0, 0, 0.23)',
            borderRadius: '5px 0px 0px 5px'
        },
        '& .MuiFormHelperText-root': {
            display: 'none'
        },
        '& .MuiTextField-root': {
            width: 170
        },
        '& fieldset': {
            borderRadius: '5px 0px 0px 5px'
        },
        '& .selectOff-root': {
            '& label, legend': {
                display: 'none'
            },
            '& select': {
                background: 'transparent !important',
                color: '#5E6E82',
                opacity: 0
            },
            '& fieldset': {
                top: 0,
                borderRadius: '0px 5px 5px 0px',
                borderColor: '#5E6E823B !important'
            },
            '& .MuiFormControl-root': {
                width: 38
            },
        },
        '& .select-root': {
            '& label, legend': {
                display: 'none'
            },
            '& select': {
                background: 'transparent !important',
                color: '#5E6E82',
                opacity: 0
            },
            '& fieldset': {
                top: 0,
                borderRadius: '0px 5px 5px 0px',
                borderColor: '#5E6E82 !important'
            },
            '& .MuiFormControl-root': {
                width: 38
            },
        },
    },
    dialogStyle: {
        '& .MuiPaper-root': {
            width: 1500,
            padding: 17
        },
        '& .MuiDialogContent-root': {
            overflowY: 'initial'
        },
        '& #deposit': {
            textAlign: 'center'
        }
    },
    containerCheckbox: {
        display: 'flex',
        justifyContent: 'space-around'
    }
});

export default Invoice;
