import React from 'react';
import ContentViewComponent from "../../../../Component/ContentViewComponent";
import TableComponent from "../../../../Component/TableComponent";
import Api from "../../../../Api";
import { formHandlerInit } from "../../../../Tool/FormHandlerCommon";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import { Edit, Delete } from "@material-ui/icons";
import SelectComponent from "../../../../Component/SelectComponent";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import FilterListIcon from "@material-ui/icons/FilterList";
import { useDispatch, useSelector } from "react-redux";
import { Box, makeStyles, Drawer as DrawerMUI } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import SweetAlert from 'sweetalert-react';
import { TableReload } from "../../../../Action/TableAction";
import { getRoutePathname } from "../../../../Config/Route";
import DatePickerComponent from "../../../../Component/DatePickerComponent";
import { cacheTablePage, cacheTableServer } from "../../../../Cache";
import moment from "moment";
import ButtonComponent from '../../../../Component/ButtonComponent';

function SessionList(props) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const selectOrganismReducer = useSelector(state => state.SelectOrganismReducer)
    const [isScreen, setIsScreen] = React.useState(true);
    const [isReadyContent, setReadyContent] = React.useState(false);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [showConfirm, setShowConfirm] = React.useState(false);
    const [optionFinanceType, setOptionFinanceType] = React.useState([]);
    const [actionRow, setActionRow] = React.useState({});
    const [optionYears, setOptionYears] = React.useState([]);
    const [isOpen, setIsOpen] = React.useState(false);
    const screenReducer = useSelector(state => state.ScreenReducer);

    const columns = [
        {
            name: "id",
            label: "Référence",
            options: { filter: true, sort: true }
        },
        {
            name: "sessionStartAtText",
            label: "Debut de session",
            options: { filter: true, sort: true }
        },
        {
            name: "sessionEndAtText",
            label: "Fin de session",
            options: { filter: true, sort: true }
        },
        {
            name: "organismName",
            label: "Organisme",
            options: { filter: true, sort: true }
        },
        {
            name: "financeType",
            label: "Type de financement",
            options: { filter: true, sort: true }
        },
        {
            name: "action",
            label: "Action",
            options: { filter: false, sort: false }
        }
    ];
    const getAction = (row) => {
        return (
            <>
                <Tooltip title={'Modifier'} placement="left">
                    <IconButton onClick={() => props.history.push(getRoutePathname('institution_health_admin_session', { id: row.id }))}>
                        <Edit />
                    </IconButton>
                </Tooltip>
                <Tooltip title={'Supprimer'} placement="left">
                    <IconButton onClick={() => {
                        setActionRow(row);
                        setShowConfirm(true);
                    }}>
                        <Delete />
                    </IconButton>
                </Tooltip>
            </>
        );
    };
    const currentYear = new Date().getFullYear();
    const [formSearch, setFormSearch] = React.useState({
        sessionStartAtText: {
            name: 'sessionStartAtText',
            label: 'Date de début de session',
            textHelper: 'Saisissez une date.',
            type: 'date',
            defaultValue: cacheTableServer['institution_health_admin_session_list'] && cacheTableServer['institution_health_admin_session_list'].sessionStartAtText ? cacheTableServer['institution_health_admin_session_list'].sessionStartAtText : '',
            options: {}
        },
        sessionEndAtText: {
            name: 'sessionEndAtText',
            label: 'Date de fin de session.',
            textHelper: 'Saisissez une date.',
            type: 'date',
            defaultValue: cacheTableServer['institution_health_admin_session_list'] && cacheTableServer['institution_health_admin_session_list'].sessionEndAtText ? cacheTableServer['institution_health_admin_session_list'].sessionEndAtText : '',
            options: {}
        },
        organismName: {
            name: 'organismName',
            label: 'Organisms',
            textHelper: 'Trier par organisms.',
            type: 'text',
            defaultValue: cacheTableServer['institution_health_admin_session_list'] && cacheTableServer['institution_health_admin_session_list'].organismName ? cacheTableServer['institution_health_admin_session_list'].organismName : 'all',
            options: {}
        },
        year: {
            name: 'year',
            label: 'Année',
            textHelper: 'Trier par année.',
            type: 'text',
            defaultValue: cacheTableServer['institution_health_admin_session_list'] && cacheTableServer['institution_health_admin_session_list'].year ? cacheTableServer['institution_health_admin_session_list'].year : currentYear,
            options: {}
        },
        financeType: {
            name: 'financeType',
            label: 'Type de financement',
            textHelper: 'Trier par type de financement.',
            type: 'text',
            defaultValue: cacheTableServer['institution_health_admin_session_list'] && cacheTableServer['institution_health_admin_session_list'].financeType ? cacheTableServer['institution_health_admin_session_list'].financeType : 'all',
            options: {}
        },
    });

    const GetActionDowlaod = () => {
        return (
            <Box style={{ display: 'flex', gap: 12 }}>
                <ButtonComponent color={"#5E6E82"} label={<><FilterListIcon style={{ color: '#FFFFFF' }} /> Filtre</>} onClick={() => setIsOpen(!isOpen)} />
            </Box>
        )
    }

    const filter = () => {
        return (
            <Box style={{ width: '90%', height: '100%', margin: 'auto', }}>
                <Box style={{
                    margin: 0,
                    display: 'flex',
                    alignItems: 'center',
                    padding: '19px 19px 10px 19px',
                    color: '#5E6E82', opacity: 0.7, fontSize: 14
                }}>FILTRE<hr style={{ width: '70%', border: '#5E6E82 1px solid', borderRadius: 10, opacity: 0.4 }} />
                    {!isScreen &&
                        <IconButton style={{ padding: 5 }} onClick={() => setIsOpen(false)}>
                            <HighlightOffIcon />
                        </IconButton>
                    }
                </Box>
                <Grid container spacing={2} style={{ width: '100%', margin: 0 }}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <SelectComponent name={'year'} handler={handlerSearch} options={optionYears} onChange={(val) => inputSearchChange('year', val)} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <SelectComponent name={'financeType'} handler={handlerSearch} options={optionFinanceType} onChange={(val) => inputSearchChange('financeType', val)} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <DatePickerComponent name={'sessionStartAtText'} handler={handlerSearch} onChange={(val) => inputSearchChange('sessionStartAtText', val)} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <DatePickerComponent name={'sessionEndAtText'} handler={handlerSearch} onChange={(val) => inputSearchChange('sessionEndAtText', val)} />
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        )
    }
    const handlerSearch = formHandlerInit(formSearch, setFormSearch);
    const inputSearchChange = (index, value) => {
        handlerSearch.setValue(index, value);
        cacheTablePage['institution_health_admin_session_list'] = 0;
        cacheTableServer['institution_health_admin_session_list'].page = 0;
        cacheTableServer['institution_health_admin_session_list'][index] = value;
        dispatch(TableReload('institution_health_admin_session_list'))
    };

    React.useEffect(handlerSearch.start, []);

    React.useEffect(() => {
        setBreadcrumbs({
            title: 'Sessions',
            context: 'Portail',
            path: getRoutePathname('institution_health_home'),
            description: 'Liste des sessions.',
            html: <GetActionDowlaod />
        });
    }, [isOpen])

    React.useEffect(() => {
        // Year
        let yearsTMP = [];
        for (let i = currentYear + 1; i > currentYear - 5; i--) {
            yearsTMP.push({ label: i, value: i });
        }
        setOptionYears(yearsTMP);

        Api.get({
            route: 'select_finance_types',
            data: { 'isFull': true, 'isInstitution': true }
        },
            (response) => {
                if (response && response.data) {
                    let data = [{ value: 'all', label: 'Tous' }, ...response.data];
                    setOptionFinanceType(data);
                }
            });

        setReadyContent(true);
    }, []);

    const getDatas = (resolve, filters) => {
        Api.get({
            route: 'institution_health_administrator_session_list', data: filters
        },
            (response) => {
                let data = response.data.data;
                for (let index in data) {
                    data[index].sessionStartAtText = moment(data[index].sessionStartAt).format("DD/MM/YYYY");
                    data[index].sessionEndAtText = moment(data[index].sessionEndAt).format("DD/MM/YYYY");
                    data[index].action = getAction(data[index]);
                }
                resolve(data, response.data.count);
            });
    }

    React.useEffect(() => {
        if (screenReducer.screen) {
            setIsScreen(screenReducer.screen === 'LG' || screenReducer.screen === 'XL');
        }
    }, [screenReducer.screen]);

    return (
        <Box className={classes.styleContentView}>
            <DrawerMUI
                variant="permanent"
                className={`${classes.drawer} ${isOpen ? classes.drawerOpen : classes.drawerClose}`}
                style={!isScreen ? { position: 'absolute', zIndex: 10 } : {}}
                open={isOpen}>
                {filter()}
            </DrawerMUI>
            <ContentViewComponent loading={!isReadyContent} breadcrumbs={breadcrumbs} {...props}>
                <Box className={classes.TableComponent} style={{ width: '95%' }}>
                    <TableComponent
                        id={'institution_health_admin_session_list'}
                        title={<h1 style={{ margin: 0, fontSize: 15, display: 'flex', alignItems: 'center', gap: 5, }}>Sessions</h1>}
                        columns={columns}
                        actionFirst={{
                            label: 'Ajouter une session',
                            onClick: () => props.history.push(getRoutePathname('institution_health_admin_session', { id: null }))
                        }}
                        promiseServerData={(resolve, options) => {
                            let datas = handlerSearch.getData();
                            let filters = {
                                limit: options.rowsPerPage,
                                offset: options.page * options.rowsPerPage,
                                sortName: options.sortOrder.name,
                                sortDirection: options.sortOrder.direction,
                                year: datas.year ?? currentYear,
                                organismName: selectOrganismReducer.organism.value,
                                sessionStartAt: datas.sessionStartAtText ?? '',
                                sessionEndAt: datas.sessionEndAtText ?? '',
                                financeType: (datas.financeType === 'all') ? '' : datas.financeType,
                            };
                            getDatas(resolve, filters)
                        }}
                        search={false}
                    />


                    <SweetAlert
                        show={showConfirm}
                        title={'Supprimer'}
                        text={actionRow.name + '\nÊtes-vous sur de vouloir supprimer cet session ?'}
                        type={'warning'}
                        cancelButtonText={'Non'}
                        confirmButtonText={'Oui'}
                        showCancelButton={true}
                        onConfirm={() => {
                            setShowConfirm(false);
                            Api.delete({
                                route: 'institution_health_administrator_session_delete',
                                params: { id: actionRow.id }
                            },
                                () => {
                                    dispatch(TableReload('institution_health_admin_session_list'))
                                });
                        }}
                        onCancel={() => setShowConfirm(false)}
                    />
                </Box>
            </ContentViewComponent>
        </Box>
    );
}

const drawerOpenWidth = 340;
const drawerCloseWidth = 0;
const useStyles = makeStyles(style => ({
    TableComponent: {
        height: '90%',
        width: '95%',
        margin: 'auto',
        '& >div': {
            height: '100%',
            '& .MuiPaper-root': {
                height: '100%',
                display: 'grid',
                gridTemplateRows: ' min-content min-content auto min-content min-content',
                overflow: 'hidden',
                '& div .MuiTable-root': {
                    position: 'absolute'
                }
            }
        },
        '& .MuiTableCell-head:last-child': {
            width: 145,
            textAlign: 'center'
        }
    },
    drawer: {
        width: drawerOpenWidth,
        height: '100%',
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxShadow: '-1px 0px 2px rgba(0,0,0,0.2)',
        background: '#FFFFFF',
        overflowX: 'hidden',
        zIndex: 1,
        boxSizing: 'border-box',
        '& .MuiDrawer-paperAnchorDockedLeft': {
            border: 0
        },
        '& .MuiDrawer-paper': {
            height: '100%',
            boxSizing: 'border-box',
            width: '100%',
            overflowX: 'hidden',
            position: 'relative'
        }
    },
    drawerOpen: {
        transition: style.transitions.create('width', {
            easing: style.transitions.easing.sharp,
            duration: style.transitions.duration.enteringScreen,
        }),
        width: drawerOpenWidth
    },
    drawerClose: {
        transition: style.transitions.create('width', {
            easing: style.transitions.easing.sharp,
            duration: style.transitions.duration.leavingScreen,
        }),
        width: drawerCloseWidth
    },
    styleContentView: {
        height: '100%',
        display: 'flex',
        flexDirection: 'row-reverse',
        '& .ContentView-root': {
            height: '100%',
            display: 'grid',
            gridTemplateRows: 'min-content auto',
            width: '100%',
            '& .Breadcrumbs-root:nth-of-type(1)': {
                margin: 0,
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
                height: 'auto',
                '& div:nth-of-type(2)': {
                    margin: '0 0 0 auto',
                }
            }
        }
    },
    h3: {
        color: '#5E6E82',
        fontSize: 18,
        fontWeight: 300,
        margin: '0px 0px 10px 0px',
        padding: '0px 5px',
    }, iconH3: {
        fontSize: 25,
        padding: '0px 5px',
        verticalAlign: 'sub',
        float: 'right'
    }
}));

export default SessionList;
