import React from 'react';
import Api from '../../../../Api';
import Grid from "@material-ui/core/Grid";
import { useDispatch, useSelector } from 'react-redux';
import DateRangeIcon from '@material-ui/icons/DateRange';
import FilterListIcon from "@material-ui/icons/FilterList";
import { getRoutePathname } from '../../../../Config/Route';
import { TableReload } from '../../../../Action/TableAction';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import TableComponent from '../../../../Component/TableComponent';
import ButtonComponent from '../../../../Component/ButtonComponent';
import SelectComponent from '../../../../Component/SelectComponent';
import { cacheTablePage, cacheTableServer } from "../../../../Cache";
import { formHandlerInit } from '../../../../Tool/FormHandlerCommon';
import TextFieldComponent from '../../../../Component/TextFieldComponent';
import DatePickerComponent from "../../../../Component/DatePickerComponent";
import ContentViewComponent from '../../../../Component/ContentViewComponent';
import { Box, IconButton, Tooltip, makeStyles, Drawer as DrawerMUI } from '@material-ui/core';
import { LinearProgressMainLayoutDeactivate } from '../../../../Action/LinearProgressMainLayoutAction';


export default function BillingFormationList(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [isReadyContent, setReadyContent] = React.useState(false);
    const selectOrganismReducer = useSelector(state => state.SelectOrganismReducer);
    const [optionYears, setOptionYears] = React.useState([]);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [isOpen, setIsOpen] = React.useState(false);
    const [isScreen, setIsScreen] = React.useState(true);
    const screenReducer = useSelector(state => state.ScreenReducer);
    const currentYear = new Date().getFullYear();
    const colors = {
        black: "#000",
        blue: "#007bff",
        green: "#28a745",
        orange: "#db770a",
        purple: "#9c27b0",
    };
    const columns = [
        {
            name: "id",
            label: "Référence",
            options: { filter: true, sort: true }
        },
        {
            name: "formationName",
            label: "Intitulé de formation",
            options: { filter: false, sort: true }
        },
        {
            name: "startAt",
            label: "Date de début",
            options: { filter: true, sort: true }
        },
        {
            name: "endAt",
            label: "Date de fin",
            options: { filter: true, sort: true }
        },
        {
            name: "countRegistration",
            label: "Nombre d'inscrits",
            options: { filter: false, sort: false }
        },
        {
            name: "action",
            label: "Action",
            options: { filter: false, sort: false }
        }
    ];
    const [formSearch, setFormSearch] = React.useState({
        text: {
            name: 'text',
            label: 'Recherche',
            textHelper: 'Saisissez une Recherche.',
            type: 'text',
            defaultValue: cacheTableServer['institution_health_financial_management_billing_formation_list'] && cacheTableServer['institution_health_financial_management_billing_formation_list'].text ? cacheTableServer['institution_health_financial_management_billing_formation_list'].text : '',
            options: {}
        },
        startAt: {
            name: 'startAt',
            label: 'Début de session',
            textHelper: 'Trier par début de session.',
            type: 'text',
            defaultValue: cacheTableServer['institution_health_financial_management_billing_formation_list'] && cacheTableServer['institution_health_financial_management_billing_formation_list'].startAt ? cacheTableServer['institution_health_financial_management_billing_formation_list'].startAt : '',
            options: {}
        },
        endAt: {
            name: 'endAt',
            label: 'Fin de session',
            textHelper: 'Trier par fin de session.',
            type: 'text',
            defaultValue: cacheTableServer['institution_health_financial_management_billing_formation_list'] && cacheTableServer['institution_health_financial_management_billing_formation_list'].endAt ? cacheTableServer['institution_health_financial_management_billing_formation_list'].endAt : '',
            options: {}
        },
        year: {
            name: 'year',
            label: 'Année',
            textHelper: 'Trier par année.',
            type: 'text',
            defaultValue: cacheTableServer['institution_health_financial_management_billing_formation_list'] && cacheTableServer['institution_health_financial_management_billing_formation_list'].year ? cacheTableServer['institution_health_financial_management_billing_formation_list'].year : currentYear,
            options: {}
        },
    });

    const handlerSearch = formHandlerInit(formSearch, setFormSearch);
    React.useEffect(handlerSearch.start, []);

    const inputSearchChange = (index, value) => {
        handlerSearch.setValue(index, value);
        cacheTablePage['institution_health_financial_management_billing_formation_list'] = 0;
        cacheTableServer['institution_health_financial_management_billing_formation_list'].page = 0;
        cacheTableServer['institution_health_financial_management_billing_formation_list'][index] = value;
        dispatch(TableReload('institution_health_financial_management_billing_formation_list'))
    };

    const getFilterValues = (options) => {
        let data = handlerSearch.getData();
        for (const key in data) {
            if (
                (key === 'organism')
                && data[key] === 'all'
            ) {
                data[key] = null;
            }
        }
        let optionsFilter = {}

        if (options) {
            optionsFilter = {
                limit: options.rowsPerPage,
                offset: options.page * options.rowsPerPage,
                sortName: options.sortOrder.name,
                sortDirection: options.sortOrder.direction
            }
        } else {
            optionsFilter = {
                limit: 10000,
            }
        }

        const filters = {
            ...data,
            ...optionsFilter
        };
        return filters
    }

    const filter = () => {
        return (
            <Box style={{ width: '90%', height: '100%', margin: 'auto', }}>
                <Box style={{
                    margin: 0,
                    display: 'flex',
                    alignItems: 'center',
                    padding: '19px 19px 10px 19px',
                    color: '#5E6E82', opacity: 0.7, fontSize: 14
                }}>FILTRE<hr style={{ width: '70%', border: '#5E6E82 1px solid', borderRadius: 10, opacity: 0.4 }} />
                    {!isScreen &&
                        <IconButton style={{ padding: 5 }} onClick={() => setIsOpen(false)}>
                            <HighlightOffIcon />
                        </IconButton>
                    }
                </Box>
                <Grid container spacing={2} style={{ width: '100%', margin: 0 }}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <SelectComponent name={'year'} handler={handlerSearch} options={optionYears} onChange={(val) => inputSearchChange('year', val)} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <TextFieldComponent name={'text'} handler={handlerSearch} onChange={(val) => inputSearchChange('text', val)} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <DatePickerComponent name={'startAt'} handler={handlerSearch} onChange={(val) => inputSearchChange('startAt', val)} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <DatePickerComponent name={'endAt'} handler={handlerSearch} onChange={(val) => inputSearchChange('endAt', val)} />
                    </Grid>
                </Grid>
            </Box>
        )
    }

    const getAction = (row) => {
        return (
            <Box>
                <Tooltip title={''} placement="left">
                    <IconButton onClick={() => { props.history.push(getRoutePathname('institution_health_financial_management_billing_formation', { id: row.id })) }}>
                        <LibraryBooksIcon style={{ color: '#5E6E82' }} />
                    </IconButton>
                </Tooltip>
            </Box>
        )
    }

    const GetActionMore = () => {
        return (
            <Box style={{ display: 'flex', gap: 12 }}>
                <ButtonComponent
                    color={"#5E6E82"}
                    onClick={() => setIsOpen(!isOpen)}
                    label={<><FilterListIcon style={{ color: '#FFFFFF' }} /> Filtre</>}
                />
            </Box>
        )
    }

    React.useEffect(() => {
        dispatch(LinearProgressMainLayoutDeactivate());
        setBreadcrumbs({
            title: 'Facturation formation',
            context: 'Portail',
            path: getRoutePathname('institution_health_home'),
            description: 'Liste des sessions formation.',
            html: <GetActionMore />
        });

        setReadyContent(true);
    }, [isOpen]);

    React.useEffect(() => {
        if (screenReducer.screen) {
            setIsScreen(screenReducer.screen === 'LG' || screenReducer.screen === 'XL');
        }
    }, [screenReducer.screen]);

    React.useEffect(() => {
        // Year
        let yearsTMP = [];
        yearsTMP.push({ label: (currentYear + 1), value: (currentYear + 1) });
        for (let i = currentYear; i > currentYear - 1; i--) {
            yearsTMP.push({ label: i, value: i });
        }
        setOptionYears(yearsTMP);
    }, [])

    return (
        <Box className={classes.styleContentView}>
            <DrawerMUI
                variant="permanent"
                className={`${classes.drawer} ${isOpen ? classes.drawerOpen : classes.drawerClose}`}
                style={!isScreen ? { position: 'absolute', zIndex: 10 } : {}}
                open={isOpen}>
                {filter()}
            </DrawerMUI>
            <ContentViewComponent loading={!isReadyContent} breadcrumbs={breadcrumbs} {...props}>
                <Box className={classes.TableComponent} style={{ width: '95%' }}>
                    <TableComponent
                        id={'institution_health_financial_management_billing_formation_list'}
                        title={
                            <h1 style={{ margin: 0, fontSize: 17, display: 'flex', alignItems: 'center', gap: 5}}>
                                <DateRangeIcon style={{ width: 20, height: 20 }} />
                                Sessions
                            </h1>
                        }
                        search={false}
                        columns={columns}
                        promiseServerData={(resolve, options) => {
                            let filters = getFilterValues(options);
                            Api.get({
                                route: 'institution_health_financial_management_billing_session_formation_list',
                                data: { ...filters, organismId: selectOrganismReducer.organism.value }
                            },
                                (response) => {
                                    if (response.status === 200) {
                                        let data = response.data.data;
                                        for (let index in data) {
                                            data[index].action = getAction(data[index]);
                                            let color = colors.black;
                                            const registrationRatio = (data[index].countAvailableRegistration / data[index].countRegistration); 
                                            if (registrationRatio >= 1) {
                                                color = colors.green;
                                            } else if (registrationRatio <= 0) {
                                                color = colors.orange;
                                            } else {
                                                color = colors.purple;
                                            }
                                            {/*data[index].countRegistration = <Tooltip title={'Inscriptions actives / Inscriptions totales'} placement="bottom">
                                                <span style={{color: color}}>{data[index].countAvailableRegistration}/{data[index].countRegistration}</span>
                                            </Tooltip>*/}
                                            data[index].formationName = <span style={{ width: 270, display: 'block' }}>{data[index].formationName}</span>;
                                        }
                                        resolve(data, response.data.count);
                                    } else resolve([]);
                                });
                        }}
                    />
                </Box>
            </ContentViewComponent>
        </Box>
    );
}

const drawerOpenWidth = 340;
const drawerCloseWidth = 0;
const useStyles = makeStyles(style => ({
    TableComponent: {
        height: '90%',
        width: '95%',
        margin: 'auto',
        '& >div': {
            height: '100%',
            '& .MuiPaper-root': {
                height: '100%',
                display: 'grid',
                gridTemplateRows: ' min-content min-content auto min-content min-content',
                overflow: 'hidden',
                '& div .MuiTable-root': {
                    position: 'absolute'
                }
            }
        },
        '& .MuiTableCell-head:last-child': {
            width: 145,
            textAlign: 'center'
        }
    },
    styleContentView: {
        height: '100%',
        display: 'flex',
        flexDirection: 'row-reverse',
        '& .ContentView-root': {
            height: '100%',
            display: 'grid',
            gridTemplateRows: 'min-content auto',
            width: '100%',
            '& .Breadcrumbs-root:nth-of-type(1)': {
                margin: 0,
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
                height: 'auto',
                '& div:nth-of-type(2)': {
                    margin: '0 0 0 auto',
                }
            }
        }
    },
    drawer: {
        width: drawerOpenWidth,
        height: '100%',
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxShadow: '-1px 0px 2px rgba(0,0,0,0.2)',
        background: '#FFFFFF',
        overflowX: 'hidden',
        zIndex: 1,
        boxSizing: 'border-box',
        '& .MuiDrawer-paperAnchorDockedLeft': {
            border: 0
        },
        '& .MuiDrawer-paper': {
            height: '100%',
            boxSizing: 'border-box',
            width: '100%',
            overflowX: 'hidden',
            position: 'relative'
        }
    },
    drawerOpen: {
        transition: style.transitions.create('width', {
            easing: style.transitions.easing.sharp,
            duration: style.transitions.duration.enteringScreen,
        }),
        width: drawerOpenWidth
    },
    drawerClose: {
        transition: style.transitions.create('width', {
            easing: style.transitions.easing.sharp,
            duration: style.transitions.duration.leavingScreen,
        }),
        width: drawerCloseWidth
    },
}))