import { Dialog, DialogContent, Grid, IconButton, makeStyles, Table, TableCell, TableContainer, TableBody, TableRow, Tooltip } from "@material-ui/core";
import { CloudDownload, Send, Create, MoneyOff, GetApp } from '@material-ui/icons';
import ButtonComponent from "../../../../../../Component/ButtonComponent";
import React from "react";
import Api from "../../../../../../Api";
import { dispatch } from "../../../../../../App";
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from "../../../../../../Action/LinearProgressMainLayoutAction";
import { SnackbarOpen } from "../../../../../../Action/SnackbarAction";
import Invoice from "./Invoice";
import Credit from "./Credit";
import SweetAlert from 'sweetalert-react/lib/SweetAlert';
import moment from "moment";

const Card = (props) => {
    const data = props.data;
    const isCancel = props.data.cancelAt ? true : false;
    const [showConfirmPaid, setShowConfirmPaid] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const savePaid = (checked) => {
        dispatch(LinearProgressMainLayoutActivate());
        setLoading(true);
        Api.post({
            route: 'institution_health_financial_management_billing_session_formation_invoice_paid',
            data: { isPaid: checked },
            params: { sessionFormation: props.match.params.id, id: data.id }
        }, (response) => {
            setLoading(false);
            dispatch(LinearProgressMainLayoutDeactivate());
            if (response.status === 200) {
                props.reloadList();
                dispatch(
                    SnackbarOpen({
                        text: response.data.message,
                        variant: 'success',
                        duration: 10000
                    })
                );
            } else if (response.status === 400) {
                dispatch(
                    SnackbarOpen({
                        text: response.error.message,
                        variant: 'warning',
                    })
                );
            } else {
                dispatch(
                    SnackbarOpen({
                        text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                        variant: 'error',
                    })
                );
            }
        });
    }

    const download = (id) => {
        const isCredit = props.type === 'avoir'
        window.open(Api.route({
            route: 'institution_health_financial_management_billing_session_formation_download',
            params: { sessionFormation: props.match.params.id, id: id },
            data: { isInvoice: !isCredit }
        }), '_blank');
    }

    const description = () => {
        const descriptions = {
            1: { text: 'Avoir', background: 'rgb(156, 39, 176)' },
            2: { text: 'Facture | Annuler', background: '#DD4E4E' },
            3: { text: 'Facture | Non payé', background: '#E6A731' },
            4: { text: 'Facture | payé', background: '#28a745' },
            default: { text: '', background: '' }
        };

        const conditions = [
            { condition: (props.type == 'avoir'), type: 1 },
            { condition: (!data.isPaid && isCancel), type: 2 },
            { condition: !data.isPaid, type: 3 },
            { condition: data.isPaid, type: 4 },
        ];

        const descriptionType = conditions.find(cond => cond.condition)?.type || 'default';
        const { text: description, background } = descriptions[descriptionType];

        return (
            <p style={{ fontSize: 10, textAlign: 'center', padding: '5px 10px', width: 'fit-content', margin: '0', color: '#FFF', fontWeight: 'bold', background: background }}>
                {description}
            </p>
        )
    }

    return (
        <>
            <TableCell align="center">
                {description()}
            </TableCell>
            <TableCell style={{ fontSize: 10 }}>{data.label}</TableCell>
            <TableCell style={{ fontSize: 10 }}>{moment(data.createdAt).format('ll')}</TableCell>
            <TableCell align="center" style={{ display: 'flex', gap: 5, alignItems: 'center', justifyContent: 'end' }}>
                {((props.type == 'facture') && !isCancel) && <ButtonComponent
                    onClick={() => { setShowConfirmPaid(true) }}
                    style={{ width: 'fit-content', boxShadow: 'none' }}
                    color={!data.isPaid ? '#DD4E4E' : '#28a745'}
                    label={<span style={{ fontSize: 9 }}>{!data.isPaid ? <>Valider le paiement</> : <>Paiement reçu</>}</span>}
                    disabled={loading || isCancel}
                />}
                <Tooltip title={(props.type === 'avoir') ? 'Télécharger l\'avoir' : 'Télécharger'} arrow placement='left'>
                    <IconButton
                        style={{ padding: 5, background: '#6D7C8E', color: '#FFF', borderRadius: 5 }}
                        onClick={() => download(data.id)}
                    >
                        <GetApp style={{ fontSize: 16 }} />
                    </IconButton>
                </Tooltip>

                {(!data.isPaid && !isCancel && (props.type == 'facture')) &&
                    <>
                        <Tooltip title={'Générer un avoir'} arrow placement='left'>
                            <IconButton
                                style={{ padding: 5, background: '#6D7C8E', color: '#FFF', borderRadius: 5 }}
                                onClick={() => { props?.setOpenCredit(true); props?.setInvoiceId(data.id); props?.setLabelCredit(data.label) }}
                            >
                                <MoneyOff style={{ fontSize: 16 }} />
                            </IconButton>
                        </Tooltip>
                    </>
                }
            </TableCell>
            <SweetAlert
                show={showConfirmPaid}
                title={'Paiement'}
                text={
                    data.isPaid ?
                        `Êtes-vous sûr de vouloir annuler le paiement ?` :
                        `Êtes-vous sûr de vouloir valider le paiement ?`
                }
                type={'warning'}
                cancelButtonText={'Non'}
                confirmButtonText={'Oui'}
                showCancelButton={true}
                onConfirm={() => {
                    setShowConfirmPaid(false);
                    savePaid(!data.isPaid)
                }}
                onCancel={() => setShowConfirmPaid(false)}
            />
        </>
    )
}

const Action = (props) => {
    const classes = useStyles();
    const [buttons, setButtons] = React.useState([]);
    const [openInvoice, setOpenInvoice] = React.useState(false);
    const [openCredit, setOpenCredit] = React.useState(false);
    const [sessionFormation, setSessionFormation] = React.useState();
    const [invoiceId, setInvoiceId] = React.useState(null);
    const [labelCredit, setLabelCredit] = React.useState('');
    const [propsReady, setPropsReady] = React.useState(false);
    const [registrations, setRegistrations] = React.useState([]);

    const downloadParticipationCertificate = () => {
        const url = Api.route({
            route: 'institution_health_financial_management_billing_session_formation_download_participation_certificate',
            params: { sessionFormation: props.match.params.id },
        });
        window.open(url);
    }

    const downloadKitParticipation = () => {
        const url = Api.route({
            route: 'institution_health_financial_management_billing_session_formation_download_kit_participation',
            params: { sessionFormation: props.match.params.id },
        });
        window.open(url);
    }

    const sendParticipationCertificate = () => {
        dispatch(LinearProgressMainLayoutActivate());
        Api.get({
            route: 'institution_health_financial_management_billing_session_formation_send_participation_certificate',
            params: { sessionFormation: props.match.params.id },
        },
            (response) => {
                dispatch(LinearProgressMainLayoutDeactivate());
                if (response.status === 200) {
                    dispatch(
                        SnackbarOpen({
                            text: 'Attestations envoyées.',
                            variant: 'success',
                        })
                    );
                }
                else {
                    dispatch(
                        SnackbarOpen({
                            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
            });
    }

    React.useEffect(() => {
        setRegistrations(props.billing.registrations.filter(
            (registration) => ![2, 3, 8, 9, 10, 11, 12].includes(registration.state.value)
        ))
    }, [props.billing.registrations]);

    React.useEffect(() => {
        if (Object.keys(props.sessionFormation).length > 0) {
            setPropsReady(true);
            setSessionFormation(props.sessionFormation);
            setButtons([
                {
                    label: 'Éditer une facture', action: () => setOpenInvoice(true),
                    color: '#4caf50', icon: <Create className={classes.icon} />,
                    disabled: props.billing.isLock || registrations.length === 0
                },
            ])
        }
    }, [props.sessionFormation, props.billing.isLock, registrations]);

    return (
        <>
            <Dialog open={props.open} className={classes.dialogStyle} onClose={() => { props.onClick(false) }} maxWidth={'xl'} style={{ textAlign: 'center' }}>
                <DialogContent style={{ padding: 8 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: 'grid', gridTemplateRows: 'max-content auto' }}>
                            <h2 style={{ margin: 0, textAlign: 'left', color: '#6D7C8E', fontSize: 20, display: 'grid', gridTemplateColumns: 'max-content auto max-content', alignItems: 'center', gap: 10 }}>
                                Action - OPCO
                                <hr style={{ margin: 0, opacity: 0.5 }} />
                                <ButtonComponent color={'#5E6E82'} style={{ width: 'fit-content', margin: '0 0 0 auto' }} label={'Fermer'} onClick={() => props.onClick(false)} />
                            </h2>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: 'grid', gridTemplateRows: 'auto', background: '#6D7C8E1A', border: '#6D7C8E24 1px solid', borderRadius: 5, minHeight: '500px', overflow: 'auto' }}>
                            <TableContainer style={{ position: 'relative' }}>
                                {(sessionFormation && sessionFormation.invoicesAndCredits && sessionFormation.invoicesAndCredits['opco'].length > 0) ?
                                    <Table sx={{ minWidth: 650 }}>
                                        <TableBody>
                                            {sessionFormation.invoicesAndCredits['opco'].map((ic, i) => {
                                                return (
                                                    <>
                                                        <TableRow key={i}><Card data={ic} type={'facture'} setOpenCredit={setOpenCredit} setInvoiceId={setInvoiceId} setLabelCredit={setLabelCredit} {...props} /></TableRow>
                                                        {ic?.credits?.map((credit, i) => {
                                                            return (
                                                                <TableRow key={i}><Card data={credit} type={'avoir'} {...props} /></TableRow>
                                                            );
                                                        })}
                                                    </>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                    : <p style={{ textAlign: 'center', fontSize: 12, position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%,-50%)' }}>Aucune <b>[Facture / Avoir]</b>.</p>
                                }
                            </TableContainer>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ gap: 10, display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
                            {buttons?.map((btn, i) => {
                                return <div className={classes.root} key={i}>
                                    <ButtonComponent
                                        label={
                                            <div className={classes.btn}>
                                                {btn.icon}
                                                {btn.label}
                                            </div>
                                        }
                                        color={btn.disabled ? '#959595' : btn.color}
                                        className={classes.button}
                                        onClick={btn.action}
                                        disabled={btn.disabled}
                                    />
                                </div>
                            })}
                        </Grid>
                        <Credit open={openCredit} sessionFormation={sessionFormation} invoiceId={invoiceId} labelId={labelCredit} reloadList={() => props.reloadList()} close={() => { setOpenCredit(false); setInvoiceId(null) }} />
                        <Invoice sessionFormation={sessionFormation} billing={props.billing} registrations={registrations} open={openInvoice} reloadList={() => props.reloadList()} close={() => setOpenInvoice(false)} />
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    )
}

const useStyles = makeStyles({
    loaderActivity: {
        '& > div':
        {
            margin: 'auto',
            padding: 10,
            width: '50px !important',
            height: '50px !important'
        }
    },
    loader: {
        textAlign: 'center',
        width: '100%',
        height: '100%',
        display: 'flex',
        marginTop: 50,
        marginBottom: 50
    },
    loaderContent: {
        margin: 'auto',
        background: '#FFF', border: '5px double rgb(206, 200, 200)', borderRadius: 100
    },
    chip: {
        color: '#fff',
        fontSize: 13,
        padding: '3px 10px',
        display: 'block',
        width: 'fit-content'
    },
    tabStyle: {
        position: 'relative',
        height: '100%',
        '& span': {
            margin: 0,
        },
        '& >div': {
            height: '100%',
            '& .MuiPaper-root': {
                boxShadow: 'none',
                borderRadius: 0,
                height: '100%',
            }
        }
    },
    dialogStyle: {
        '& .MuiPaper-root': {
            width: 1000,
            padding: 17
        },
        '& .MuiDialogContent-root': {
            overflowY: 'initial'
        },
        '& #deposit': {
            textAlign: 'center'
        }
    },
    btnContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center'
    },
    btn: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        fontSize: 13,
        gap: 5
    },
    button: {
        width: '100%',
        height: 'auto',
        textTransform: 'none',
        borderRadius: 5,
    },
    icon: {
        fontSize: '17px',
    },
});


export default Action;