import React from 'react';
import { Box, Fade, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, makeStyles } from "@material-ui/core";
import { Dialog, DialogActions, DialogContent } from "@material-ui/core";
import ButtonComponent from "../../../../../../Component/ButtonComponent";
import Api from "../../../../../../Api";
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from "../../../../../../Action/LinearProgressMainLayoutAction";
import { formHandlerInit } from '../../../../../../Tool/FormHandlerCommon';
import DatePickerComponent from '../../../../../../Component/DatePickerComponent';
import TextFieldComponent from '../../../../../../Component/TextFieldComponent';
import { dispatch } from '../../../../../../App';
import { SnackbarOpen } from '../../../../../../Action/SnackbarAction';
import SweetAlert from 'sweetalert-react/lib/SweetAlert';
import SelectComponent from '../../../../../../Component/SelectComponent';
import moment from 'moment';

function Invoice(props) {
    const classes = useStyles();
    const registration = props.registration;
    const [loading, setLoading] = React.useState(true);
    const [destinationType, setDestinationType] = React.useState(1);
    const [credit, setCredit] = React.useState(0);
    const [creditBalance, setCreditBalance] = React.useState(0);
    const [totalTTC, setTotalTTC] = React.useState(0);
    const [tva, setTva] = React.useState(props?.sessionFormation?.tva ?? 0);
    const [price, setPrice] = React.useState(0);
    const [amount, setAmount] = React.useState([]);
    const [errorCredit, setErrorCredit] = React.useState({
        isError: false,
        text: ''
    });
    const [form, setForm] = React.useState({
        invoiceAt: {
            name: 'invoiceAt',
            label: 'Date de la facture',
            textHelper: 'Saisissez la date de la facture',
            type: 'date',
            defaultValue: props?.sessionFormation.invoiceAt,
            options: { validation: ['required', 'date'] }
        },
        amount: {
            name: 'amount',
            label: 'Total HT',
            textHelper: '',
            type: 'float',
            defaultValue: '',
            value: '0',
            options: { validation: ['required'] },
        },
        credit: {
            name: 'credit',
            label: 'Solde',
            type: 'float',
            defaultValue: '0',
            options: {}
        },
        tva: {
            name: 'tva',
            label: 'TVA(%)',
            type: 'float',
            defaultValue: props?.sessionFormation?.tva ?? 0,
            options: {}
        },
        comment: {
            name: 'comment',
            label: 'Commentaire',
            textHelper: '',
            type: 'text',
            defaultValue: '',
            options: {},
        },
        destinationType: {
            name: 'destinationType',
            label: 'Destination',
            textHelper: '',
            type: 'integer',
            defaultValue: 1,
            value: 1,
            options: {},
        },
        destinationFor: {
            name: 'destinationFor',
            label: 'Autre',
            textHelper: '',
            type: 'text',
            defaultValue: '',
            options: {},
        }
    });
    const handler = formHandlerInit(form, setForm);
    React.useEffect(handler.start, []);

    const save = () => {
        if (handler.checkError()) {
            console.log('Error');
        }
        else {
            dispatch(LinearProgressMainLayoutActivate());
            handler.setFormLoading(true);
            setLoading(true);
            let data = {
                ...handler.getData(),
                amountTTC: totalTTC?.toFixed(2),
                remainTTC: (totalTTC?.toFixed(2) - credit).toFixed(2),
                price: amount,
                credit: destinationType === 1 ? handler?.form.credit.value : '',
                contractId: registration.contractId,
                financeTypeId: registration.financeTypeId,
                registrationId: registration.id
            }
            Api.post({
                route: 'institution_health_financial_management_billing_session_formation_invoice_rest',
                params: { sessionFormation: props.sessionFormation.id },
                data: data
            },
                (response) => {
                    dispatch(LinearProgressMainLayoutDeactivate());
                    handler.setFormLoading(false);
                    setLoading(false);

                    if (response.status === 200) {
                        props.close();
                        props.reloadList();
                        dispatch(
                            SnackbarOpen({
                                text: 'Session formation facturée.',
                                variant: 'success',
                            })
                        );
                    }
                    else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
        }
    };

    const selectPrice = (type, restAmount) => {
        setAmount((val) => {
            const exists = val.some(item => item.type === type);

            if (exists && val.length > 1) {
                const filteredAmount = val.filter(item => item.type !== type);
                return filteredAmount;
            }

            if (exists) return val;

            const newAmount = [...val, { type, restAmount }];
            return newAmount;
        });
    };

    React.useEffect(() => {
        if (destinationType == 1) {
            handler.setFormLoading(true);
            setLoading(true);
            Api.get({
                route: 'institution_health_financial_management_billing_session_formation_invoice_credit_balance',
                params: { sessionFormation: props.sessionFormation.id },
                data: {
                    destinationType: destinationType,
                    registrationId: registration.id,
                    contractId: registration.contractId
                }
            },
                (response) => {
                    handler.setFormLoading(false);
                    setLoading(false);
                    setCreditBalance(response?.data?.creditBalance ?? 0);
                });
        }
    }, [destinationType]);

    React.useEffect(() => {
        if ((credit > creditBalance) && creditBalance) {
            setErrorCredit({
                isError: true,
                text: <>Attention, vous avez dépassé le montant disponibles. Le montant est actuellement à <b>{creditBalance} €</b></>
            });
        } else if (parseFloat(credit) > totalTTC?.toFixed(2)) {
            setErrorCredit({
                isError: true,
                text: <>Attention, le montant actuel de la facture est de <b>{totalTTC?.toFixed(2)} €</b>. Par conséquent, le montant utilisé sera identique.</>
            });
        } else setErrorCredit({
            isError: false,
            text: ``
        });
    }, [credit, registration, totalTTC, creditBalance]);

    React.useEffect(() => {
        setAmount((val) => {
            const exists = val.some(item => (item.type === 'support' || item.type === 'remain'));
            if (!exists) {
                return [...val, registration?.restAmount?.remain == 0 ? {
                    type: 'support',
                    restAmount: registration?.restAmount?.support ?? 0
                } : {
                    type: 'remain',
                    restAmount: registration?.restAmount?.remain ?? 0
                }]
            } else return val
        });
    }, [registration?.restAmount]);

    React.useEffect(() => {
        if (destinationType != 1) { handler.setValue('credit', '0'); setCredit(0) }
    }, [destinationType]);

    React.useEffect(() => {
        const tvaPrice = parseFloat(price) * (parseFloat(tva) / 100);
        let ttc = parseFloat(price) + tvaPrice;
        setTotalTTC(ttc)
    }, [price, tva]);

    React.useEffect(() => {
        const total = amount.reduce((total, item) => total + item.restAmount, 0);
        setPrice(total ?? 0);
        handler.setValue('amount', total);
    }, [amount])


    return (
        <>
            <Dialog open={props.open} className={classes.dialogStyle} maxWidth={'xl'} onClose={props.close}>
                <Box style={{ display: 'grid', gap: 10, gridTemplateColumns: 'auto', padding: 10 }}>
                    <Box style={{ display: 'flex', flexDirection: 'column' }}>
                        <Box style={{ fontSize: 15, color: '#5E6E82', }}>
                            <Box style={{ display: 'grid', gridTemplateColumns: 'max-content auto', alignItems: 'center', gap: 10 }}>
                                <h1 style={{ fontSize: 20, margin: 0 }}>Facture - {registration.firstname} {registration.lastname}</h1>
                                <hr style={{ margin: 0, opacity: 0.5 }} />
                            </Box>
                            <Box style={{ padding: '10px 20px' }}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <DatePickerComponent name={'invoiceAt'} handler={handler} />
                                        <Box style={{ display: 'grid', gridTemplateColumns: 'auto max-content auto', alignItems: 'center', gap: 10 }}>
                                            <hr style={{ margin: 0, opacity: 0.5 }} />
                                            <h1 style={{ fontSize: 13, margin: 0 }}>À destination de</h1>
                                            <hr style={{ margin: 0, opacity: 0.5 }} />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <DestinationAction registration={registration} handler={handler} onChange={(value) => setDestinationType(value)} {...props} />
                                    </Grid>
                                    {((creditBalance > 0) && (destinationType == 1)) &&
                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                            <span style={{ fontSize: 15, margin: 0, fontWeight: 'bold', display: 'block', textAlign: 'center' }}>Solde utiliser : {(creditBalance - parseFloat(credit)).toFixed(2)} €</span>
                                            <span style={{ display: 'block', fontSize: 10, textAlign: 'center' }}>(Solde initial : {creditBalance} €)</span>
                                            <div style={{ padding: '10px 0 0 0' }}>
                                                <span style={{ margin: 'auto' }} >{<TextFieldComponent name={`credit`} onChange={(value) => { setCredit(value) }} handler={handler} disabled={creditBalance === 0} />}</span>
                                                <span style={{ display: 'block', fontSize: 10, textAlign: 'center', color: '#E05059', margin: 'auto', padding: '10px 0 0' }}>{errorCredit.text}</span>
                                            </div>
                                        </Grid>
                                    }
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        {(registration?.restAmount?.remain != 0 && registration?.restAmount?.support != 0) &&
                                            <div className={classes.selectPrice}>
                                                <p style={amount.some(item => item?.type === 'remain') ? { background: '#6D7C8E', color: '#FFF' } : { background: 'transparent', color: '#6D7C8E' }}
                                                    onClick={() => {
                                                        selectPrice('remain', registration?.restAmount?.remain);
                                                    }}>
                                                    <span style={{ display: 'block', fontSize: 11 }}>Reste</span>
                                                    <span>{registration?.restAmount?.remain} €</span>
                                                </p>
                                                <p style={amount.some(item => item?.type === 'support') ? { background: '#6D7C8E', color: '#FFF' } : { background: '#FFF', color: '#6D7C8E' }}
                                                    onClick={() => {
                                                        selectPrice('support', registration?.restAmount?.support);
                                                    }}>
                                                    <span style={{ display: 'block', fontSize: 11 }}>Financement</span>
                                                    <span>{registration?.restAmount?.support} €</span>
                                                </p>
                                            </div>
                                        }
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={9} lg={9}>
                                        <TextFieldComponent name={`amount`} handler={handler} disabled={true} onChange={(price) => setPrice(price == '' ? 0 : price)} />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={3} lg={3}>
                                        <TextFieldComponent name={`tva`} handler={handler} disabled={true} onChange={(tva) => setTva(tva == '' ? 0 : tva)} />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <Box style={{ display: 'grid', gridTemplateColumns: 'auto max-content auto', alignItems: 'center', gap: 10 }}>
                                            <hr style={{ margin: 0, opacity: 0.5 }} />
                                            <h1 style={{ fontSize: 13, margin: 0 }}>Total TTC : {(totalTTC?.toFixed(2) - credit).toFixed(2)}€</h1>
                                            <hr style={{ margin: 0, opacity: 0.5 }} />
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                        <DialogActions>
                            <ButtonComponent disabled={loading} onClick={props.close} color={'#5E6E82'} label={'Annuler'} />
                            <ButtonComponent disabled={loading || errorCredit.isError} onClick={save} label={'Générer la facture'} />
                        </DialogActions>
                    </Box>
                </Box>
            </Dialog>
        </>
    );
}

const DestinationAction = (props) => {
    const classes = useStyles();
    const handler = props.handler;
    const [destination, setDestination] = React.useState(1);

    const option = [
        { value: 1, label: 'Établissement' },
        { value: 2, label: 'L\'apprenant' },
        //{ value: 3, label: 'Autre' }
    ];

    const onChange = (value) => {
        setDestination(value);
        props.onChange(value);
        handler.form[`destinationFor`].options = value == 3 ? { validation: ['required'] } : {}
    }

    React.useEffect(() => {
        setDestination(1);
        handler.setValue(`destinationType`, 1)
        props.onChange(1)
    }, [props.open]);

    return (
        <Box className={classes.destinationRoot}>
            {destination != 3 ? <p style={{ textAlign: 'center', fontWeight: 'bold' }}>{option[destination - 1].label}</p> : <TextFieldComponent name={`destinationFor`} handler={handler} />}
            <div className={!props.disabled ? 'select-root' : 'selectOff-root'}>
                <SelectComponent name={`destinationType`} handler={handler} options={option} onChange={(value) => { onChange(value) }} disabled={props.disabled} />
            </div>
        </Box>
    )
}

const useStyles = makeStyles({
    th: {
        fontWeight: 900,
        fontSize: 15,
        padding: 12,
        textAlign: 'center',
        color: '#5E6E82',
    },
    td: {
        fontSize: 13,
        padding: 5,
        textAlign: 'center',
        color: '#5E6E82',
        '& .MuiFormHelperText-root': {
            display: 'none'
        }
    },
    selectPrice: {
        display: 'flex',
        alignItems: 'center',
        gap: 5,
        margin: '0 0 10px 0',
        '& p': {
            textAlign: 'center',
            width: '100%',
            margin: 0,
            background: 'transparent',
            border: '2px solid #6D7C8E54',
            borderRadius: 5,
            color: '#6D7C8E',
            cursor: 'pointer',
            padding: '5px 0px',
            transition: 'all .5s',
            '&:hover': {
                background: '#6D7C8E54 !important',
                color: '#6D7C8E !important',
            }
        }
    },
    destinationRoot: {
        display: 'grid',
        gridTemplateColumns: 'auto max-content',
        '& p': {
            padding: '7.1px 14px',
            fontSize: 13,
            margin: 0,
            border: '1px solid rgba(0, 0, 0, 0.23)',
            borderRadius: '5px 0px 0px 5px'
        },
        '& .MuiFormHelperText-root': {
            display: 'none'
        },
        '& .MuiTextField-root': {
            width: 170
        },
        '& fieldset': {
            borderRadius: '5px 0px 0px 5px'
        },
        '& .selectOff-root': {
            '& label, legend': {
                display: 'none'
            },
            '& select': {
                background: 'transparent !important',
                color: '#5E6E82',
                opacity: 0
            },
            '& fieldset': {
                top: 0,
                borderRadius: '0px 5px 5px 0px',
                borderColor: '#5E6E823B !important'
            },
            '& .MuiFormControl-root': {
                width: 38
            },
        },
        '& .select-root': {
            '& label, legend': {
                display: 'none'
            },
            '& select': {
                background: 'transparent !important',
                color: '#5E6E82',
                opacity: 0
            },
            '& fieldset': {
                top: 0,
                borderRadius: '0px 5px 5px 0px',
                borderColor: '#5E6E82 !important'
            },
            '& .MuiFormControl-root': {
                width: 38
            },
        },
    },
    dialogStyle: {
        '& .MuiPaper-root': {
            width: 500,
            padding: 17
        },
        '& .MuiDialogContent-root': {
            overflowY: 'initial'
        },
        '& #deposit': {
            textAlign: 'center'
        }
    },
    containerCheckbox: {
        display: 'flex',
        justifyContent: 'space-around'
    },
    priceRoot: {
        width: '100%',
        color: '#6D7C8E',
        textAlign: 'center',
        '& tbody': {
            position: 'relative',
            '& td:nth-of-type(1)': {
                background: '#E5E7EA',
                padding: 10,
                fontSize: 13,
                transition: 'all .4s',
            },
            '&  tr:first-of-type td:nth-of-type(1)': {
                borderRadius: '10px 10px 0 0',
            },
            '& tr:last-of-type td:nth-of-type(1)': {
                borderRadius: '0 0 10px 10px',
            },
            '& td': {
                '& span': {
                    fontWeight: 'bold',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: 3,
                }
            }
        },
        '& select': {
            padding: '8.4px 14px !important',
        },
        '& .MuiFormControl-root': {
            '& label, legend, .MuiFormHelperText-root': {
                display: 'none'
            },
            '& input': {
                padding: 8,
                textAlign: 'center'
            },
            '& fieldset': {
                top: 0
            }
        },
    },
});

export default Invoice;
