import { Box, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Fade, IconButton, makeStyles, Tooltip } from "@material-ui/core";
import ButtonComponent from "../../../../../Component/ButtonComponent";
import UseAnimations from "react-useanimations";
import activity from "react-useanimations/lib/activity";
import DescriptionIcon from '@material-ui/icons/Description';
import { getRoutePathname } from "../../../../../Config/Route";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Api from "../../../../../Api";
import { TableReload } from "../../../../../Action/TableAction";
import { dispatch } from "../../../../../App";
import { SnackbarOpen } from "../../../../../Action/SnackbarAction";
import SweetAlert from 'sweetalert-react/lib/SweetAlert';
import ListAltIcon from '@material-ui/icons/ListAlt';
import CancelIcon from '@material-ui/icons/Cancel';
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from "../../../../../Action/LinearProgressMainLayoutAction";
import React from "react";

const ViewInvoiceDialogComponent = (props) => {
    const classes = useStyles();
    const [data, setData] = React.useState([]);
    const [dataInvoice, setDataInvoice] = React.useState([]);
    const [credits, setCredits] = React.useState({
        id: '',
        dataCredits: []
    });
    const [showConfirm, setShowConfirm] = React.useState(false);
    const [typeShow, setTypeShow] = React.useState({});
    const [actionRow, setActionRow] = React.useState({});
    const [isArchive, setIsArchive] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const save = (id, name, checked, setIsLoading) => {
        dispatch(LinearProgressMainLayoutActivate());
        setIsLoading(true);
        Api.post({
            route: 'institution_health_financial_management_billing_institution_invoice_paid',
            data: { [name]: checked },
            params: { institution: props.match.params.institution, invoiceId: id }
        }, (response) => {
            setIsLoading(false);
            dispatch(LinearProgressMainLayoutDeactivate());
            if (response.status === 201) {
                dispatch(TableReload(
                    props.isPersonal ?
                        'institution_health_financial_management_billing_institution_invoice_personal_list' :
                        'institution_health_financial_management_billing_institution_invoice_list'
                ));
                dispatch(
                    SnackbarOpen({
                        text: response.data.message,
                        variant: 'success',
                        duration: 10000
                    })
                );
            } else if (response.status === 400) {
                dispatch(
                    SnackbarOpen({
                        text: response.error.message,
                        variant: 'warning',
                        duration: 10000
                    })
                );
            } else {
                dispatch(
                    SnackbarOpen({
                        text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                        variant: 'error',
                    })
                );
            }
        });
    }

    const download = (type, id) => {
        const url = Api.route({
            route: 'institution_health_financial_management_billing_institution_download',
            data: { type: type },
            params: { institution: props.match.params.institution, id: id },
        });
        window.open(url, '_blank');
    };

    React.useEffect(() => setData(props.row ?? []), [props.row])

    React.useEffect(() => {
        if ((data.invoices && data.invoices.length != 0) || (data.canceledInvoices && data.canceledInvoices.length != 0)) {
            const newData = [...data.canceledInvoices, ...data.invoices];
            setCredits({
                id: newData[0]?.id,
                label: newData[0]?.label,
                dataCredits: [...newData[0]?.credits, ...newData[0]?.internalCredits]
            });
            setDataInvoice(newData)
        }
    }, [data.invoices, data.canceledInvoices])

    return (
        <Dialog open={props.dialog} className={classes.dialogStyle} onClose={() => { props.setDialog(false); setIsArchive(false) }} maxWidth={'xl'}>
            <DialogContent style={{ display: 'grid', gridTemplateColumns: 'auto max-content', gap: 30, padding: 0, overflow: 'initial' }}>
                <Box style={{ display: 'grid', gridTemplateRows: 'max-content max-content auto max-content', color: '#5E6E82', gap: 10, background: '#FFF', borderRadius: 4, padding: 17, boxShadow: '#0000006e 0px 0px 10px' }}>
                    <h1 style={{ margin: 0, fontSize: 20 }}>Contrat</h1>
                    <Box style={{ display: 'grid', gridTemplateColumns: 'auto max-content max-content', gap: 5, alignItems: 'end' }}>
                        <p style={{ margin: 0, fontSize: 12 }}>
                            Référence du contrat : {data.label}<br />
                            Nombre total d'inscrits : {data.countRegistration}<br />
                            Net à payer : {data.amountTtc} €<br />
                        </p>
                        {!(data.cancelAt && props.isPersonal) && <ButtonComponent style={{ height: 'fit-content', }} label={'Accéder au contrat'}
                            onClick={() => { props.history.push(getRoutePathname(props.isPersonal ? 'institution_health_financial_management_billing_institution_invoice_personal' : 'institution_health_financial_management_billing_institution_invoice', { institution: props.match.params.institution, id: data.id })) }} />}
                    </Box>
                    <Box>
                        <Box style={{ display: 'grid', gridTemplateRows: 'auto', color: '#5E6E82', height: '100%' }}>
                            <Box style={{ background: 'rgb(239, 243, 244)', borderRadius: 10, border: '1px solid rgba(163, 173, 183, 0.36)', position: 'relative', overflow: 'hidden auto' }}>
                                {props.loading ? (
                                    <Fade in={true} timeout={1000}>
                                        <Box className={classes.loader} style={{ width: '100%', height: '100%', display: 'flex' }}>
                                            <Box className={classes.loaderContent}>
                                                <Box className={classes.loaderActivity}>
                                                    <UseAnimations animation={activity} size={70} />
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Fade>
                                ) : (dataInvoice && dataInvoice.length !== 0) ? (
                                    <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', padding: '25px 0px', display: 'flex', flexDirection: 'column', gap: 20 }}>
                                        {dataInvoice.map((invoice, index) => {
                                            const descriptions = {
                                                1: { text: 'Facture | Annuler', color: '#DD4E4E' },
                                                2: { text: 'Facture | payé', color: '#28a745' },
                                                default: { text: 'Facture | Non payé', color: '#E6A731' }
                                            };
                                            const conditions = [
                                                { condition: invoice.cancelAt, type: 1 },
                                                { condition: invoice.isPaid, type: 2 }
                                            ];

                                            const descriptionType = conditions.find(cond => cond.condition)?.type || 'default';
                                            const { text: stateLabel, color } = descriptions[descriptionType];

                                            return (
                                                <Fade key={index} in={true} {...{ timeout: 800 }}>
                                                    <Box style={{ width: '95%', margin: 'auto' }}>
                                                        <Box style={{ background: '#FFF', width: '100%', borderRadius: 10, border: '1px solid #A3ADB7', display: 'grid', gridTemplateRows: 'max-content auto max-content', overflow: 'hidden' }}>
                                                            <Box style={{ borderBottom: '1px solid #A3ADB7', display: 'grid', gridTemplateColumns: 'auto max-content max-content max-content max-content' }}>
                                                                <div style={{ padding: '10px 30px' }}>
                                                                    <p style={{ margin: 0, fontSize: 12 }}>Référence de la facturation</p>
                                                                    <p style={{ margin: 0, fontWeight: 'bold', fontSize: 14 }}>{`${invoice.label}`}</p>
                                                                </div>
                                                                <div style={{ background: '#5E6E82', height: '100%', justifyContent: 'center', display: 'flex' }}>
                                                                    <Tooltip title={'Télécharger la facture'} placement="left">
                                                                        <IconButton onClick={() => { download(2, invoice.id) }} style={{ borderRadius: 0 }}>
                                                                            <CloudDownloadIcon style={{ color: '#FFF' }} />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </div>
                                                                {((invoice.credits && invoice.credits.length != 0) || (invoice.internalCredits && invoice.internalCredits.length != 0)) &&
                                                                    <div style={{ background: invoice.id ? 'rgb(156, 39, 176)' : '#5E6E82B2', height: '100%', justifyContent: 'center', display: 'flex' }}>
                                                                        <Tooltip title={'Voir les facture / avoir'} placement="left">
                                                                            <IconButton onClick={() => {
                                                                                setCredits({
                                                                                    id: invoice.id, label: invoice.label, dataCredits: [...invoice.credits, ...invoice.internalCredits]
                                                                                })
                                                                            }} style={{ borderRadius: 0 }}>
                                                                                <ListAltIcon style={{ color: '#FFF' }} />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    </div>
                                                                }
                                                            </Box>
                                                            <Box style={{ padding: '10px 30px' }}>
                                                                <Box style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                                                                    <Box style={{ fontSize: 12, display: 'flex', alignItems: 'center', gap: 2 }}>
                                                                        {/*<span>Paiement</span>
                                                                        <GetActionCheck data={invoice} label={'Payement'} name={'isPaid'} otherName={'countConvention'} save={save} loading={invoice.cancelAt ? true : false} />*/}
                                                                        <span><b>Net à payer :</b> {invoice.amountTtc ?? 0} €</span>
                                                                    </Box>
                                                                    <p style={{ margin: '0 0 0 auto', fontSize: 12 }}>État - <span style={{ color: color, width: 'max-content', fontWeight: 'bold' }}>{stateLabel}</span></p>
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </Fade>
                                            )
                                        })}
                                    </div>
                                ) : (
                                    <p className={classes.error} style={{ transform: 'translate(-50%,-50%)' }}>Aucune facturations</p>
                                )}
                            </Box>
                        </Box>
                    </Box>
                    <ButtonComponent color={'#5E6E82'} label={'Fermer'} onClick={() => { props.setDialog(false); setIsArchive(false) }} />
                </Box>
                <Box style={{ width: 400, display: 'grid', gridTemplateRows: 'max-content auto', color: '#5E6E82', gap: 10, background: '#FFF', borderRadius: 4, padding: 17, boxShadow: '#0000006e 0px 0px 10px' }}>
                    <h1 style={{ margin: 0, fontSize: 20, lineHeight: '15px' }}>Avoir<br /> <span style={{ fontSize: 12, fontWeight: 'initial' }}>Facturation : {credits.label ?? '-'}</span></h1>
                    <Box>
                        <Box style={{ display: 'grid', gridTemplateRows: 'auto', color: '#5E6E82', height: '100%' }}>
                            <Box style={{ background: 'rgb(239, 243, 244)', borderRadius: 10, border: '1px solid rgba(163, 173, 183, 0.36)', position: 'relative', overflow: 'hidden auto' }}>
                                {props.loading ? (
                                    <Fade in={true} timeout={1000}>
                                        <Box className={classes.loader} style={{ width: '100%', height: '100%', display: 'flex' }}>
                                            <Box className={classes.loaderContent}>
                                                <Box className={classes.loaderActivity}>
                                                    <UseAnimations animation={activity} size={70} />
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Fade>
                                ) : credits.dataCredits && credits.dataCredits.length != 0 ? (
                                    <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', padding: '25px 0px', display: 'flex', flexDirection: 'column', gap: 20 }}>
                                        {credits.dataCredits.map((credit, index) => {
                                            return (
                                                <Fade key={index} in={true} {...{ timeout: 800 }}>
                                                    <Box style={{ width: '90%', margin: 'auto' }}>
                                                        <Box style={{ background: '#FFF', width: '100%', borderRadius: 10, border: '1px solid #A3ADB7', display: 'grid', gridTemplateRows: 'max-content auto max-content', overflow: 'hidden' }}>
                                                            <Box style={{ display: 'grid', gridTemplateColumns: 'auto max-content' }}>
                                                                <div style={{ padding: '10px 30px' }}>
                                                                    <p style={{ margin: 0, fontSize: 12 }}>Référence de l'avoir</p>
                                                                    <p style={{ margin: 0, fontWeight: 'bold', fontSize: 14 }}>{`${credit.label ?? ''}`}</p>
                                                                </div>
                                                                <div style={{ background: '#5E6E82', height: '100%', justifyContent: 'center', display: 'flex' }}>
                                                                    <Tooltip title={'Télécharger l\'avoir'} placement="left">
                                                                        <IconButton onClick={() => { download(3, credit.id) }}>
                                                                            <CloudDownloadIcon style={{ color: '#FFF' }} />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </div>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </Fade>
                                            )
                                        })}
                                    </div>
                                ) : (
                                    <p className={classes.error} style={{ transform: 'translate(-50%,-50%)' }}>Aucun avoir</p>
                                )}
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <SweetAlert
                    show={showConfirm}
                    title={typeShow.title}
                    text={actionRow.label + typeShow.text}
                    type={'warning'}
                    cancelButtonText={'Non'}
                    confirmButtonText={'Oui'}
                    showCancelButton={true}
                    onConfirm={() => {
                        setShowConfirm(false);
                        dispatch(LinearProgressMainLayoutActivate());
                        setLoading(true)
                        Api.delete({
                            route: 'institution_health_financial_management_billing_institution_invoice_cancel',
                            params: { institution: props.match.params.institution, invoiceId: actionRow.id }
                        },
                            (response) => {
                                dispatch(LinearProgressMainLayoutDeactivate());
                                setLoading(false)
                                if (response.status === 201) {
                                    dispatch(TableReload('institution_health_financial_management_billing_institution_invoice_list'))
                                    dispatch(
                                        SnackbarOpen({
                                            text: 'Facture annuler.',
                                            variant: 'success',
                                        })
                                    );
                                } else if (response.status === 400) {
                                    dispatch(
                                        SnackbarOpen({
                                            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                            variant: 'error',
                                        })
                                    );
                                } else if (response.status === 409) {
                                    dispatch(
                                        SnackbarOpen({
                                            text: response.error.message,
                                            variant: 'warning',
                                        })
                                    );
                                } else {
                                    dispatch(
                                        SnackbarOpen({
                                            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                            variant: 'error',
                                        })
                                    );
                                }
                            });
                    }}
                    onCancel={() => setShowConfirm(false)}
                />
            </DialogContent>
        </Dialog >
    )
}

const GetActionCheck = (props = { data: [], label: '', name: '', otherName: '', save: () => { }, loading: false }) => {
    const [isChecked, setIsChecked] = React.useState(props.data[props.name]);
    const [isEvent, setIsEvent] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [isShow, setIsShow] = React.useState(false);
    const [text, setText] = React.useState('');

    const handleCheckboxChange = (event) => {
        if (!props.data[props.name] && props.data[props.otherName]) {
            setText(`Êtes-vous sûr de vouloir cocher cette case ? Si c'est le cas, toutes les inscriptions seront activées.`);
            setIsEvent(event.target.checked);
            setIsShow(true);
        } else if (props.data[props.name] && props.data[props.otherName]) {
            setText(`Êtes-vous sûr de vouloir décocher cette case ? Si c'est le cas, toutes les inscriptions seront désactivées.`);
            setIsEvent(event.target.checked);
            setIsShow(true);
        } else {
            setIsChecked(event.target.checked);
            props.save(props.data.id, props.name, event.target.checked, setIsLoading);
        }
    };

    React.useEffect(() => {
        setIsChecked(props.data[props.name])
    }, [props.data[props.name]]);

    return (
        <>
            <Checkbox
                key={`${props.data.id}-${props.name}`}
                checked={isChecked}
                disabled={props.loading || isLoading}
                style={{ padding: 0 }}
                onChange={(value) => handleCheckboxChange(value)}
            />
            <SweetAlert
                show={isShow}
                title={props.label}
                text={text}
                type={'warning'}
                showCancelButton={true}
                cancelButtonText={'Non'}
                confirmButtonText={'Oui'}
                onConfirm={() => {
                    setIsShow(false);
                    setIsChecked(isEvent);
                    props.save(props.data.id, props.name, isEvent, setIsLoading);
                }}
                onCancel={() => {
                    setIsShow(false);
                }}
            />
        </>
    );
}

const useStyles = makeStyles(style => ({
    loaderActivity: {
        '& > div': {
            margin: 'auto',
            padding: 10,
            width: '50px !important',
            height: '50px !important'
        }
    },
    loader: {
        textAlign: 'center'
    },
    loaderContent: {
        margin: 'auto',
        background: '#FFF',
        border: '5px double rgb(206, 200, 200)',
        borderRadius: 100
    },
    dialogStyle: {
        '& .MuiPaper-root': {
            width: 1400,
            padding: 0,
            margin: 0,
            height: '90%',
            maxHeight: 590,
            background: 'transparent',
            boxShadow: 'none',
            overflow: 'initial'
        },
        '& .MuiDialogContent-root': {
            overflowY: 'initial'
        },
        '& #depositAmount': {
        }
    },
    error: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
        fontSize: 30,
        fontWeight: 'bold',
        opacity: 0.3,
        margin: 0,
        width: '100%',
        textAlign: 'center',
    },
}))

export default ViewInvoiceDialogComponent;