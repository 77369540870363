import { Box } from "@material-ui/core";
import ButtonComponent from "../../../../../Component/ButtonComponent";
import SweetAlert from 'sweetalert-react/lib/SweetAlert';
import React from "react";

const ViewInvoiceComponent = (props) => {
    const institution = props.institution;
    const contractHistorique = institution.contractHistorique;
    const checkList = props.checkList;
    const [showConfirm, setShowConfirm] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);

    const allSaveList = () => {
        setShowConfirm(true);
    };

    const handleConfirm = () => {
        setShowConfirm(false);
        props.setCheckList((prev) => {
            const newPrev = prev
                .filter(item => !item.deletedAt)
                .map(item => ({
                    ...item,
                    isConvention: !props.isAllTrue,
                }));
            props.saveConvention(newPrev, null, null, setIsLoading);
            return newPrev;
        });
        props.setSaveConfirm(true);
    };

    return (
        <Box style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
            <Box style={{ display: 'grid', gridTemplateColumns: 'max-content max-content', alignItems: 'center', gap: 10, justifyContent: 'space-between' }}>
                <h1 style={{ fontSize: 15, margin: 0 }}>Référence:<br /><span style={{ fontSize: 13, fontWeight: 'initial' }} >{contractHistorique.firstInvoice.label ?? institution.label}</span></h1>
                <ButtonComponent
                    onClick={() => { props.setShowConfirmPaid(true)}}
                    color={!contractHistorique.firstInvoice.isPaid ? '#DD4E4E' : '#28a745'}
                    label={!contractHistorique.firstInvoice.isPaid ? <>Valider le paiement</> : <>Paiement reçu</>}
                    disabled={props.loading || contractHistorique.firstInvoice.cancelAt || props.isInvoiceEdit}
                />
            </Box>
            <Box style={{ display: 'grid', gridTemplateColumns: 'max-content auto', alignItems: 'center', gap: 10, justifyContent: 'space-between' }}>
                <h1 style={{ fontSize: 15, margin: 0 }}>Convention valider : <span style={{ fontWeight: 'initial' }} >{`${props.trueCount} / ${props.dataList.length}`}</span></h1>
                <ButtonComponent
                    onClick={allSaveList} label={!props.isAllTrue ? 'Cocher les conventions' : 'Décocher les conventions'}
                    color={"#5E6E82"}
                    disabled={props.loading || contractHistorique.firstInvoice.cancelAt || props.isInvoiceEdit}
                />
            </Box>
            <SweetAlert
                show={showConfirm}
                title={!props.isAllTrue ? 'Tout cocher' : 'Tout décocher'}
                text={`Êtes-vous sûr de vouloir ${!props.isAllTrue ? 'cocher' : 'décocher'} toutes les conventions ?
                ${contractHistorique.firstInvoice.isPaid && checkList.filter(item => !item.isConvention).length != 0 ? 'Si c\'est le cas, toutes les inscriptions seront activées' :
                        contractHistorique.firstInvoice.isPaid && checkList.filter(item => item.isConvention).length != 0 ? 'Si c\'est le cas, toutes les inscriptions seront désactivées' : ''}`}
                type={'warning'}
                cancelButtonText={'Non'}
                confirmButtonText={'Oui'}
                showCancelButton={true}
                onConfirm={handleConfirm}
                onCancel={() => setShowConfirm(false)}
            />
        </Box >
    )
}
export default ViewInvoiceComponent;