import { Box, IconButton, Slide, Tooltip, makeStyles } from '@material-ui/core';
import TextFieldComponent from "../../../../../Component/TextFieldComponent";
import React from "react";
import SelectComponent from '../../../../../Component/SelectComponent';
import { formHandlerInit } from '../../../../../Tool/FormHandlerCommon';
import BackspaceIcon from '@material-ui/icons/Backspace';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import EditIcon from '@material-ui/icons/Edit';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import moment from 'moment';
import Api from '../../../../../Api';

const CardPersonalComponent = (props) => {
    const classes = useStyles();
    const data = props.data;
    const institution = props.institution;
    const creditBalance = institution.creditBalance ?? 0;
    const isInvoice = props.isBilled;
    const handler = props.handler;
    const handlerDeposit = props.handlerDeposit;
    const [isOpenDescription, setIsOpenDescription] = React.useState(false);
    const [textComment, setTextComment] = React.useState('');
    const [values, setValues] = React.useState('');

    const description = (value) => {
        const input = value ? value.trim() : '';
        if (input === '') {
            setTextComment('Aucune description...');
        } else {
            setTextComment(input);
        }
    }

    const option = [
        { value: 1, label: 'Établissement' },
        { value: 2, label: 'L\'apprenant' }
    ];

    const calculateHT = (price, discount, isPercentage) => {
        const amount = isPercentage ? price.toFixed(2) - (price.toFixed(2) * discount / 100) : price.toFixed(2) - discount;
        return amount < 0 ? 0 : amount;
    };

    const getInitialValues = () => {
        const fields = [
            { key: 'inscriptionFeeHt', discountKey: 'inscriptionDiscount' },
            { key: 'educationalFeeHt', discountKey: 'educationalDiscount' },
            { key: 'tva' },
            { key: 'credit' },
        ];
        let values = { inscriptionFee: 0, remainHt: 0, educationalFee: 0, tvaAmount: 0, totalHT: 0, amountTtc: 0, credit: 0, depositAmount: 0 };

        fields.forEach(field => {
            const { key, discountKey } = field;
            const value = parseFloat(handler.form[`${data.id}-${key}`]?.value || 0);

            if (discountKey) {
                const discount = parseFloat(handler.form[`${data.id}-${discountKey}`]?.value || 0);
                const isPercentage = handler.form[`${data.id}-${discountKey}`]?.isPercentage;
                const amountHT = calculateHT(value, discount, isPercentage);
                if (key === 'inscriptionFeeHt') {
                    values.inscriptionFee += amountHT;
                } else if (key === 'educationalFeeHt') {
                    values.educationalFee += amountHT;
                }
            } else {
                if (key === 'tva') {
                    values.tvaAmount = value
                } else if (key === 'credit') {
                    values.credit = value;
                }
            }
        });
        values.remainHt = values.inscriptionFee + values.educationalFee;
        values.tvaAmount = values.remainHt * (values.tvaAmount / 100);
        values.amountTtc = values.remainHt + values.tvaAmount;
        values.totalHT = values.amountTtc - values.credit;
        values.depositAmount = (institution.depositPaid && isInvoice) ? institution.depositPaid : (values.totalHT * 30) / 100;

        if (isInvoice) {
            values.totalHT = values.totalHT - values.depositAmount;
        }

        handler.form[`${data.id}-remainHt`] = { value: values.remainHt.toFixed(2), registrationId: data.id };
        handler.form[`${data.id}-remainTtc`] = { value: values.totalHT.toFixed(2), registrationId: data.id };
        handler.form[`${data.id}-tvaAmount`] = { value: values.tvaAmount.toFixed(2), registrationId: data.id };
        handler.form[`${data.id}-amountTtc`] = { value: values.amountTtc.toFixed(2), registrationId: data.id };
        handler.form[`${data.id}-deposit`] = { value: values.depositAmount.toFixed(2), registrationId: data.id };
        handlerDeposit.setValue('depositAmount', values.depositAmount.toFixed(2));
        return values;
    };

    const onChange = (value, name, type) => {
        if (type === 'unit') {
            handler.form[name].isPercentage = value == 1;
        } else {
            handler.form[name].value = value;
        }
        const differences = findDifferences(handler.form, institution.registrationsBilled);
        props.setIsActiveBilled(differences.length != 0);
        setValues(getInitialValues());
    };

    const download = () => {
        const url = Api.route({
            route: 'institution_health_financial_management_billing_institution_download',
            data: { type: 1 },
            params: { institution: props.match.params.institution, id: props.match.params.id },
        });
        window.open(url, '_blank');
    };

    React.useEffect(() => {
        setValues(getInitialValues());
        if (handler.form[`${data.id}-destinationType`]) {
            props.setDestinationType(handler.form[`${data.id}-destinationType`].value);
        }
        if (institution.registrationsBilled) {
            institution.registrationsBilled[0][`tva`] = institution.tva;
            institution.registrationsBilled[0][`destinationType`] = institution.destinationType ?? 1;

            const differences = findDifferences(handler.form, institution.registrationsBilled);
            props.setIsActiveBilled(differences.length != 0);
        }
    }, [handler.form]);

    React.useEffect(() => {
        description(data.comment);
    }, [data]);

    return (
        <>
            <Box style={{ borderBottom: '1px solid #A3ADB7', padding: '10px 20px', display: 'grid', gridTemplateColumns: 'auto max-content', alignItems: 'center', gap: 30 }}>
                <p style={{ margin: 0, fontWeight: 'bold', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>Formation : {data.formationName ?? '-'}</p>
                {!(props.loading || props.match.params.id) &&
                    <Box onClick={() => { props.onClick(data) }} style={{ borderRadius: 5, cursor: 'pointer' }}>
                        <BackspaceIcon style={{ margin: 'auto', display: 'block', color: '#E05059', fontSize: 25 }} />
                    </Box>
                }
            </Box>
            <Box className={classes.DestinationRoot}>
                <p style={{ margin: 0, fontWeight: 'bold' }}>À destination de :</p>
                {handler.form[`${data.id}-destinationType`] && <SelectComponent name={`${data.id}-destinationType`} handler={handler} options={option} onChange={(value) => props.setDestinationType(value)} disabled={props.loading || institution.isTreated} />}
            </Box>
            <Box style={{ padding: '10px 20px' }}>
                <p style={{ margin: 0, fontSize: 12 }}>Nom/Prénom</p>
                <p style={{ margin: 0, fontWeight: 'bold' }}>{`${data.prospectName ?? '-'}`}</p>
            </Box>
            <Box style={{ padding: '0px 20px' }}>
                <p style={{ margin: 0, fontSize: 12, fontWeight: 'bold', }}>Session : {moment(data.sessionStartAt.date).format('ll')} au {moment(data.sessionEndAt.date).format('ll')}</p>
            </Box>
            <Box style={{ padding: '0px 20px 20px', height: '100%' }}>
                <table className={classes.priceRoot}>
                    <thead>
                        <tr>
                            <th></th>
                            <th style={{ textAlign: 'center' }}>P.U. HT</th>
                            <th style={{ textAlign: 'center' }}>REMISE</th>
                            <th style={{ textAlign: 'center' }}>Montant HT</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><p style={{ fontWeight: 'bold' }}>Frais d’inscription</p></td>
                            <td><div><span style={{ margin: 'auto', }}>{handler.form[`${data.id}-inscriptionFeeHt`] && <TextFieldComponent name={`${data.id}-inscriptionFeeHt`} handler={handler} onChange={(value) => onChange(value, `${data.id}-inscriptionFeeHt`, null)} disabled={props.loading || props.isValidInvoice} />}</span></div></td>
                            <td><div><span style={{ margin: 'auto', transform: 'translate(10%)' }}>{handler.form[`${data.id}-inscriptionDiscount`] && <SelectDiscount name={`${data.id}-inscriptionDiscount`} handler={handler} onChange={onChange} disabled={props.loading || props.isValidInvoice} />}</span></div></td>
                            <td><span>{values.inscriptionFee?.toFixed(2)}</span></td>
                        </tr>
                        <tr>
                            <td><p style={{ fontWeight: 'bold' }}>Coût pédagogique</p></td>
                            <td><div><span style={{ margin: 'auto', }} >{handler.form[`${data.id}-educationalFeeHt`] && <TextFieldComponent name={`${data.id}-educationalFeeHt`} onChange={(value) => onChange(value, `${data.id}-educationalFeeHt`, null)} handler={handler} disabled={props.loading || props.isValidInvoice} />}</span></div></td>
                            <td><div><span style={{ margin: 'auto', transform: 'translate(10%)' }}>{handler.form[`${data.id}-educationalDiscount`] && <SelectDiscount name={`${data.id}-educationalDiscount`} handler={handler} onChange={onChange} disabled={props.loading || props.isValidInvoice} />}</span></div></td>
                            <td><span>{values.educationalFee?.toFixed(2)}</span></td>
                        </tr>
                    </tbody>
                </table>
            </Box>
            <Box style={{ padding: '0px 20px 10px', margin: 'auto 0 0 0', display: 'grid', gridTemplateColumns: 'auto max-content', alignItems: 'center', gap: 30 }}>
                <div>
                    <p style={{ margin: 0, fontWeight: 'bold', }}>{!isInvoice ? '30% d\'acompte à la signature du devis' : 'Acompte à la signature du devis'} </p>
                    <p style={{ margin: 0, fontSize: 14 }}>
                        Acompte de <b>{values.depositAmount?.toFixed(2)}€</b>
                        {isInvoice && <> versé le <b> {moment(institution.estimateAcceptedAt).format('ll')} </b></>}
                    </p>
                </div>
                {/*isInvoice &&
                    <Tooltip title={'Télécharger l\'acompte'}>
                        <IconButton style={{ padding: 4, padding: '5px 20px', background: '#6D7C8E', borderRadius: 6 }} onClick={() => { download(institution?.firstInvoice?.label) }}>
                            <CloudDownloadIcon style={{ fontSize: 20, color: '#FFF' }} />
                        </IconButton>
                    </Tooltip>
                */}
            </Box>
            <Box style={{ borderTop: '1px solid #A3ADB7', padding: '0px 20px 20px' }}>
                <table className={classes.priceTotalRoot}>
                    <tbody>
                        <tr>
                            <td><span>Total HT</span></td>
                            <td><span>{values.remainHt?.toFixed(2)}€</span></td>
                        </tr>
                        <tr>
                            <td><span style={{ margin: 'auto', width: 135 }}>Total TVA {handler.form[`${data.id}-tva`] && <TextFieldComponent name={`${data.id}-tva`} onChange={(value) => onChange(value, `${data.id}-tva`, null)} handler={handler} disabled={true} />}%</span></td>
                            <td><span>{values.tvaAmount?.toFixed(2)}€</span></td>
                        </tr>
                        {(institution.firstInvoice && institution.firstInvoice.credit) &&
                            <tr>
                                <td><span>Crédit utiliser</span></td>
                                <td><span>{institution.firstInvoice.credit?.toFixed(2) ?? 0} €</span></td>
                            </tr>
                        }
                        <tr>
                            {!data.paymentData ?
                                <>
                                    <td><span>Net à payer</span></td>
                                    <td><span>{values.totalHT?.toFixed(2)}€</span></td>
                                </> :
                                <td style={{ background: 'transparent', padding: 0 }} colSpan={2}>
                                    <table className={classes.priceRoot2} cellSpacing={0} cellPadding={0}>
                                        <thead>
                                            <tr>
                                                <th style={{ textAlign: 'center' }}>Contrat</th>
                                                <th style={{ textAlign: 'center' }}>à facturer</th>
                                                <th style={{ textAlign: 'center' }}>facturé</th>
                                                <th style={{ textAlign: 'center' }}>Reste à payer</th>
                                                <th style={{ textAlign: 'center' }}>Payé</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <span style={{ color: data.paymentData.indicators.contract?.color }}>{data.paymentData.contract} €</span>
                                                </td>
                                                <td>
                                                    <span style={{ color: data.paymentData.indicators.toBill?.color }}>{data.paymentData.toBill} €</span>
                                                </td>
                                                <td>
                                                    <span style={{ color: data.paymentData.indicators.billed?.color }}>{data.paymentData.billed} €</span>
                                                </td>
                                                <td>
                                                    <span style={{ color: data.paymentData.indicators.rest?.color }}>{data.paymentData.rest} €</span>
                                                </td>
                                                <td>
                                                    <span style={{ color: data.paymentData.indicators.paid?.color }}>{data.paymentData.paid} €</span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            }
                        </tr>
                    </tbody>
                </table>
            </Box>
            <Box style={{ borderTop: '1px solid #A3ADB7', background: '#E5E7EA', display: 'grid', gridTemplateColumns: 'auto max-content', alignItems: 'center' }}>
                <Box style={{ overflow: 'hidden', padding: '10px 20px', }}>
                    <p style={{ margin: 0, fontSize: 12, fontWeight: 'bold' }}>Description remise :</p>
                    <p style={{ margin: 0, textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>{textComment}</p>
                </Box>

                <Box style={{ position: 'relative', height: '100%' }}>
                    <Slide direction="left" in={isOpenDescription} mountOnEnter unmountOnExit>
                        <Box className={classes.DescriptionPopup}>
                            {handler.form[`${data.id}-comment`] && <TextFieldComponent name={`${data.id}-comment`} handler={handler} onChange={(value) => { description(value) }} disabled={props.loading || props.isValidInvoice} multiline />}
                        </Box>
                    </Slide>
                    <Tooltip title={'Modifier'} placement="left">
                        <span className={classes.IconButtonRoot} style={{ background: '#5E6E82C4' }}>
                            <IconButton onClick={() => { setIsOpenDescription(!isOpenDescription) }} >
                                {!isOpenDescription ?
                                    <EditIcon style={{ color: '#FFF' }} /> :
                                    <HighlightOffIcon style={{ color: '#FFF' }} />
                                }
                            </IconButton>
                        </span>
                    </Tooltip>
                </Box>
            </Box>
        </>
    )
}

const SelectDiscount = (props) => {
    const [form, setForm] = React.useState({
        unit: {
            name: 'unit',
            label: 'unité',
            type: 'integer',
            defaultValue: props.handler.form[props.name].isPercentage ? 1 : 2,
            options: {}
        }
    });
    const handler = formHandlerInit(form, setForm);
    const optionUnit = [
        { value: 1, label: '%' },
        { value: 2, label: '€' }
    ];

    React.useEffect(() => {
        handler.form.unit.value = props.handler.form[props.name].isPercentage ? 1 : 2;
    }, [props.handler.form[props.name].isPercentage]);

    React.useEffect(handler.start, []);
    return (
        <>
            <TextFieldComponent name={props.name} handler={props.handler} onChange={(value) => { props.onChange && props.onChange(value, props.name, null) }} disabled={props.disabled} />
            <div className={'select-root'}>
                <SelectComponent name={'unit'} handler={handler} options={optionUnit} onChange={(value) => { props.onChange && props.onChange(value, props.name, 'unit') }} disabled={props.disabled} />
            </div>
        </>
    )
}

const findDifferences = (obj1, obj2) => {
    const diffs = [];
    if (obj2) {
        // Convertir obj2 en un objet clé-valeur basé sur les ID pour un accès rapide
        const obj2Data = obj2.reduce((acc, item) => {
            acc[item.id] = item;
            return acc;
        }, {});

        // Extraire tous les IDs uniques dans obj1
        const ids = Array.from(new Set(
            Object.keys(obj1).map((key) => key.split("-")[0])
        ));

        ids.forEach((id) => {
            // Vérifier si l'id existe dans obj2Data
            if (!obj2Data[id]) return;

            // Itération sur les clés de obj1 qui commencent par l'ID actuel
            for (let key in obj1) {
                if (obj1.hasOwnProperty(key) && key.startsWith(id)) {
                    const obj1Field = obj1[key];

                    // Ignorer les champs sans type
                    if (!obj1Field.type) continue;

                    const genericFieldName = key.replace(`${id}-`, "");
                    let obj2Value = obj2Data[id][genericFieldName];

                    // Traitement des valeurs en fonction du type
                    let obj1Value, unit1, unit2;
                    if (obj1Field.hasOwnProperty("isPercentage")) {
                        // Déterminer l'unité en fonction de isPercentage
                        const isEuroComparison = !obj1Field.isPercentage;
                        unit1 = isEuroComparison ? "€" : "%";
                        unit2 = typeof obj2Value === "string" && obj2Value.includes('%') ? "%" : "€";

                        // Extraire les valeurs numériques
                        const numericObj1Value = parseFloat(obj1Field.value) || 0;
                        const numericObj2Value = parseFloat(obj2Value) || 0;

                        // Formater les valeurs avec les unités pour affichage
                        obj1Value = numericObj1Value + unit1;
                        obj2Value = numericObj2Value + unit2;

                        // Comparer les valeurs numériques et les unités
                        if (numericObj1Value !== numericObj2Value || unit1 !== unit2) {
                            diffs.push({
                                field: key,
                                valueInObj1: obj1Value,
                                valueInObj2: obj2Value,
                                unitChanged: unit1 !== unit2
                            });
                        }
                    } else if (obj1Field.type === "float" || obj1Field.type === "integer") {
                        obj1Value = parseFloat(obj1Field.value) || 0;
                        obj2Value = parseFloat(obj2Value) || 0;

                        if (obj1Value !== obj2Value) {
                            diffs.push({
                                field: key,
                                valueInObj1: obj1Value,
                                valueInObj2: obj2Value,
                            });
                        }
                    } else if (obj1Field.type === "text") {
                        obj1Value = obj1Field.value || "";
                        obj2Value = obj2Value || "";

                        if (obj1Value !== obj2Value) {
                            diffs.push({
                                field: key,
                                valueInObj1: obj1Value,
                                valueInObj2: obj2Value,
                            });
                        }
                    } else {
                        obj1Value = obj1Field.value;
                        if (obj1Value !== obj2Value) {
                            diffs.push({
                                field: key,
                                valueInObj1: obj1Value,
                                valueInObj2: obj2Value,
                            });
                        }
                    }
                }
            }
        });
    }
    return diffs;
};

const useStyles = makeStyles({
    OtherRoot: {
        padding: '10px 20px',
        display: 'grid',
        gridTemplateColumns: 'max-content auto',
        gap: 10,
        alignItems: 'center',
        padding: '0px 20px',
        '& label, legend, .MuiFormHelperText-root': {
            display: 'none'
        },
        '& select': {
            background: 'transparent !important',
            color: '#5E6E82',
        },
        '& fieldset': {
            top: 0
        }
    },
    DestinationRoot: {
        padding: '10px 20px',
        display: 'grid',
        gridTemplateColumns: 'max-content auto',
        gap: 10,
        alignItems: 'center',
        '& label, legend, .MuiFormHelperText-root': {
            display: 'none'
        },
        '& select': {
            background: 'transparent !important',
            color: '#5E6E82',
        },
        '& input': {
            padding: 6,
            textAlign: 'center'
        },
        '& fieldset': {
            top: 0
        }
    },
    priceRoot: {
        width: '100%',
        '& thead': {
            position: 'relative',
            '& th:nth-of-type(1)::before': {
                width: '95%',
                height: 5,
                content: "' '",
                background: '#E5E7EA',
                position: 'absolute',
                top: 0,
                left: 0,
                borderRadius: 100,
            },
            '& th:nth-of-type(2), th:nth-of-type(3), th:nth-of-type(4)': {
                background: '#E5E7EA',
                padding: 10,
                fontSize: 13,
                transition: 'all .4s',
            },
            '& th:nth-of-type(2)': {
                borderRadius: '10px 0 0 0',
            },
            '& th:nth-of-type(4)': {
                borderRadius: '0 10px 0 0',
            },
        },
        '& tbody': {
            '& td:nth-of-type(2), td:nth-of-type(3), td:nth-of-type(4) ': {
                background: '#E5E7EA',
                padding: 10,
                fontSize: 13,
                transition: 'all .4s',
            },
            '& tr:last-of-type td:nth-of-type(2)': {
                borderRadius: '0 0 0 10px',
            },
            '& tr:last-of-type td:nth-of-type(4)': {
                borderRadius: '0 0 10px 0',
            },
            '& td': {
                '& >div, p': {
                    display: 'flex',
                    alignItems: 'center',
                    gap: 5,
                    margin: 0,
                    flexWrap: 'wrap',
                    fontSize: 15
                },
                '& span': {
                    fontWeight: 'bold',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: 3,
                }
            }
        },
        '& .MuiFormControl-root': {
            width: 60,
            '& label, legend, .MuiFormHelperText-root': {
                display: 'none'
            },
            '& select': {
                padding: 0,
                background: 'transparent !important',
                color: '#5E6E82',
            },
            '& input': {
                padding: 6,
                textAlign: 'center'
            },
            '& fieldset': {
                top: 0
            }
        },
        '& .select-root': {
            '& fieldset': {
                padding: 0,
                display: 'none'
            },
            '& .MuiFormControl-root': {
                width: 20
            },
            '& .MuiSelect-icon': {
                right: -6,
            }
        },
    },
    priceTotalRoot: {
        width: '100%',
        '& tbody': {
            '& td:nth-of-type(1),td:nth-of-type(2)': {
                background: '#E5E7EA',
                padding: 10,
                fontSize: 13,
            },
            '& tr:last-of-type td:nth-of-type(1)': {
                borderRadius: '0 0 0 10px',
            },
            '& tr:last-of-type td:nth-of-type(2)': {
                borderRadius: '0 0 10px 0',
            },
            '& td': {
                '& p': {
                    display: 'flex',
                    alignItems: 'center',
                    gap: 5,
                    margin: 0,
                    flexWrap: 'wrap',
                    fontSize: 15
                },
                '& span': {
                    display: 'grid',
                    alignItems: 'center',
                    fontWeight: 'bold',
                    justifyContent: 'center',
                    gridTemplateColumns: 'max-content auto max-content',
                    gap: 5,
                    '& label, legend, .MuiFormHelperText-root': {
                        display: 'none'
                    },
                    '& input': {
                        padding: 6,
                        textAlign: 'center'
                    },
                    '& fieldset': {
                        top: 0
                    }
                }
            }
        },
    },
    priceRoot2: {
        width: '100%',
        '& thead': {
            position: 'relative',
            '& th': {
                background: '#E5E7EA',
                padding: 10,
                fontSize: 13,
                transition: 'all .4s',
                borderBottom: '2px solid #FFF'
            },
            '& th:nth-child(n+2):nth-last-child(n+1)': {
                borderLeft: '2px solid #FFF',
            }
        },
        '& tbody': {
            '& tr td': {
                background: '#E5E7EA',
                padding: 10,
                fontSize: 13,
                transition: 'all .4s',
                borderRadius: '0 !important',
            },
            '& tr td:nth-child(n+2):nth-last-child(n+1)': {
                borderLeft: '2px solid #FFF',
            },
            '& tr:last-of-type td:nth-of-type(1)': {
                borderRadius: '0 0 0 10px !important',
            },
            '& tr:last-of-type td:last-child': {
                borderRadius: '0 0 10px 0 !important',
            },
            '& td': {
                '& >div, p': {
                    display: 'flex',
                    alignItems: 'center',
                    gap: 5,
                    margin: 0,
                    flexWrap: 'wrap',
                    fontSize: 15
                },
                '& span': {
                    fontWeight: 'bold',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: 3,
                }
            }
        }
    },
    IconButtonRoot: {
        display: 'flex',
        height: '100%',
        '& .MuiIconButton-root': {
            padding: '0 20px',
            borderRadius: 0
        }
    },
    DescriptionPopup: {
        position: 'absolute',
        bottom: 60,
        border: '1px solid rgb(163, 173, 183)',
        minWidth: 280,
        background: '#FFF',
        borderRadius: '5px',
        padding: 13,
        gap: 11,
        display: 'flex',
        flexDirection: 'column',
        right: 5,
        '& textarea': {
            overflow: 'hidden auto !important',
            minHeight: 100,
            maxHeight: 200
        }
    },
})

export default CardPersonalComponent;