export const routeInstitution = {
    // home
    institution_health_home: '/institution-health/home',

    // investor
    institution_health_investor_info: '/institution-health/investor/info',

    // administrator
    institution_health_administrator_user_list: '/institution-health/administrator/user/list',
    institution_health_administrator_user_add: '/institution-health/administrator/user/add',
    institution_health_administrator_user_get: '/institution-health/administrator/user/{id}',
    institution_health_administrator_user_edit: '/institution-health/administrator/user/{id}',
    institution_health_administrator_user_delete: '/institution-health/administrator/user/{id}',

    institution_health_administrator_session_list: '/institution-health/administrator/session/list',
    institution_health_administrator_session_add: '/institution-health/administrator/session/add',
    institution_health_administrator_session_get: '/institution-health/administrator/session/{id}',
    institution_health_administrator_session_edit: '/institution-health/administrator/session/{id}',
    institution_health_administrator_session_delete: '/institution-health/administrator/session/{id}',
    institution_health_administrator_session_formation_list: '/institution-health/administrator/session/formation/list',
    institution_health_administrator_session_session_formation_list: '/institution-health/administrator/session/{id}/session-formation/list',
    institution_health_administrator_session_formations_relation: '/institution-health/administrator/session/{id}/formations/relation',

    // content manager
    institution_health_content_manager_formation_list: '/institution-health/content-manager/formation/list',
    institution_health_content_manager_formation_list_download: '/institution-health/content-manager/formation/list/download',
    institution_health_content_manager_formation_download: '/institution-health/content-manager/formation/download/{id}',
    institution_health_content_manager_formation_add: '/institution-health/content-manager/formation/add',
    institution_health_content_manager_formation_get: '/institution-health/content-manager/formation/{id}',
    institution_health_content_manager_formation_edit: '/institution-health/content-manager/formation/{id}',
    institution_health_content_manager_formation_delete: '/institution-health/content-manager/formation/{id}',
    institution_health_content_manager_formation_andpc_list: '/institution-health/content-manager/formation/{id}/andpc/list',
    institution_health_content_manager_formation_andpc: '/institution-health/content-manager/formation/{id}/andpc',
    institution_health_content_manager_formation_fifpl_list: '/institution-health/content-manager/formation/{id}/fifpl/list',
    institution_health_content_manager_formation_fifpl: '/institution-health/content-manager/formation/{id}/fifpl',
    institution_health_content_manager_formation_cpf_list: '/institution-health/content-manager/formation/{id}/cpf/list',
    institution_health_content_manager_formation_cpf: '/institution-health/content-manager/formation/{id}/cpf',

    institution_health_content_manager_chapter_list: '/institution-health/content-manager/chapter/list',
    institution_health_content_manager_chapter_add: '/institution-health/content-manager/chapter/add',
    institution_health_content_manager_chapter_get: '/institution-health/content-manager/chapter/{id}',
    institution_health_content_manager_chapter_edit: '/institution-health/content-manager/chapter/{id}',
    institution_health_content_manager_chapter_delete: '/institution-health/content-manager/chapter/{id}',

    institution_health_content_manager_formation_formation_course_list: '/institution-health/content-manager/formation/{formation}/chapter-course/list',
    institution_health_content_manager_formation_formation_course_add: '/institution-health/content-manager/formation/{formation}/chapter-course/add',
    institution_health_content_manager_formation_formation_course_delete: '/institution-health/content-manager/formation/{formation}/chapter-course/{id}',

    institution_health_content_manager_chapter_chapter_course_list: '/institution-health/content-manager/chapter/{chapter}/chapter-course/list',
    institution_health_content_manager_chapter_chapter_course_add: '/institution-health/content-manager/chapter/{chapter}/chapter-course/add',
    institution_health_content_manager_chapter_chapter_course_get: '/institution-health/content-manager/chapter/{chapter}/chapter-course/{id}',
    institution_health_content_manager_chapter_chapter_course_edit: '/institution-health/content-manager/chapter/{chapter}/chapter-course/{id}',
    institution_health_content_manager_chapter_chapter_course_delete: '/institution-health/content-manager/chapter/{chapter}/chapter-course/{id}',

    institution_health_content_manager_formation_formation_chapter_list: '/institution-health/content-manager/formation/{formation}/formation-chapter/list',
    institution_health_content_manager_formation_formation_chapter_add: '/institution-health/content-manager/formation/{formation}/formation-chapter/add',
    institution_health_content_manager_formation_formation_chapter_order: '/institution-health/content-manager/formation/{formation}/formation-chapter/order',
    institution_health_content_manager_formation_formation_chapter_get: '/institution-health/content-manager/formation/{formation}/formation-chapter/{id}',
    institution_health_content_manager_formation_formation_chapter_edit: '/institution-health/content-manager/formation/{formation}/formation-chapter/{id}',
    institution_health_content_manager_formation_formation_chapter_delete: '/institution-health/content-manager/formation/{formation}/formation-chapter/{id}',

    institution_health_content_manager_formation_formation_chapter_course_list: '/institution-health/content-manager/formation/{formation}/chapter/{chapter}/chapter-course/list',
    institution_health_content_manager_formation_formation_chapter_course_action: '/institution-health/content-manager/formation/{formation}/chapter/{chapter}/chapter-course/action',

    institution_health_content_manager_formation_formation_question_list: '/institution-health/content-manager/formation/{formation}/formation-question/list',
    institution_health_content_manager_formation_formation_question_add: '/institution-health/content-manager/formation/{formation}/formation-question/add',
    institution_health_content_manager_formation_formation_question_action: '/institution-health/content-manager/formation/{formation}/formation-question/action',
    institution_health_content_manager_formation_formation_question_cancel: '/institution-health/content-manager/formation/{formation}/formation-question/cancel',

    institution_health_content_manager_formation_formation_question_answer_list: '/institution-health/content-manager/formation/{formation}/formation-question/{question}/question-answer/list',
    institution_health_content_manager_formation_formation_question_answer_action: '/institution-health/content-manager/formation/{formation}/formation-question/{question}/question-answer/action',
    institution_health_content_manager_formation_formation_question_comment_list: '/institution-health/content-manager/formation/{formation}/formation-question/{question}/question-comment/list',
    institution_health_content_manager_formation_formation_question_comment_action: '/institution-health/content-manager/formation/{formation}/formation-question/{question}/question-comment/action',

    institution_health_content_manager_formation_formation_question_epp_list: '/institution-health/content-manager/formation/{formation}/formation-question-epp/list',
    institution_health_content_manager_formation_formation_question_epp_add: '/institution-health/content-manager/formation/{formation}/formation-question-epp/add',
    institution_health_content_manager_formation_formation_question_epp_action: '/institution-health/content-manager/formation/{formation}/formation-question-epp/action',

    institution_health_content_manager_question_question_comment_list: '/institution-health/content-manager/question/{question}/question-comment/list',
    institution_health_content_manager_question_question_comment_add: '/institution-health/content-manager/question/{question}/question-comment/add',
    institution_health_content_manager_question_question_comment_get: '/institution-health/content-manager/question/{question}/question-comment/{id}',
    institution_health_content_manager_question_question_comment_edit: '/institution-health/content-manager/question/{question}/question-comment/{id}',
    institution_health_content_manager_question_question_comment_delete: '/institution-health/content-manager/question/{question}/question-comment/{id}',

    institution_health_content_manager_formation_formation_resource_list: '/institution-health/content-manager/formation/{formation}/formation-resource/list',
    institution_health_content_manager_formation_formation_resource_add: '/institution-health/content-manager/formation/{formation}/formation-resource/add',
    institution_health_content_manager_formation_formation_resource_order: '/institution-health/content-manager/formation/{formation}/formation-resource/order',
    institution_health_content_manager_formation_formation_resource_get: '/institution-health/content-manager/formation/{formation}/formation-resource/{id}',
    institution_health_content_manager_formation_formation_resource_edit: '/institution-health/content-manager/formation/{formation}/formation-resource/{id}',
    institution_health_content_manager_formation_formation_resource_delete: '/institution-health/content-manager/formation/{formation}/formation-resource/{id}',

    institution_health_content_manager_formation_formation_session_list: '/institution-health/content-manager/formation/{formation}/formation-session/list',
    institution_health_content_manager_formation_formation_session_add: '/institution-health/content-manager/formation/{formation}/formation-session/add',
    institution_health_content_manager_formation_formation_session_delete: '/institution-health/content-manager/formation/{formation}/formation-session/{id}',
    institution_health_content_manager_formation_formation_session_select_sessions: '/institution-health/content-manager/formation/{formation}/formation-session/select-sessions',

    institution_health_content_manager_formation_virtual_classroom_list: '/institution-health/content-manager/formation/{formation}/virtual-classroom/list',

    institution_health_content_manager_formation_unit_list: '/institution-health/content-manager/formation/{formation}/unit/list',
    institution_health_content_manager_formation_unit_get_formations_chapters: '/institution-health/content-manager/formation/{formation}/unit/get-formations-chapters',
    institution_health_content_manager_formation_unit_add: '/institution-health/content-manager/formation/{formation}/unit/add',
    institution_health_content_manager_formation_unit_edit: '/institution-health/content-manager/formation/{formation}/unit/{unit}',
    institution_health_content_manager_formation_unit_delete: '/institution-health/content-manager/formation/{formation}/unit/{unit}',

    institution_health_content_manager_chapter_formation_chapter_list: '/institution-health/content-manager/chapter/{chapter}/formation-chapter/list',
    institution_health_content_manager_course_chapter_course_list: '/institution-health/content-manager/course/{course}/chapter-course/list',

    institution_health_content_manager_virtual_classroom_list: '/institution-health/content-manager/virtual-classroom/list',
    institution_health_content_manager_virtual_classroom_add: '/institution-health/content-manager/virtual-classroom/add',
    institution_health_content_manager_virtual_classroom_get: '/institution-health/content-manager/virtual-classroom/{id}',
    institution_health_content_manager_virtual_classroom_active: '/institution-health/content-manager/virtual-classroom/active/{id}',
    institution_health_content_manager_virtual_classroom_edit: '/institution-health/content-manager/virtual-classroom/{id}',
    institution_health_content_manager_virtual_classroom_delete: '/institution-health/content-manager/virtual-classroom/{id}',

    institution_health_content_manager_virtual_classroom_period_list: '/institution-health/content-manager/virtual-classroom/period/list',
    institution_health_content_manager_virtual_classroom_period_add: '/institution-health/content-manager/virtual-classroom/period/add',
    institution_health_content_manager_virtual_classroom_period_edit: '/institution-health/content-manager/virtual-classroom/period/{id}',
    institution_health_content_manager_virtual_classroom_period_delete: '/institution-health/content-manager/virtual-classroom/period/{id}',
    institution_health_content_manager_virtual_classroom_formation_list: '/institution-health/content-manager/virtual-classroom/formation/list',
    institution_health_content_manager_virtual_classroom_unit_list: '/institution-health/content-manager/virtual-classroom/unit/list',
    institution_health_content_manager_virtual_classroom_sessions_formation_list: '/institution-health/content-manager/virtual-classroom/sessions-formation/list',
    institution_health_content_manager_virtual_classroom_delete_session_formation: '/institution-health/content-manager/virtual-classroom/{virtualClassroom}/delete-session-formation',
    institution_health_content_manager_virtual_classroom_all_sessions_formation: '/institution-health/content-manager/virtual-classroom/{virtualclassroom}/all-sessions-formation',
    institution_health_content_manager_virtual_classroom_user_list: '/institution-health/content-manager/virtual-classroom/{virtualclassroom}/user-virtual-classroom/list',

    institution_health_content_manager_course_list: '/institution-health/content-manager/course/list',
    institution_health_content_manager_course_add: '/institution-health/content-manager/course/add',
    institution_health_content_manager_course_get: '/institution-health/content-manager/course/{id}',
    institution_health_content_manager_course_edit: '/institution-health/content-manager/course/{id}',
    institution_health_content_manager_course_delete: '/institution-health/content-manager/course/{id}',

    institution_health_content_manager_resource_list: '/institution-health/content-manager/resource/list',
    institution_health_content_manager_resource_add: '/institution-health/content-manager/resource/add',
    institution_health_content_manager_resource_get: '/institution-health/content-manager/resource/{id}',
    institution_health_content_manager_resource_edit: '/institution-health/content-manager/resource/{id}',
    institution_health_content_manager_resource_delete: '/institution-health/content-manager/resource/{id}',
    institution_health_content_manager_resource_formation_resource_list: '/institution-health/content-manager/resource/{id}/formation-resource/list',

    institution_health_content_manager_resource_type_list: '/institution-health/content-manager/resource/type/list',
    institution_health_content_manager_resource_type_add: '/institution-health/content-manager/resource/type/add',
    institution_health_content_manager_resource_type_delete: '/institution-health/content-manager/resource/type/{id}',
    institution_health_content_manager_resource_type_edit: '/institution-health/content-manager/resource/type/{id}',

    institution_health_content_manager_resource_group_list: '/institution-health/content-manager/resource/group/list',
    institution_health_content_manager_resource_group_add: '/institution-health/content-manager/resource/group/add',
    institution_health_content_manager_resource_group_delete: '/institution-health/content-manager/resource/group/{id}',
    institution_health_content_manager_resource_group_edit: '/institution-health/content-manager/resource/group/{id}',

    // CRM
    institution_health_crm_institution_list: '/institution-health/crm/institution/list',
    institution_health_crm_institution_add: '/institution-health/crm/institution/add',
    institution_health_crm_institution_edit: '/institution-health/crm/institution/{id}',
    institution_health_crm_institution_delete: '/institution-health/crm/institution/{id}',
    institution_health_crm_institution_get: '/institution-health/crm/institution/{id}',
    institution_health_crm_institution_manager: '/institution-health/crm/institution/{id}/institution-manager',
    institution_health_crm_institution_info: '/institution-health/crm/institution/{id}/institution-info',
    institution_health_crm_institution_assign_me: '/institution-health/crm/institution/{id}/commercial-attribution',
    institution_health_crm_institution_pole_add: '/institution-health/crm/institution/{institution}/pole/add',
    institution_health_crm_institution_pole_edit: '/institution-health/crm/institution/{institution}/pole/{id}',
    institution_health_crm_institution_pole_delete: '/institution-health/crm/institution/{institution}/pole/{id}',

    institution_health_crm_institution_group_list: '/institution-health/crm/institution-group/list',
    institution_health_crm_institution_group_add: '/institution-health/crm/institution-group/add',
    institution_health_crm_institution_group_edit: '/institution-health/crm/institution-group/{id}',
    institution_health_crm_institution_group_delete: '/institution-health/crm/institution-group/{id}',
    institution_health_crm_institution_group_get: '/institution-health/crm/institution-group/{id}',
    institution_health_crm_institution_group_add_institutions: '/institution-health/crm/institution-group/{id}/add-institutions',
    institution_health_crm_institution_group_remove_institutions: '/institution-health/crm/institution/{id}/groupless',

    institution_health_crm_group_discount_list: '/institution-health/crm/institution-group/{institutionGroup}/group-discount/list',
    institution_health_crm_group_discount_add: '/institution-health/crm/institution-group/{institutionGroup}/group-discount/add',
    institution_health_crm_group_discount_edit: '/institution-health/crm/institution-group/{institutionGroup}/group-discount/{id}',
    institution_health_crm_group_discount_delete: '/institution-health/crm/institution-group/{institutionGroup}/group-discount/{id}',
    institution_health_crm_group_discount_get: '/institution-health/crm/institution-group/{institutionGroup}/group-discount/{id}',

    institution_health_crm_institution_employee_list: '/institution-health/crm/institution/{institution}/employee/list',
    institution_health_crm_institution_employee_get: '/institution-health/crm/institution/{institution}/employee/{id}',
    institution_health_crm_institution_employee_add: '/institution-health/crm/institution/{institution}/employee/add',
    institution_health_crm_institution_employee_edit: '/institution-health/crm/institution/{institution}/employee/{id}',
    institution_health_crm_institution_employee_delete: '/institution-health/crm/institution/{institution}/employee/{id}',
    institution_health_crm_institution_employee_end_function: '/institution-health/crm/institution/{institution}/employee/{id}/end-function',

    institution_health_crm_prospect_list: '/institution-health/crm/prospect/list',
    institution_health_crm_prospect_check: '/institution-health/crm/prospect/organism/{organism}/check/{emailIPS}',
    institution_health_crm_prospect_add: '/institution-health/crm/prospect/add',
    institution_health_crm_prospect_fusion: '/institution-health/crm/prospect/{id}/fusion',
    institution_health_crm_prospect_get: '/institution-health/crm/prospect/{id}',
    institution_health_crm_prospect_edit: '/institution-health/crm/prospect/{id}',
    institution_health_crm_prospect_delete: '/institution-health/crm/prospect/{id}',
    institution_health_crm_prospect_cancel_deletion: '/institution-health/crm/prospect/{id}/cancel-deletion',
    institution_health_crm_prospect_import: '/institution-health/crm/prospect/import',
    institution_health_crm_prospect_assign: '/institution-health/crm/prospect/assign',
    institution_health_crm_prospect_form_list: '/institution-health/crm/prospect/{id}/form/list',
    institution_health_crm_prospect_prospect_activity_list: '/institution-health/crm/prospect/{id}/prospect-activity/list',
    institution_health_crm_prospect_assign_me: '/institution-health/crm/prospect/{id}/assign-me',
    institution_health_crm_prospect_unassign_me: '/institution-health/crm/prospect/{id}/unassign-me',
    institution_health_crm_prospect_note_edit: '/institution-health/crm/prospect/{id}/note',
    institution_health_crm_prospect_reset_password: '/institution-health/crm/prospect/{id}/reset-password',
    institution_health_crm_prospect_prospect_state_edit: '/institution-health/crm/prospect/{id}/prospect-state',

    institution_health_crm_prospect_prospect_hotline_list: '/institution-health/crm/prospect/{prospect}/hotline/list',
    institution_health_crm_prospect_prospect_hotline_add: '/institution-health/crm/prospect/{prospect}/hotline/add',

    institution_health_crm_prospect_commercial_list: '/institution-health/crm/prospect/{id}/commercial/list',
    institution_health_crm_prospect_commercial: '/institution-health/crm/prospect/{id}/commercial',
    institution_health_crm_prospect_commercial_commission: '/institution-health/crm/commercial/commission',

    institution_health_crm_prospect_registration_list: '/institution-health/crm/prospect/{prospect}/registration/list',
    institution_health_crm_prospect_registration_add: '/institution-health/crm/prospect/{prospect}/registration/add',
    institution_health_crm_prospect_registration_get: '/institution-health/crm/prospect/{prospect}/registration/{registration}',
    institution_health_crm_prospect_registration_edit: '/institution-health/crm/prospect/{prospect}/registration/{registration}',
    institution_health_crm_prospect_registration_cancel: '/institution-health/crm/prospect/{prospect}/registration/{registration}/cancel',
    institution_health_crm_prospect_registration_delete: '/institution-health/crm/prospect/{prospect}/registration/{registration}',
    institution_health_crm_prospect_registration_re_enrollment: '/institution-health/crm/prospect/{prospect}/registration/{registration}/re-enrollment',
    institution_health_crm_prospect_registration_formation_session_formation_list: '/institution-health/crm/prospect/{prospect}/registration/formation/{formation}/session-formation/list',
    institution_health_crm_prospect_registration_formation_list: '/institution-health/crm/prospect/{prospect}/registration/formation/list',

    institution_health_crm_commercial_list: '/institution-health/crm/commercial/list',

    institution_health_crm_commercial_task_list: '/institution-health/crm/commercial-task/list',
    institution_health_crm_commercial_task_add: '/institution-health/crm/commercial-task/add',
    institution_health_crm_commercial_task_edit: '/institution-health/crm/commercial-task/{id}',
    institution_health_crm_commercial_task_delete: '/institution-health/crm/commercial-task/{id}',

    institution_health_crm_hotline_list: '/institution-health/crm/hotline/list',

    institution_health_crm_institution_registration_list: '/institution-health/crm/institution/{institution}/registration/list',
    institution_health_crm_institution_registration_formation_list: '/institution-health/crm/institution/{institution}/registration/formation/list',
    institution_health_crm_institution_registration_formation_session_formation_list: '/institution-health/crm/institution/{institution}/registration/formation/{formation}/session-formation/list',
    institution_health_crm_institution_registration_formation_add: '/institution-health/crm/institution/{institution}/registration/formation/{formation}/add',
    institution_health_crm_institution_registration_cancel: '/institution-health/crm/institution/{institution}/registration/{registration}/cancel',
    institution_health_crm_institution_registration_delete: '/institution-health/crm/institution/{institution}/registration/{registration}/delete',
    institution_health_crm_institution_registration_re_enrollment: '/institution-health/crm/institution/{institution}/registration/{registration}/re-enrollment',
    institution_health_crm_institution_registration_edit: '/institution-health/crm/institution/{institution}/registration/{registration}/edit',

    institution_health_crm_relaunch_registration_list: '/institution-health/crm/relaunch/registration/list',
    institution_health_crm_relaunch_registration_marketing: '/institution-health/crm/relaunch/registration/{id}/marketing',

    institution_health_crm_reporting_info: '/institution-health/crm/reporting/info',
    institution_health_crm_dashboard_manager_registrations: '/institution-health/crm/dashboard-manager/registrations',

    //Marketing Manager
    institution_health_marketing_manager_featured_resource_info: '/institution-health/marketing-manager/featured-resource/info',
    institution_health_marketing_manager_featured_resource_resource_get: '/institution-health/marketing-manager/featured-resource/resource/{id}',
    institution_health_marketing_manager_featured_resource_edit: '/institution-health/marketing-manager/featured-resource/edit',

    institution_health_marketing_manager_thematic_list: '/institution-health/marketing-manager/thematic/list',
    institution_health_marketing_manager_thematic_add: '/institution-health/marketing-manager/thematic/add',
    institution_health_marketing_manager_thematic_get: '/institution-health/marketing-manager/thematic/{id}',
    institution_health_marketing_manager_thematic_edit: '/institution-health/marketing-manager/thematic/{id}',
    institution_health_marketing_manager_thematic_delete: '/institution-health/marketing-manager/thematic/{id}',

    institution_health_marketing_manager_thematic_thematic_formation_list: '/institution-health/marketing-manager/thematic/{id}/thematic-formation/list',
    institution_health_marketing_manager_thematic_thematic_formation_add: '/institution-health/marketing-manager/thematic/{id}/thematic-formation/add',
    institution_health_marketing_manager_thematic_thematic_formation_delete: '/institution-health/marketing-manager/thematic/{id}/thematic-formation/{formation}',

    institution_health_marketing_manager_thematic_thematic_formation_group_list: '/institution-health/marketing-manager/thematic/{id}/thematic-formation-group/list',
    institution_health_marketing_manager_thematic_thematic_formation_group_add: '/institution-health/marketing-manager/thematic/{id}/thematic-formation-group/add',
    institution_health_marketing_manager_thematic_thematic_formation_group_delete: '/institution-health/marketing-manager/thematic/{id}/thematic-formation-group/{formationGroup}',

    institution_health_marketing_manager_thematic_thematic_library_resource_list: '/institution-health/marketing-manager/thematic/{id}/thematic-library-resource/list',
    institution_health_marketing_manager_thematic_thematic_library_resource_add: '/institution-health/marketing-manager/thematic/{id}/thematic-library-resource/add',
    institution_health_marketing_manager_thematic_thematic_library_resource_delete: '/institution-health/marketing-manager/thematic/{id}/thematic-library-resource/{libraryResource}',

    institution_health_marketing_manager_library_resource_type_list: '/institution-health/marketing-manager/library-resource-type/list',
    institution_health_marketing_manager_library_resource_type_get: '/institution-health/marketing-manager/library-resource-type/{id}',
    institution_health_marketing_manager_library_resource_type_add: '/institution-health/marketing-manager/library-resource-type/add',
    institution_health_marketing_manager_library_resource_type_edit: '/institution-health/marketing-manager/library-resource-type/{id}',
    institution_health_marketing_manager_library_resource_type_delete: '/institution-health/marketing-manager/library-resource-type/{id}',

    institution_health_marketing_manager_library_resource_list: '/institution-health/marketing-manager/library-resource/list',
    institution_health_marketing_manager_library_resource_get: '/institution-health/marketing-manager/library-resource/{id}',
    institution_health_marketing_manager_library_resource_add: '/institution-health/marketing-manager/library-resource/add',
    institution_health_marketing_manager_library_resource_edit: '/institution-health/marketing-manager/library-resource/{id}',
    institution_health_marketing_manager_library_resource_delete: '/institution-health/marketing-manager/library-resource/{id}',

    institution_health_marketing_manager_library_resource_command_execute_livestorm: '/institution-health/marketing-manager/library-resource/command/execute-livestorm',

    //FinancialManagement
    institution_health_financial_management_billing_institution_list: '/institution-health/financial-management/billing/institution/list',

    institution_health_financial_management_billing_institution_invoice_list: '/institution-health/financial-management/billing/institution/{institution}/invoice/list',

    institution_health_financial_management_billing_institution_invoice_get: '/institution-health/financial-management/billing/institution/{institution}/get',

    institution_health_financial_management_billing_institution_estimation_info: '/institution-health/financial-management/billing/institution/{institution}/estimation/{contractId}/info',

    institution_health_financial_management_billing_institution_contract_info: '/institution-health/financial-management/billing/institution/{institution}/contract/{contractId}/info',
    institution_health_financial_management_billing_institution_contract_add: '/institution-health/financial-management/billing/institution/{institution}/contract/add',
    institution_health_financial_management_billing_institution_contract_edit: '/institution-health/financial-management/billing/institution/{institution}/contract/{contractId}',
    institution_health_financial_management_billing_institution_contract_delete: '/institution-health/financial-management/billing/institution/{institution}/delete/contract/{contractId}',
    institution_health_financial_management_billing_institution_contract_cancel: '/institution-health/financial-management/billing/institution/{institution}/cancel/contract/{contractId}',
    institution_health_financial_management_billing_institution_contract_convention: '/institution-health/financial-management/billing/institution/{institution}/contract/{contractId}/convention',
    institution_health_financial_management_billing_institution_contract_deposit: '/institution-health/financial-management/billing/institution/{institution}/invoice-deposit/add',
    institution_health_financial_management_billing_institution_contract_rest: '/institution-health/financial-management/billing/institution/{institution}/invoice-rest/add',
    institution_health_financial_management_billing_institution_download: '/institution-health/financial-management/billing/institution/{institution}/download/{id}',

    institution_health_financial_management_billing_institution_invoice_cancel: '/institution-health/financial-management/billing/institution/{institution}/cancel/invoice/{invoiceId}',
    institution_health_financial_management_billing_institution_invoice_paid: '/institution-health/financial-management/billing/institution/{institution}/invoice/{invoiceId}/paid',
    institution_health_financial_management_billing_institution_invoice_add: '/institution-health/financial-management/billing/institution/{institution}/invoice/add',


    institution_health_financial_management_billing_institution_credit_add: '/institution-health/financial-management/billing/institution/{institution}/invoice/{invoiceId}/registration-credit',
    institution_health_financial_management_billing_institution_registration_invoices_info: '/institution-health/financial-management/billing/institution/{institution}/registration/{registrationId}/invoices-info',

    institution_health_financial_management_billing_institution_invoice_regitration_get: '/institution-health/financial-management/billing/institution/{institution}/invoice-regitration',

    institution_health_financial_management_billing_institution_invoice_personal_add: '/institution-health/financial-management/billing/institution/{institution}/personal-invoice/add',
    institution_health_financial_management_billing_institution_invoice_personal_edit: '/institution-health/financial-management/billing/institution/{institution}/personal-invoice/{id}',

    institution_health_financial_management_billing_session_formation_list: '/institution-health/financial-management/billing/formation/list',
    institution_health_financial_management_billing_session_formation_get: '/institution-health/financial-management/billing/formation/{sessionFormation}/get',
    institution_health_financial_management_billing_session_formation_edit: '/institution-health/financial-management/billing/formation/{sessionFormation}',
    institution_health_financial_management_billing_session_formation_billable: '/institution-health/financial-management/billing/formation/{sessionFormation}/billable',
    institution_health_financial_management_billing_session_formation_send_participation_certificate: '/institution-health/financial-management/billing/formation/{sessionFormation}/send/participation-certificate',
    institution_health_financial_management_billing_session_formation_download_follow_up_certificate: '/institution-health/financial-management/billing/formation/{sessionFormation}/andpc/download-follow-up-certificate',
    institution_health_financial_management_billing_session_formation_download: '/institution-health/financial-management/billing/formation/{sessionFormation}/download/{id}',
    institution_health_financial_management_billing_session_formation_download_participation_certificate: '/institution-health/financial-management/billing/formation/{sessionFormation}/download/participation-certificate',
    institution_health_financial_management_billing_session_formation_download_kit_participation: '/institution-health/financial-management/billing/formation/{sessionFormation}/download/kit-participation',
    institution_health_financial_management_billing_session_formation_invoice: '/institution-health/financial-management/billing/formation/{sessionFormation}/invoice',
    institution_health_financial_management_billing_session_formation_invoice_rest: '/institution-health/financial-management/billing/formation/{sessionFormation}/invoice/rest',
    institution_health_financial_management_billing_invoice_download: '/financial-management/billing-v2/invoice/{id}/download',
    institution_health_financial_management_billing_credit_download: '/financial-management/billing-v2/credit/{id}/download',
    institution_health_financial_management_billing_session_formation_credit: '/institution-health/financial-management/billing/formation/{sessionFormation}/invoice/{id}/credit',
    institution_health_financial_management_billing_session_formation_invoice_paid: '/institution-health/financial-management/billing/formation/{sessionFormation}/invoice/{id}/paid',
    institution_health_financial_management_billing_session_formation_invoice_credit_balance: '/institution-health/financial-management/billing/formation/{sessionFormation}/credit-balance',

    institution_health_financial_management_billing_registration_edit: '/institution-health/financial-management/billing/registration/{registration}',


    // select
    select_institution_groups: '/select/institution-groups',
    select_institution_types: '/select/institution-types',
    select_institution_poles: '/select/{institution}/institution-pole',
    select_institution_pole_names: '/select/institution-pole-names',
    select_group_institutions: '/select/{organismId}/groupless-institutions',
    select_institution_prospects: '/select/{institution}/institution-prospect',
    select_institution_employees: '/select/{institution}/institution-employee',
    select_institution_states: '/select/institution-states',
    select_institution_functions: '/select/institution-functions'
}

