import { Box, Fade, makeStyles } from "@material-ui/core";
import UseAnimations from "react-useanimations";
import activity from "react-useanimations/lib/activity";
import ButtonComponent from "../../../../../Component/ButtonComponent";
import React from "react";
import moment from "moment";

const ViewRegistrationComponent = ({ dataList, addBilling, loading, disabled }) => {
    const classes = useStyles();
    return (
        <Box style={{ display: 'grid', gridTemplateRows: 'max-content auto', gap: 10, color: '#5E6E82', height: '100%' }}>
            <Box style={{ display: 'grid', gridTemplateColumns: 'max-content auto', alignItems: 'center', gap: 10 }}>
                <h1 style={{ fontSize: 20, margin: 0 }}>Liste des inscription</h1>
                <hr style={{ margin: 0, opacity: 0.5 }} />
            </Box>
            <Box style={{ background: '#EFF3F4', borderRadius: 10, border: '1px solid rgba(163, 173, 183, 0.36)', position: 'relative', overflow: 'hidden auto' }}>
                {loading ?
                    <Fade in={true} {...{ timeout: 1000 }}>
                        <Box className={classes.loader} style={{ width: '100%', height: '100%', display: 'flex', }}>
                            <Box className={classes.loaderContent}>
                                <Box className={classes.loaderActivity}>
                                    <UseAnimations animation={activity} size={70} />
                                </Box>
                            </Box>
                        </Box>
                    </Fade>
                    : (dataList.length != 0) ? <div style={{ padding: 20, position: 'absolute', top: 0, left: 0, display: 'flex', flexWrap: 'wrap', gap: 20 }}>
                        {dataList.map((data, index) => (
                            <Fade key={index} in={true} {...{ timeout: 1000 }}>
                                <Box style={{ background: '#FFF', width: 220, borderRadius: 10, border: '1px solid #A3ADB7', display: 'grid', gridTemplateRows: 'max-content auto max-content' }}>
                                    <Box style={{ padding: '10px 15px', borderBottom: '1px solid #A3ADB7' }}>
                                        <p style={{ margin: 0, fontSize: 12 }}>Nom/Prénom</p>
                                        <p style={{ margin: 0, fontWeight: 'bold', fontSize: 14 }}>{`${data.lastname ?? ""} ${data.firstname ?? ""}`}</p>
                                    </Box>
                                    <Box style={{ padding: '10px 15px 0', }}>
                                        <p style={{ margin: 0, fontSize: 12 }}>Formation :</p>
                                        <p style={{ margin: 0, fontWeight: 'bold', fontSize: 14 }}>{data.formationName ?? '-'}</p>
                                        <p style={{ margin: '10px 0', fontSize: 12 }}>Session :<br />
                                            <span style={{ margin: 0, fontSize: 13, fontWeight: 'bold' }}>{moment(data.sessionStartAt.date).format('ll')} au {moment(data.sessionEndAt.date).format('ll')}</span>
                                        </p>
                                        <p style={{ margin: 0, fontSize: 12 }} >Financement - <b>{data.financeTypeName ?? '-'}</b></p>
                                        {data.notNormal && <p style={{ margin: 0, fontSize: 12, color: '#DD4E4E' }} >Inscription annuler</p>}
                                    </Box>
                                    <ButtonComponent
                                        style={{ width: '90%', margin: '10px auto', display: 'block' }}
                                        onClick={() => { addBilling(data) }}
                                        color='#5E6E82'
                                        label={<>Ajouter à la Facture</>}
                                        disabled={loading || disabled || data.notNormal}
                                    />
                                </Box>
                            </Fade>
                        ))}
                    </div> : <p className={classes.error} style={{ transform: 'translate(-50%,-50%)' }}>Aucune inscription</p>}
            </Box >
        </Box >
    )
}

const useStyles = makeStyles(style => ({
    loaderActivity: {
        '& > div':
        {
            margin: 'auto',
            padding: 10,
            width: '50px !important',
            height: '50px !important'
        }
    },
    loader: {
        textAlign: 'center'
    },
    loaderContent: {
        margin: 'auto',
        background: '#FFF', border: '5px double rgb(206, 200, 200)', borderRadius: 100
    },
    error: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
        fontSize: 30,
        fontWeight: 'bold',
        opacity: 0.3,
        margin: 0,
        width: '100%',
        textAlign: 'center',
    },
}));

export default ViewRegistrationComponent;