import { Box, IconButton, Tooltip } from "@material-ui/core";
import TableComponent from "../../../../Component/TableComponent";
import { getRoutePathname } from "../../../../Config/Route";
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import LibraryAddCheckIcon from '@material-ui/icons/LibraryAddCheck';
import ApartmentIcon from '@material-ui/icons/Apartment';
import CardHolderComponent from "./Component/CardHolderComponent";
import Api from "../../../../Api";
import { useSelector } from "react-redux";

const BillingFinancialMix = (props) => {
    const selectOrganismReducer = useSelector(state => state.SelectOrganismReducer);

    const columns = [
        {
            name: "id",
            label: "Référence",
            options: { filter: true, sort: true }
        },
        {
            name: "name",
            label: "Nom",
            options: { filter: true, sort: true }
        },
        {
            name: "institutionType",
            label: "Type d'établissement",
            options: { filter: true, sort: true }
        },
        {
            name: "holder",
            label: "Titulaire",
            options: { filter: true, sort: true }
        },
        {
            name: "employeeCount",
            label: "Salariés",
            options: { filter: true, sort: true }
        },
        {
            name: "groupName",
            label: "Groupement",
            options: { filter: true, sort: true }
        },
        {
            name: "registrationBilled",
            label: "Facturer",
            options: { filter: true, sort: true }
        },
        {
            name: "registrationToBill",
            label: "À facturer",
            options: { filter: true, sort: true }
        },
        {
            name: "action",
            label: "Action",
            options: { filter: false, sort: false }
        }
    ];

    const getAction = (row) => {
        return (
            <Box>
                <Tooltip title={'Créer une facture mixte'} placement="left">
                    <IconButton onClick={() => {
                        props.history.push(getRoutePathname('institution_health_financial_management_billing_institution_invoice', { institution: row.id, id: null }))
                    }}>
                        <LibraryBooksIcon style={{ color: '#5E6E82' }} />
                    </IconButton>
                </Tooltip>
                <Tooltip title={'Liste des facturations'} placement="left">
                    <IconButton onClick={() => { props.history.push(getRoutePathname('institution_health_financial_management_billing_institution_invoice_list', { institution: row.id })) }}>
                        <LibraryAddCheckIcon style={{ color: '#5E6E82' }} />
                    </IconButton>
                </Tooltip>
            </Box>
        )
    }

    return (
        <TableComponent
            id={'institution_health_financial_management_billing_institution_list'}
            title={<h1 style={{ margin: 0, fontSize: 15, display: 'flex', alignItems: 'center', gap: 5, }}><ApartmentIcon style={{ width: 20, height: 20 }} />Vos établissements</h1>}
            columns={columns}
            search={true}
            promiseServerData={(resolve, options) => {
                let filters = props.getFilterValues(options);
                Api.get({
                    route: 'institution_health_financial_management_billing_institution_list',
                    data: { ...filters, organismId: selectOrganismReducer.organism.value, isPersonal: false }
                },
                    (response) => {
                        if (response.status === 200) {
                            let data = response.data.data;
                            for (let index in data) {
                                data[index].action = getAction(data[index]);
                                data[index].holder = data[index].holder.length > 0 ? <CardHolderComponent holder={data[index].holder} /> : '-';
                                //data[index].email = data[index].email ? data[index].email : '-';
                                data[index].name = data[index].name ? data[index].name : '-';
                                data[index].groupName = data[index].groupName ? data[index].groupName : '-';
                                data[index].employeeCount = data[index].employeeCount ? data[index].employeeCount : 0;
                                data[index].registrationBilled = data[index].registrationBilled ? data[index].registrationBilled : 0;
                                data[index].registrationToBill = data[index].registrationToBill ? data[index].registrationToBill : 0;

                                if (data[index].phone) {
                                    continue;
                                } else if (data[index].mobile) {
                                    data[index].phone = data[index].mobile;
                                } else {
                                    data[index].mobile = '-';
                                    data[index].phone = '-'
                                }
                            }
                            resolve(data, response.data.count);
                        } else resolve([]);
                    });
            }}
        />
    )
}
export default BillingFinancialMix;