import React from 'react';
import { Box, Fade, Slide, makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
import activity from "react-useanimations/lib/activity";
import { dispatch, theme } from "../../../App";
import ShadowBoxComponent from '../../../Component/ShadowBoxComponent';
import ButtonComponent from '../../../Component/ButtonComponent';
import UseAnimations from 'react-useanimations';
import ApartmentIcon from '@material-ui/icons/Apartment';
import { SelectOrganism } from '../../../Action/SelectOrganismAction';
import { OrganismIndex, Organisms } from '../../../Organism';

export default function SelectOrganisms(props) {
    const classes = useStyles();
    const [isSmallView, setSmallView] = React.useState(false);
    const screenReducer = useSelector(state => state.ScreenReducer);

    const selectOrganism = (data) => {
        dispatch(SelectOrganism({ organism: data }));
    }

    React.useEffect(() => {
        setSmallView((screenReducer.screen === 'XS' || screenReducer.screen === 'SM'));
    }, [screenReducer.screen]);

    return (
        <Box className={classes.contentView} >
            {props.loading ?
                <Fade in={true} {...{ timeout: 1000 }}>
                    <Box className={classes.loader}>
                        <Box className={classes.loaderContent} style={isSmallView ? { width: 225 } : {}}>
                            <ShadowBoxComponent>
                                <img src={'/asset/images/' + theme.logo} alt="logo" className={classes.loaderLogo} />
                                <Box className={classes.loaderActivity}>
                                    <UseAnimations animation={activity} size={35} />
                                </Box>
                            </ShadowBoxComponent>
                        </Box>
                    </Box>
                </Fade>
                :
                <Box style={
                    screenReducer.size <= 1217 ?
                        { width: '100%', margin: 'auto', height: '100%', overflow: 'auto' } :
                        { display: 'grid', width: '100%', margin: 'auto', height: '100%', gridTemplateColumns: 'min-content auto', overflow: 'auto' }
                } >
                    <Slide direction="right" in={true} {...{ timeout: 500 }}>
                        <Box className={screenReducer.size <= 1217 ? classes.descriptionScreenStyle : classes.descriptionStyle}>
                            <ApartmentIcon style={screenReducer.size <= 1217 ? { display: 'none' } : {}} />
                            <h1 style={{ color: '#FFFFFF', }}>
                                Portail <span style={screenReducer.size <= 1217 ? { fontSize: 18, } : { fontSize: 18, padding: '0 56px' }}>Gestionnaire d'établissement de santé.</span>
                            </h1>
                            <p style={screenReducer.size <= 1217 ? { display: 'none' } : { color: '#FFFFFF', fontWeight: 200 }} >
                                Accédez à votre tableau de bord administratif pour orchestrer
                                harmonieusement les opérations de vos établissements.
                            </p>
                        </Box>
                    </Slide>
                    <Box style={{ overflow: 'auto', justifyItems: 'center', display: 'grid' }}>
                        <Box style={
                            screenReducer.size <= 1217 ?
                                { margin: '50px 0px', display: 'grid', gridTemplateColumns: 'repeat(1, 1fr)', gap: 20, width: '80%' } :
                                { margin: 'auto', display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: 30, width: '80%', height: 'fit-content', padding: '50px 0' }
                        } >
                            {props.data.length !== 0 ? props.data.map((data, index) => (
                                <Fade in={true} {...{ timeout: 1000 }} key={index}>
                                    <ShadowBoxComponent className={classes.shadowBox} style={{ width: '100%' }}>
                                        <Box style={{ position: 'relative', display: 'flex', alignItems: 'center', flexDirection: 'column', margin: 'auto', }}>
                                            {Organisms[OrganismIndex[data.value]] && <img style={{ width: 50, height: 50, padding: 10 }} src={'/asset/images/' + Organisms[OrganismIndex[data.value]].icon} />}
                                            <h1 style={{ margin: 0 }}>{data.label}</h1>
                                        </Box>
                                        <Box style={{ background: '#FFFFFF', display: 'flex', flexDirection: 'column', padding: '15px 40px', }}>
                                            <ButtonComponent
                                                style={{ margin: 'auto', width: 'fit-content', padding: '6px 40px', zIndex: 1 }}
                                                color={'#5E6E82'} label={'Accéder'}
                                                onClick={() => { selectOrganism(data) }}
                                            />
                                        </Box>
                                    </ShadowBoxComponent>
                                </Fade>
                            )) :
                                <Fade in={true} {...{ timeout: 1000 }}>
                                    <h1 style={{ margin: 'auto', color: '#5E6E82', fontSize: 30, opacity: 0.5, display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                                        <ApartmentIcon style={{ width: 'auto', height: 100, background: '#FFF', borderRadius: 100, boxShadow: 'inset 0 0 5px', padding: 15 }} />
                                        Vous n'êtes liés à aucun organisme d'établissements.
                                    </h1>
                                </Fade>
                            }
                        </Box>
                    </Box>
                </Box>
            }
        </Box>
    )
}

const useStyles = makeStyles({
    loader: {
        textAlign: 'center',
        width: '100%',
    },
    loaderContent: {
        marginTop: 'calc(50vh - 60px)',
        transform: 'translateY(-50%)',
        width: 270,
        margin: 'auto'
    },
    loaderLogo: {
        width: '80%',
        marginLeft: '3%',
        marginBottom: 5
    },
    loaderActivity: {
        '& > div': {
            margin: 'auto',
            width: '15% !important'
        }
    },
    contentView: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        paddingTop: 58,
        background: '#EFF2F4'
    },
    shadowBox: {
        background: 'linear-gradient(rgba(255, 255, 255, 0.74), rgb(255, 255, 255)), url(/asset/images/wallpaper_institution.webp)',
        padding: 0,
        display: 'grid',
        gridTemplateRows: '200px max-content',
        overflow: 'hidden',
        margin: 'auto 0',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        '& div': {
            '& h1': {
                color: '#5E6E82',
                width: '100%',
                textAlign: 'center',
                fontSize: 25
            }
        }
    },
    descriptionStyle: {
        display: 'flex',
        width: 550,
        gap: 30,
        margin: 'auto',
        height: '100%',
        background: 'linear-gradient(rgba(94, 110, 130, .9), rgba(94, 110, 130, .8)), url(/asset/images/wallpaper_institution.webp)',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        flexDirection: 'column',
        position: 'relative',
        '& h1': {
            margin: 'auto 0 0 0',
            fontSize: 80,
            color: 'rgb(255, 255, 255)',
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'center',
        },
        '& p': {
            margin: 'auto 77px 24px 24px',
            fontSize: 22
        },
        '& svg': {
            width: 'max-content',
            height: 75,
            color: '#5E6E82',
            background: '#EFF2F4',
            borderRadius: 100,
            padding: 16,
            position: 'absolute',
            right: '0%',
            top: '50%',
            transform: 'translate(50%,-50%)'
        }
    },
    descriptionScreenStyle: {
        display: 'flex',
        width: '100%',
        gap: 30,
        margin: 'auto',
        background: 'linear-gradient(rgba(94, 110, 130, .9), rgba(94, 110, 130, .8)), url(/asset/images/wallpaper_institution.webp)',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        flexDirection: 'column',
        position: 'relative',
        '& h1': {
            margin: 0,
            padding: 10,
            fontSize: 38,
            color: 'rgb(255, 255, 255)',
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'center',
        },
        '& p': {
            margin: 'auto 77px 24px 24px',
            fontSize: 22
        },
        '& svg': {
            display: 'none'
        }
    }
})