import { Box, Checkbox, Fade, IconButton, makeStyles, Tooltip } from "@material-ui/core";
import React from "react";
import UseAnimations from "react-useanimations";
import activity from "react-useanimations/lib/activity";
import SweetAlert from 'sweetalert-react/lib/SweetAlert';
import ListIcon from '@material-ui/icons/List';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import ButtonComponent from "../../../../../Component/ButtonComponent";

const ViewRegistrationBilledComponent = (props) => {
    const classes = useStyles()();
    const checkList = props.checkList;
    const dataList = props.dataList;

    React.useEffect(() => {
        if (checkList.length !== 0) {
            props.setIsAllTrue(
                checkList
                    .filter(item => !item.deletedAt)
                    .every(item => item.isConvention)
            );

            props.setTrueCount(
                checkList
                    .filter(item => !item.deletedAt && item.isConvention)
                    .length
            );
        }
    }, [checkList]);

    React.useEffect(() => {
        if (props.saveConfirm) {
            props.setSaveConfirm(false);
        }
    }, [props.saveConfirm]);

    return (
        <>
            <Box style={{ display: 'grid', gridTemplateRows: 'auto max-content', color: '#5E6E82', height: '100%' }}>
                <Box style={{ position: 'relative', overflow: 'hidden auto' }}>
                    <table className={classes.tableRoot}>
                        <thead>
                            <tr>
                                <th>Nom/Prénom</th>
                                <th style={{ minWidth: 145 }}>Formation</th>
                                <th>Frais d’inscription</th>
                                <th>Coût pédagogique</th>
                                <th>Prise en charge</th>
                                <th style={{ width: 100 }}>Conventions</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        {props.loading ? (
                            <Fade in={true} timeout={1000}>
                                <Box className={classes.loader} style={{ width: '100%', height: '100%', display: 'flex' }}>
                                    <Box className={classes.loaderContent}>
                                        <Box className={classes.loaderActivity}>
                                            <UseAnimations animation={activity} size={70} />
                                        </Box>
                                    </Box>
                                </Box>
                            </Fade>
                        ) : dataList.length !== 0 ? (
                            dataList.map((registration, index) => (
                                <tbody key={index}>
                                    <tr className={registration.deletedAt && classes.deletedAt}>
                                        <Card
                                            data={registration}
                                            save={props.save}
                                            checkList={checkList}
                                            setCheckList={props.setCheckList}
                                            saveConfirm={props.saveConfirm}
                                            loading={props.loading || props.disabled || props.isInvoiceEdit}
                                            dataInstitution={props.dataInstitution}
                                            setAddList={props.setAddList}
                                            setIsAllChecked={props.setIsAllChecked}
                                            isAllChecked={props.isAllChecked}
                                            isInvoiceEdit={props.isInvoiceEdit}
                                            setRegistration={props.setRegistration}
                                        />
                                    </tr>
                                </tbody>
                            ))) : (
                            <tbody>
                                <p className={classes.error} style={{ transform: 'translate(-50%,-50%)' }}>Aucun inscription</p>
                            </tbody>
                        )}
                    </table>
                </Box>
            </Box>
        </>
    );
};

const Card = (props) => {
    const [dataCheck, setDataCheck] = React.useState({});
    const data = props.data;
    const [isConvention, setIsConvention] = React.useState(data.isConvention);
    const [newCheckList, setNewCheckList] = React.useState([]);

    const getIsConventionStatus = () => {
        const entry = newCheckList.find(item => item.id === data.registrationId);
        return entry ? entry.isConvention : null;
    }

    React.useEffect(() => {
        props.setCheckList((prevCheckList) => {
            const item = {
                id: data.registrationId,
                registrationContractId: data.id,
                isConvention: data.isConvention,
                isForced: data.isForced,
                isLock: data.isLock,
                deletedAt: data.deletedAt
            };
            const itemExists = prevCheckList.some((checkItem) => checkItem.id === item.id);
            let newPrevCheckList;

            if (itemExists) {
                newPrevCheckList = prevCheckList.map((checkItem) => {
                    if (checkItem.id === item.id) {
                        data.isConvention = isConvention;
                        return { ...checkItem, isConvention: isConvention }
                    }
                    else return checkItem
                }
                );
            } else {
                newPrevCheckList = [...prevCheckList, item];
            }
            setNewCheckList(newPrevCheckList);
            return newPrevCheckList;
        });
    }, [isConvention, data]);

    React.useEffect(() => {
        if (props.saveConfirm) {
            const checkItem = props.checkList.filter((checkItem) => checkItem.id === data.registrationId && !data.deletedAt && !data.isLock);
            setIsConvention(checkItem[0]?.isConvention)
            setDataCheck(checkItem[0]);
        }
    }, [props.checkList, props.saveConfirm]);

    React.useEffect(() => {
        const checkItem = newCheckList.filter((checkItem) => checkItem.id === data.registrationId && !data.deletedAt && !data.isLock);
        setDataCheck(checkItem[0]);
    }, [newCheckList]);

    return (
        <>
            <td>{`${data.prospectName ?? ""}`}</td>
            <td style={{ padding: 0 }}>
                <Tooltip title={data.formationName ?? ""}>
                    <p className={'formationName'}>{`${data.formationName ?? "-"}`}</p>
                </Tooltip>
            </td>
            <td>
                <Tooltip title={data.inscriptionFee?.indicator?.text ?? ''}>
                    <p style={{ color: data.inscriptionFee?.indicator?.color }}>
                        <b>{data.inscriptionFee?.invoice?.toFixed(2) ?? 0} €</b>
                    </p>
                </Tooltip>
                <p style={{ margin: 'auto', fontSize: 12, width: 'fit-content' }} >Contrat | <b>{data.inscriptionFee?.contract.toFixed(2) ?? 0} €</b></p>
            </td>
            <td>
                <Tooltip title={data.educationalFee?.indicator?.text ?? ''}>
                    <p style={{ color: data.educationalFee?.indicator?.color }}>
                        <b>{data.educationalFee?.invoice?.toFixed(2) ?? 0} €</b>
                    </p>
                </Tooltip>
                <p style={{ margin: 'auto', fontSize: 12, width: 'fit-content' }} >Contrat | <b>{data.educationalFee?.contract.toFixed(2) ?? 0} €</b></p>
            </td>
            <td>
                <Tooltip title={data.supportFee?.indicator?.text ?? ''}>
                    <p style={{ color: data.supportFee?.indicator?.color }}>
                        <b>{data.supportFee?.contract?.toFixed(2) ?? 0} €</b>
                    </p>
                </Tooltip>
                <p style={{ margin: 'auto', fontSize: 12, width: 'fit-content' }}>Financement - <b>{data.financeTypeName ?? '-'}</b></p>
            </td>
            <td>
                {(dataCheck && Object.keys(dataCheck).length != 0) ?
                    <GetActionConventionCheck
                        data={dataCheck}
                        save={props.save}
                        loading={props.loading || data.deletedAt || data.isLock}
                        dataInstitution={props.dataInstitution}
                        onChange={(value) => setIsConvention(value)}
                    /> : (data.deletedAt || data.isLock) ?
                        <div style={{ background: 'rgba(0, 0, 0, 0.12)', height: '100%', justifyContent: 'center', display: 'flex', borderRadius: 5, overflow: 'hidden' }}>
                            <IconButton disabled={true} style={{ borderRadius: 0, padding: 5, width: '100%' }}>
                                {data.isConvention ?
                                    <CheckCircleOutlineIcon style={{ color: '#FFF' }} /> :
                                    <HighlightOffIcon style={{ color: '#FFF' }} />}
                            </IconButton>
                        </div> : ''
                }
            </td>
            <td>
                <Box style={{ display: 'grid', gridTemplateColumns: 'auto max-content' }}>
                    <div style={{ background: '#5E6E82', height: '100%', justifyContent: 'center', display: 'flex', borderRadius: (props.isInvoiceEdit && !getIsConventionStatus() && !data.deletedAt && !data.isLock) ? '5px 0 0 5px' : 5, overflow: 'hidden' }}>
                        <Tooltip title={'Voir ses factures & avoir'} placement="left">
                            <IconButton onClick={() => {
                                props.setRegistration({
                                    id: data.registrationId,
                                    prospectName: `${data.prospectName ?? ''}`
                                });
                            }} style={{ borderRadius: 0, padding: 5, width: '100%' }}>
                                <ListIcon style={{ color: '#FFF' }} />
                            </IconButton>
                        </Tooltip>
                    </div>
                    {(props.isInvoiceEdit && !getIsConventionStatus() && !data.deletedAt && !data.isLock) &&
                        <div style={{ background: 'rgba(94, 110, 130, 0.31)', height: '100%', justifyContent: 'center', display: 'flex', borderRadius: '0 5px 5px 0', overflow: 'hidden' }}>
                            <GetActionCreditCheck
                                data={data}
                                institution={props.dataInstitution}
                                isAllChecked={props.isAllChecked}
                                setIsAllChecked={props.setIsAllChecked}
                                onChange={(value) => props.setAddList(value)}
                            />
                        </div>
                    }
                </Box>
            </td>
        </>
    );
};

const GetActionConventionCheck = (props = { data: [], dataInstitution: [], save: () => { }, loading: false, onChange: () => { } }) => {
    const data = props.data;
    const [isLoading, setIsLoading] = React.useState(false);
    const [isEvent, setIsEvent] = React.useState(false);
    const [isChecked, setIsChecked] = React.useState(false);
    const [text, setText] = React.useState('');
    const [showConfirm, setShowConfirm] = React.useState(false);

    const handleCheckboxChange = (checked) => {
        if (!checked && props.dataInstitution.isPaid) {
            setText(`Êtes-vous sûr de vouloir décocher cette convention ?\n Si c'est le cas, cette inscription sera désactivée.`);
            setIsEvent(checked);
            setShowConfirm(true);
        } else if (checked && props.dataInstitution.isPaid) {
            setText(`Êtes-vous sûr de vouloir cocher cette convention ?\n Si c'est le cas, cette inscription sera activée.`);
            setIsEvent(checked);
            setShowConfirm(true);
        } else {
            setIsChecked(checked);
            props.onChange(checked);
            props.save(null, data.registrationContractId, checked, setIsLoading);
        }
    }

    React.useEffect(() => {
        setIsChecked(data.isConvention);
    }, [data.isConvention]);

    return (
        <>
            <div style={{
                background: (props.loading || isLoading) ? 'rgba(0, 0, 0, 0.12)' :
                    (isChecked ? '#28a745' : (data.isForced ? '#dd984e' : '#DD4E4E')), height: '100%', justifyContent: 'center', display: 'flex', borderRadius: 5, overflow: 'hidden'
            }}>
                <Tooltip title={!isChecked ?
                    `Valider la convention \n ${data.isForced ? '(Cette formation est accessible.)' : ''}` :
                    `Annuler la convention \n ${data.isForced ? '(Attention, si vous la décochez,\n cette formation restera tout de même accessible.)' : ''}`
                } placement="left">
                    <IconButton disabled={props.loading || isLoading} onClick={() => handleCheckboxChange(!isChecked)} style={{ borderRadius: 0, padding: 5, width: '100%' }}>
                        {isChecked ?
                            <CheckCircleOutlineIcon style={{ color: '#FFF' }} /> :
                            <HighlightOffIcon style={{ color: '#FFF' }} />}
                    </IconButton>
                </Tooltip>
            </div>
            <SweetAlert
                show={showConfirm}
                title={!isChecked ? 'Tout cocher' : 'Tout décocher'}
                text={text}
                type={'warning'}
                cancelButtonText={'Non'}
                confirmButtonText={'Oui'}
                showCancelButton={true}
                onConfirm={() => {
                    setShowConfirm(false)
                    setIsChecked(isEvent);
                    props.onChange(isEvent);
                    props.save(null, data.registrationContractId, isEvent, setIsLoading);
                }}
                onCancel={() => setShowConfirm(false)}
            />
        </>
    );
};

const GetActionCreditCheck = (props = { data: [], institution: [], isAllChecked: false, setIsAllChecked: () => { }, onChange: () => { } }) => {
    const data = props.data;
    const classes = useStyles()();
    const [isChecked, setIsChecked] = React.useState(false);

    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
        props.onChange((prev) => {
            const exists = prev.some(item => item === data.registrationId && !data.deletedAt);
            if (exists) {
                return prev.filter(item => item !== data.registrationId);
            } else {
                return [...prev, data.registrationId];
            }
        });
    }

    React.useEffect(() => {
        props.onChange((prev) => {
            const exists = prev.some(item => item === data.registrationId && !data.deletedAt);
            setIsChecked(exists);
            return prev;
        })
    }, [props.isAllChecked])

    return (
        <div className={classes.checkbox}>
            <Checkbox
                key={`${data.registrationId}`}
                checked={isChecked}
                onChange={(value) => handleCheckboxChange(value)}
            />
        </div>
    );
};

const useStyles = (loading) => makeStyles(style => ({
    loaderActivity: {
        '& > div': {
            margin: 'auto',
            padding: 10,
            width: '50px !important',
            height: '50px !important'
        }
    },
    loader: {
        textAlign: 'center'
    },
    loaderContent: {
        margin: 'auto',
        background: '#FFF',
        border: '5px double rgb(206, 200, 200)',
        borderRadius: 100
    },
    checkbox: {
        display: 'flex',
        alignItems: 'center',
        '& .MuiIconButton-root': {
            padding: '5px !important',
            borderRadius: '0 !important'
        },
        '& .MuiCheckbox-colorSecondary.Mui-checked': {
            color: '#5E6E82'
        }
    },
    error: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
        fontSize: 30,
        fontWeight: 'bold',
        opacity: 0.3,
        margin: 0,
        width: '100%',
        textAlign: 'center',
    },
    deletedAt: {
        '& td': {
            background: '#dfcbb9 !important',
        },
    },
    tableRoot: {
        width: '100%',
        '& thead': {
            position: 'relative',
            '& th': {
                background: '#E5E7EA',
                padding: 10,
                fontSize: 13,
                transition: 'all .4s',
                textAlign: 'center'
            },
        },
        '& tbody': {
            '& tr td': {
                background: '#E5E7EA',
                padding: 10,
                fontSize: 13,
                transition: 'all .4s',
                textAlign: 'center',
                position: 'relative'
            },
            '& td': {
                '& >div, p': {
                    margin: 0,
                    textAlign: 'center'
                },
                '& p.formationName': {
                    margin: 'auto',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    position: 'absolute',
                    width: '85%',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%,-50%)'
                },
                '& span': {
                    fontWeight: 'bold',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: 3,
                }
            }
        }
    },
}));

export default ViewRegistrationBilledComponent;