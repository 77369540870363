import React from 'react';
import ContentViewComponent from "../../../../Component/ContentViewComponent";
import { formHandlerInit } from "../../../../Tool/FormHandlerCommon";
import Grid from "@material-ui/core/Grid";
import ShadowBoxComponent from "../../../../Component/ShadowBoxComponent";
import { makeStyles } from "@material-ui/core";
import ButtonComponent from "../../../../Component/ButtonComponent";
import Api from "../../../../Api";
import { getRoutePathname } from "../../../../Config/Route";
import TitleComponent from "../../../../Component/TitleComponent";
import { SnackbarOpen } from "../../../../Action/SnackbarAction";
import { useDispatch, useSelector } from "react-redux";
import Fade from "@material-ui/core/Fade";
import SelectComponent from "../../../../Component/SelectComponent";
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from "../../../../Action/LinearProgressMainLayoutAction";
import DatePickerComponent from "../../../../Component/DatePickerComponent";
import Slide from "@material-ui/core/Slide";
import SessionFormation from "./SessionFormation";
import moment from "moment";
import { TableReload } from "../../../../Action/TableAction";

function Session(props) {
    const dispatch = useDispatch();
    const selectOrganismReducer = useSelector(state => state.SelectOrganismReducer)
    const classes = useStyles();
    const [isReadyContent, setReadyContent] = React.useState(false);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [isEdition, setIsEdition] = React.useState(false);
    const [optionFinanceType, setOptionFinanceType] = React.useState([]);

    const [form, setForm] = React.useState({
        startAt: {
            name: 'startAt',
            label: 'Date de début',
            textHelper: 'Saisissez la date de début de la session.',
            type: 'date',
            defaultValue: '',
            options: { validation: ['required', 'date'] }
        },
        endAt: {
            name: 'endAt',
            label: 'Date de fin',
            textHelper: 'Saisissez la date de fin de la session.',
            type: 'date',
            defaultValue: '',
            options: { validation: ['required', 'date'] }
        },
        eppFirstStageEndAt: {
            name: 'eppFirstStageEndAt',
            label: 'Date de fin du questionnaire epp phase 1',
            textHelper: 'Saisissez la date de fin epp phase 1.',
            type: 'date',
            defaultValue: '',
            options: { validation: ['date'] }
        },
        fcEndAt: {
            name: 'fcEndAt',
            label: 'Date de fin de la formation continue',
            textHelper: 'Saisissez la date de fin de la formation continue.',
            type: 'date',
            defaultValue: '',
            options: { validation: ['date'] }
        },
        eppLastStageAt: {
            name: 'eppLastStageAt',
            label: 'Date du questionnaire epp phase 2',
            textHelper: 'Saisissez la date de epp phase 2.',
            type: 'date',
            defaultValue: '',
            options: { validation: ['date'] }
        },
        financeType: {
            name: 'financeType',
            label: 'Type de financement',
            textHelper: 'Choisissez le type de financement de la session.',
            type: 'integer',
            defaultValue: selectOrganismReducer.organism.value === 5 ? 7 : '', //TODO: Faut fixer l'id pour la prod ca change a chaque fois entre 7 et 8 (Actuellement 7 en préprod)
            options: { validation: ['required'] }
        }
    });
    const [loading, setLoading] = React.useState(false);
    const handler = formHandlerInit(form, setForm);

    const save = () => {
        if (handler.checkError()) {
            console.log('Error');
        } else {
            handler.setFormLoading(true);
            setLoading(true);
            dispatch(LinearProgressMainLayoutActivate());

            if (isEdition) {
                Api.post({
                    route: 'institution_health_administrator_session_edit',
                    params: { id: props.match.params.id },
                    data: { ...handler.getData(), organism: selectOrganismReducer.organism.value }
                }, (response) => {
                    handler.setFormLoading(false);
                    setLoading(false);
                    dispatch(LinearProgressMainLayoutDeactivate());

                    if (response.status === 200) {
                        dispatch(
                            SnackbarOpen({
                                text: 'Les modifications ont bien été pris en compte.',
                                variant: 'success',
                            })
                        );
                    } else if (response.status === 400) {
                        handler.setErrorApi(response.error);
                    } else {
                        dispatch(
                            SnackbarOpen({
                                text: 'Une erreur inattendue s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
            } else {
                Api.post({
                    route: 'institution_health_administrator_session_add',
                    data: { ...handler.getData(), organism: selectOrganismReducer.organism.value }
                }, (response) => {
                    handler.setFormLoading(false);
                    setLoading(false);
                    dispatch(LinearProgressMainLayoutDeactivate());

                    if (response.status === 201) {
                        props.history.push(getRoutePathname('institution_health_admin_session', { id: response.data.id }));
                        dispatch(
                            SnackbarOpen({
                                text: 'Session créé avec succès.',
                                variant: 'success',
                            })
                        );
                    } else if (response.status === 400) {
                        handler.setErrorApi(response.error);
                    } else {
                        dispatch(
                            SnackbarOpen({
                                text: 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
            }
        }
    };

    React.useEffect(handler.start, []);
    React.useEffect(() => {
        Api.get({
            route: 'select_finance_types',
            data: { 'isFull': true, 'isInstitution': true }
        },
            (response) => {
                if (response && response.data) {
                    setOptionFinanceType(response.data);
                }
            });
    }, []);
    React.useEffect(() => {
        if (props.match.params.id) {
            setLoading(true);
            handler.setFormLoading(true);
            dispatch(LinearProgressMainLayoutActivate());

            setBreadcrumbs({
                title: 'Éditer une session',
                context: 'Administrateur',
                description: '',
                links: [
                    { path: getRoutePathname('institution_health_admin_session_list'), label: 'Liste des sessions' }
                ]
            });
            setIsEdition(true);

            Api.get({
                route: 'institution_health_administrator_session_get',
                params: { id: props.match.params.id }
            },
                (response) => {
                    setLoading(false);
                    handler.setFormLoading(false);
                    setReadyContent(true);
                    dispatch(LinearProgressMainLayoutDeactivate());

                    if (response.status === 200) {
                        handler.setDataApi(response.data);
                    } else if (response.status === 404) {
                        props.history.push(getRoutePathname('institution_health_admin_session_list'));
                        dispatch(
                            SnackbarOpen({
                                text: response.error.message,
                                variant: 'warning',
                            })
                        );
                    } else {
                        dispatch(
                            SnackbarOpen({
                                text: 'Une erreur inattendue s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
        } else {
            setBreadcrumbs({
                title: 'Ajouter une session',
                context: 'Administrateur',
                description: '',
                links: [
                    { path: getRoutePathname('institution_health_admin_session_list'), label: 'Liste des sessions' }
                ]
            });
            setIsEdition(false);
            setReadyContent(true);
            handler.reset();
        }
    }, [props.match.params.id]);
    React.useEffect(() => {
        if (form.startAt.value && form.endAt.value) {
            const dateInitiale = new Date(form.startAt.value);
            dateInitiale.setDate(dateInitiale.getDate() - 1);
            form.endAt.options.minDate = dateInitiale;
            form.endAt.error = handler.getErrorByField(form.endAt);
            if (form.endAt.error) handler.setError('endAt', `La date ne doit pas être inférieur au ${moment(form.startAt.value).format("DD/MM/YYYY")}.`);
            handler.set({ ...form });
        }
    }, [form.startAt.value, form.endAt.value]);

    return (
        <ContentViewComponent loading={!isReadyContent} breadcrumbs={breadcrumbs} {...props}>
            <Fade in={isReadyContent} {...{ timeout: 500 }}>
                <div>
                    <ShadowBoxComponent className={classes.shadowBox}>
                        <TitleComponent title={isEdition ? 'Formulaire d\'édition d\'une session' : 'Formulaire de création d\'une session'} />

                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <DatePickerComponent name={'startAt'} handler={handler} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <DatePickerComponent name={'endAt'} handler={handler} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <SelectComponent name={'financeType'} handler={handler} options={optionFinanceType} disabled={selectOrganismReducer.organism.value === 5}/>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <DatePickerComponent name={'fcEndAt'} handler={handler} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <DatePickerComponent name={'eppFirstStageEndAt'} handler={handler} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <DatePickerComponent name={'eppLastStageAt'} handler={handler} />
                            </Grid>
                        </Grid>
                        <ButtonComponent label={'Enregistrer'} className={classes.button} onClick={save} disabled={loading} />
                    </ShadowBoxComponent>
                </div>
            </Fade>
            <br />

            {

                (form.financeType.value && isEdition) && (
                    <>
                        <Slide direction={'up'} in={true} {...{ timeout: 500 }}>
                            <div>
                                <SessionFormation organismId={selectOrganismReducer.organism.value} financeTypeId={form.financeType.value} {...props} />
                            </div>
                        </Slide>
                        <br />
                    </>
                )
            }

        </ContentViewComponent>
    );
}

const useStyles = makeStyles({
    shadowBox: {
        paddingBottom: 60
    },
    button: {
        margin: 15,
        bottom: 0,
        right: 0,
        position: 'absolute'
    }
});

export default Session;
