import { Box, IconButton, Popover, Typography, makeStyles } from "@material-ui/core";
import ShadowBoxComponent from "../../../../../Component/ShadowBoxComponent";
import React from "react";

const CardHolderComponent = (props) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);
    const open = Boolean(anchorEl);
    const idPopover = open ? 'simple-popover' : undefined;
    const viewAction = (row) => {
        return (
            <div style={{ margin: 'auto', width: '100%', textAlign: 'center' }}>
                <IconButton style={{ padding: '5px 0px', borderRadius: 0, height: ' 100%', width: '100%' }} onClick={handleClick}>
                    {`${row[0].lastName && row[0].lastName} ${row[0].firstName && row[0].firstName} `}
                    <span style={{ margin: 0, height: '100%' }}>
                        {props.holder.length > 1 && '[...]'}
                    </span>
                </IconButton>
                <Popover
                    id={idPopover}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    className={classes.popover}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    <Typography style={{ padding: 8 }}>
                        {getAction(row)}
                    </Typography>
                </Popover>
            </div>
        )
    }

    const getAction = (row) => {
        return (row.map((data, index) => (
            <p key={index} style={{ padding: '5px 10px' }}>{data.lastName + ' ' + data.firstName}</p>
        )))
    }

    return (
        <>
            <ShadowBoxComponent className={classes.iconButton} style={{ background: props.holder.length > 1 ? '#FFFFFF' : 'transparent' }}>
                <Box style={{ height: '100%' }}>
                    {props.holder.length > 1 && <p style={{ margin: 0, padding: '5px 10px', borderRadius: 5, background: 'rgb(94, 110, 130)', color: '#FFF' }}>{props.holder.length}</p>}
                </Box>
                {props.holder.length > 1 ? viewAction(props.holder) : <span style={{ margin: 'auto', textAlign: 'center' }} >{(`${props.holder[0].lastName && props.holder[0].lastName} ${props.holder[0].firstName && props.holder[0].firstName} `)}</span>}
            </ShadowBoxComponent >
        </>
    )
}

const useStyles = makeStyles(style => ({
    iconButton: {
        height: 'auto',
        display: 'flex',
        color: '#000',
        alignItems: 'center',
        boxShadow: 'none',
        margin: '0',
        gap: 5,
        padding: 4,
        borderRadius: 5,
        width: 'fit-content',
        "& .MuiIconButton-label": {
            color: '#000',
        },
        "& span": {
            width: 'max-content',
            fontSize: 12,
        }
    },
    popover: {
        '& .MuiPaper-root': {
            margin: 12,
            border: '1px solid rgba(94, 110, 130, 0.38)',
            boxShadow: 'none'
        },
        '& p': {
            margin: 2,
            fontSize: 12,
            padding: 3,
            color: 'rgb(94, 110, 130)',
            fontWeight: 'bold',
            background: 'rgba(94, 110, 130, 0.06)',
            borderRadius: 2
        }
    }
}));
export default CardHolderComponent;