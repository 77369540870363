import React from 'react';
import TitleComponent from '../../../../../Component/TitleComponent';
import { Box, Fade, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, makeStyles } from '@material-ui/core';
import UseAnimations from 'react-useanimations';
import activity from "react-useanimations/lib/activity";
import ReplayIcon from '@material-ui/icons/Replay';
import CircularProgress from "@material-ui/core/CircularProgress";
import { Alert } from '@material-ui/lab';
import { theme } from '../../../../../App';
import ButtonComponent from '../../../../../Component/ButtonComponent';

function Info(props = { reloadList: () => { } }) {
    const classes = useStyles(theme)();
    const [units, setUnits] = React.useState();
    const [infoANDPC, setInfoANDPC] = React.useState();
    const [propsReady, setPropsReady] = React.useState(false);

    const getIsBillable = (billing) => {
        return (
            <>
                {(billing && billing.state) ? (
                    <>
                        {
                            (() => {
                                let chipColor = '#28a745';
                                switch (billing.state) {
                                    case 1:
                                        chipColor = '#007bff';
                                        break;
                                    case 2:
                                        chipColor = '#28a745';
                                        break;
                                    case 3:
                                        chipColor = '#db770a';
                                        break;
                                    case 4:
                                        chipColor = '#9c27b0';
                                        break;
                                }
                                return (
                                    <span style={{ color: chipColor }} >{billing.stateText}</span>
                                );
                            })()
                        }
                    </>
                ) : <span>-</span>}
            </>
        )
    }
    const formatTime = (duration) => {
        const hours = Math.floor(duration / 3600);
        const minutes = Math.floor((duration % 3600) / 60);

        const formattedHours = String(hours).padStart(2, '0');
        const formattedMinutes = String(minutes).padStart(2, '0');

        return `${formattedHours}h${formattedMinutes}`;
    };

    React.useEffect(() => {
        setUnits(props.sessionFormation.units);
        setInfoANDPC(props.sessionFormation.infoANDPC);

        if (Object.keys(props.sessionFormation).length > 0) {
            setPropsReady(true);
        }
    }, [props.sessionFormation]);

    return (
        propsReady ?
            <div style={{ padding: '20px 40px', position: 'relative' }}>
                <TitleComponent title={
                    <span className={classes.title}>
                        <b>{props.sessionFormation.formationName}</b>
                        <ButtonComponent
                            onClick={() => props.reloadList()}
                            disabled={props.reload}
                            style={{ padding: 4, width: 32, height: 32, minWidth: 'initial' }}
                            label={props.reload ? <CircularProgress size={19} className={classes.circularProgress} /> : <ReplayIcon style={{ color: '#FFF' }} />}
                        />
                    </span>} />
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                        <p><span className={classes.bold}>Début de session :</span> {props.sessionFormation.startAt}</p>
                        <p><span className={classes.bold}>Durée de la formation :</span> {props.sessionFormation.formationDuration}</p>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                        <p><span className={classes.bold}>Fin de session :</span> {props.sessionFormation.endAt}</p>
                    </Grid>
                </Grid>
                <div>
                    <TitleComponent title={<span className={classes.bold}>Financement</span>} />
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.th}>Type de financement</TableCell>
                                    <TableCell className={classes.th}>Numéro de programme</TableCell>
                                    <TableCell className={classes.th}>Status</TableCell>
                                    <TableCell className={classes.th}>Nombre d'inscrits</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell className={classes.td}>ANDPC</TableCell>
                                    <TableCell className={classes.td}>{props.sessionFormation.infoANDPC.code ?? '-'}</TableCell>
                                    <TableCell className={classes.td}>{getIsBillable(props.sessionFormation.billingANDPC)}</TableCell>
                                    <TableCell className={classes.td}>{props.sessionFormation.billingANDPC.registrations.length}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={classes.td}>OPCO</TableCell>
                                    <TableCell className={classes.td}>{'-'}</TableCell>
                                    <TableCell className={classes.td}>{getIsBillable(props.sessionFormation.billingOPCO)}</TableCell>
                                    <TableCell className={classes.td}>{props.sessionFormation.billingOPCO.registrations.length}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={classes.td}>FIFPL</TableCell>
                                    <TableCell className={classes.td}>{'-'}</TableCell>
                                    <TableCell className={classes.td}>{getIsBillable(props.sessionFormation.billingFIFPL)}</TableCell>
                                    <TableCell className={classes.td}>{props.sessionFormation.billingFIFPL.registrations.length}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={classes.td}>Reste à charge</TableCell>
                                    <TableCell className={classes.td}>{'-'}</TableCell>
                                    <TableCell className={classes.td}>{getIsBillable(props.sessionFormation.billingREST)}</TableCell>
                                    <TableCell className={classes.td}>{props.sessionFormation.billingREST.registrations.length}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                <br />
                {(units && props.sessionFormation.billingANDPC.registrations.length != 0) &&
                    <div>
                        <TitleComponent title={<span className={classes.bold}>Détail des unitées</span>} />
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.th}>Ordre</TableCell>
                                        <TableCell className={classes.th}>Type</TableCell>
                                        <TableCell className={classes.th}>Durée en ligne</TableCell>
                                        <TableCell className={classes.th}>Durée hors-ligne</TableCell>
                                        <TableCell className={classes.th}>Chapitres</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {(units && units.length > 0) ?
                                        units.map((unit) => {
                                            return <TableRow key={unit.id}>
                                                <TableCell className={classes.td}>{unit.orderBy}</TableCell>
                                                <TableCell className={classes.td}>{unit.type}</TableCell>
                                                <TableCell className={classes.td}>{formatTime(unit.duration)} ({unit.duration} secondes)</TableCell>
                                                <TableCell className={classes.td}>{formatTime(unit.durationOffline)} ({unit.durationOffline} secondes)</TableCell>
                                                <TableCell className={classes.td}>
                                                    {
                                                        (unit.chapter && unit.chapter.length > 0) &&
                                                        <span style={{ color: '#0090ff' }}>
                                                            {unit.type === 'FC' ?
                                                                (
                                                                    'Temps total chapitre(cours + pre/post) : ' + formatTime((unit.durationChapters + unit.durationPrePost)) + ' (' + (unit.durationChapters + unit.durationPrePost) + 'secondes) = Temps FC : ' +
                                                                    formatTime((unit.durationChapters)) + ' (' + (unit.durationChapters) + ' secondes) + Temps PRE/POST : ' +
                                                                    formatTime(unit.durationPrePost) + ' (' + unit.durationPrePost + ' secondes)'
                                                                )
                                                                :
                                                                'Temps total chapitre(cours cumulés) : ' + formatTime(unit.durationChapters) + ' (' + unit.durationChapters + 'secondes)'
                                                            }
                                                        </span>
                                                    }
                                                    {
                                                        (unit.chapter && unit.chapter.length > 0) ?
                                                            unit.chapter.map((chapter, i) => {
                                                                return <div key={i}><span>{i + 1}/ {chapter.name}</span><br /></div>
                                                            })
                                                            :
                                                            '--'
                                                    }
                                                    {
                                                        (unit.chapter && unit.chapter.length > 0 && (unit.durationChapters + unit.durationPrePost) < unit.duration) &&
                                                        <p style={{ margin: 5, color: '#dc3545' }}>Attention ! Le temps des cours est inferieur au temps de l'unité.</p>
                                                    }
                                                </TableCell>

                                            </TableRow>
                                        })
                                        :
                                        <TableRow>
                                            <TableCell className={classes.td}></TableCell>
                                            <TableCell className={classes.td}></TableCell>
                                            <TableCell className={classes.td}><Alert severity="warning">Attention, aucune unité n'a été remplie pour cette formation.</Alert></TableCell>
                                            <TableCell className={classes.td}></TableCell>
                                            <TableCell className={classes.td}></TableCell>
                                        </TableRow>
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                }
                <br />
                {infoANDPC &&
                    <div>
                        <TitleComponent title={<span className={classes.bold}>Détail date, temps et prix ANDPC</span>} />
                        <div className={classes.andpcContainer}>
                            <div>
                                <p>Date</p>
                                <div>
                                    <p><span>Fin EPP 1 : </span>{infoANDPC.epp_first_stage_end_at}</p>
                                    <p><span>Début EPP 2 : </span>{infoANDPC.epp_last_stage_at}</p>
                                    <p><span>Fin FC : </span>{infoANDPC.fc_end_at}</p>
                                </div>
                            </div>
                            <div style={props.sessionFormation.billingANDPC.registrations.length != 0 ? { borderLeft: '1px solid #DADADA', borderRight: '1px solid #DADADA' } : { borderLeft: '1px solid #DADADA' }}>
                                <p>Temps</p>
                                <div>
                                    <p><span>FC : </span>{infoANDPC.duration_fc ? formatTime(infoANDPC.duration_fc) + ' (' + infoANDPC.duration_fc + ' secondes)' : '-'}</p>
                                    <p><span>Questionnaire pre : </span>{infoANDPC.duration_pre ? formatTime(infoANDPC.duration_pre) + ' (' + infoANDPC.duration_pre + ' secondes)' : '-'}</p>
                                    <p><span>Questionnaire post : </span>{infoANDPC.duration_post ? formatTime(infoANDPC.duration_post) + ' (' + infoANDPC.duration_post + ' secondes)' : '-'}</p>
                                    <p><span>EPP 1 : </span>{infoANDPC.duration_epp1 ? formatTime(infoANDPC.duration_epp1) + ' (' + infoANDPC.duration_epp1 + ' secondes)' : '-'}</p>
                                    <p><span>EPP 2 : </span>{infoANDPC.duration_epp2 ? formatTime(infoANDPC.duration_epp2) + ' (' + infoANDPC.duration_epp2 + ' secondes)' : '-'}</p>
                                    <p><span>Hors ligne : </span>{infoANDPC.duration_offline ? formatTime(infoANDPC.duration_offline) + ' (' + infoANDPC.duration_offline + ' secondes)' : '-'}</p>
                                    <p><span>CV : </span>{infoANDPC.duration_cv ? formatTime(infoANDPC.duration_cv) + ' (' + infoANDPC.duration_cv + ' secondes)' : '-'}</p>
                                </div>
                            </div>
                            {props.sessionFormation.billingANDPC.registrations.length != 0 &&
                                <div>
                                    <p>Prix ANDPC (par heure)</p>
                                    <div>
                                        <p><span>FC : </span>{infoANDPC.price_fc_per_hour ? infoANDPC.price_fc_per_hour + ' €' : '-'}</p>
                                        <p><span>EPP : </span>{infoANDPC.price_epp_per_hour ? infoANDPC.price_epp_per_hour + ' €' : '-'}</p>
                                        <p><span>CV : </span>{infoANDPC.price_cvper_hour ? infoANDPC.price_cvper_hour + ' €' : '-'}</p>
                                        <p><span>Hors ligne : </span>{infoANDPC.price_offline_per_hour ? infoANDPC.price_offline_per_hour + ' €' : '-'}</p>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                }
            </div>

            :

            <Fade in={true} {...{ timeout: 1000 }}>
                <Box className={classes.loader}>
                    <Box className={classes.loaderContent}>
                        <Box className={classes.loaderActivity}>
                            <UseAnimations animation={activity} size={70} />
                        </Box>
                    </Box>
                </Box>
            </Fade>
    );
};

const useStyles = (theme) => makeStyles({
    title: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        '& .MuiButtonBase-root': {
            height: 'max-content',
            width: 'max-content',
            display: 'flex',
            background: '#5E6E82',
            color: '#FFF',
            borderRadius: 5,
            padding: 5
        }
    },
    circularProgress: {
        margin: 0,
        color: '#FFF',
    },
    logoContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: 20
    },
    bold: {
        fontWeight: 600
    },
    btnContainer: {
        marginTop: 20,
        marginBottom: 10,
        display: 'flex',
        justifyContent: 'flex-end',
        '& > button': {
            margin: 3
        }
    },
    td: { fontSize: 13, padding: 12 },
    th: { fontWeight: 900, fontSize: 13, padding: 12 },
    andpcContainer: {
        margin: 10,
        display: 'flex',
        justifyContent: 'space-evenly',
        '& > div': {
            padding: 15,
            width: '30%',
            '& > p': {
                textAlign: 'center',
                fontWeight: 600,
                fontSize: 19,
                margin: 15
            },
            '& > div': {
                display: 'flex',
                justifyContent: 'space-evenly',
                flexWrap: 'wrap',
                flexDirection: 'column',
                '& > p': {
                    fontSize: 15,
                    margin: 5,
                    display: 'flex',
                    justifyContent: 'space-between',
                    borderBottom: '1px dotted #DADADA',
                    '& > span': {
                        fontWeight: 600,
                    }
                }
            }
        }
    },
    loaderActivity: {
        '& > div':
        {
            margin: 'auto',
            padding: 10,
            width: '50px !important',
            height: '50px !important'
        }
    },
    loader: {
        textAlign: 'center',
        width: '100%',
        height: '100%',
        display: 'flex',
        position: 'absolute',
    },
    loaderContent: {
        height: 'initial !important',
        margin: 'auto',
        background: '#FFF', border: '5px double rgb(206, 200, 200)', borderRadius: 100
    },
});

export default Info;